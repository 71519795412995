import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-photo-status',
  templateUrl: './select-photo-status.component.html',
  styleUrls: ['./select-photo-status.component.scss']
})
export class SelectPhotoStatusComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Todas', value: 0 },
    { name: 'No han subido foto', value: 4 },
    { name: 'Foto de hoy', value: 1 },
    { name: 'Foto de mala calidad', value: 2 },
    { name: 'Foto de días anteriores', value: 3 }
  ];

  public statusPhoto: any = [];
  statusPhoto$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.statusPhoto$ = this.sharedService.sharedStatusPhoto.subscribe(statusPhoto => {
      this.statusPhoto = statusPhoto;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.statusPhoto$.unsubscribe();
  }

  changeStatusPhoto(event: any) {
    this.statusPhoto = event;
    this.sharedService.nextStatusPhoto = this.statusPhoto;
  }
}
