import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { Subscription } from 'rxjs';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-chart-quotient-sale',
  templateUrl: './chart-quotient-sale.component.html',
  styleUrls: ['./chart-quotient-sale.component.scss']
})
export class ChartQuotientSaleComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  data;
  days = 30;
  days$!: Subscription;
  elastic;
  inelastic;
  slope;
  idGroup: GroupIds | string = GroupIds.Pa;
  idGroup$!: Subscription;
  id;
  idStation;
  event$!: Subscription;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  route;
  rol;
  stationId;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'scatter'
    },
    title: {
      text: 'Venta Litros - Indice de Precios',
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Indice de Precios',
        style: {
          fontSize: '11px'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        format: '{value:,.3f}',
      },
      startOnTick: true,
      endOnTick: true
    },
    yAxis: {
      title: {
        text: 'Venta Litros',
        style: {
          fontSize: '11px'
        }
      },
      labels: {
        format: '{value:,.0f} lts.',
        style: {
          fontSize: '11px',
        }
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    exporting: {
      filename: 'Venta Litros - Indice de Precios',
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 4,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)'
            }
          }
        }
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        if (this.reqst > 1) {
          this.getDashboardVolumeQuotient();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysOptimumPrice.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardVolumeQuotient();
        }
      }
    });

  }

  ngOnInit(): void {
    this.getDashboardVolumeQuotient();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.days$.unsubscribe();
    this.event$.unsubscribe();
  }

  getDashboardVolumeQuotient() {
    this.reqst = 1;
    this.stationId = localStorage.getItem('idStation');
    const url = this.gasprecioService.getChartSalesOptimum(this.stationId, this.days, 1);
    url.subscribe({
      next: (data: any) => {
        this.data = data['merged_data'];
        this.elastic = data['elastic'];
        this.inelastic = data['inelastic'];
        this.slope = data['slope'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const seriesData = [];
    const pos = this.productType === 'regular' ? 0 : this.productType === 'premium' ? 1 : 2;
    const colorP = this.productType === 'regular' ? '#179446' : this.productType === 'premium' ? '#CD2120' : '#1e2228';
    this.data[pos].map((month: any, index) => {
      const nameMonth = month.length > 0 ? this.gasprecioService.parseDateM(month[0]['date']) : '';
      let color;
      switch (index) {
        case 0:
          color = colorP;
          break;
        case 1:
          color = '#63A8E0';
          break;
        case 2:
          color = '#E1DDDD';
          break;
        case 3:
          color = '#2787F5';
          break;
        case 4:
          color = '#33F3F5';
          break;
        case 5:
          color = '#25DB65';
          break;
        case 6:
          color = '#C4DCF5';
          break;
        case 7:
          color = '#A0C9F1';
          break;
        case 8:
          color = '#F0EFEF';
          break;
        case 9:
          color = '#7CB6FB';
          break;
        case 10:
          color = '#9CF7F8';
          break;
        case 11:
          color = '#7EF2A1';
          break;
        case 12:
          color = '#E0ECFB';
          break;
        default:
          color = '#A5FD2D';
          break;
      }
      const series = {
        name: nameMonth,
        data: month.map(item => [item.x, item.y]),
        color: color,
        tooltip: {
          pointFormatter: function () {
            return '<b>Venta Litros: ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.<br/>Indice de Precios: ' + this.x.toFixed(3) +' </b>';
          }
        }
      };

      seriesData.push(series);
    });

    if (this.slope[pos]) {
      const slopeSeries = {
        name: 'Pendiente',
        color: 'black',
        data: this.slope[pos],
        type: 'line',
        tooltip: {
          pointFormatter: function () {
            return '<b>Venta Litros: ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.<br/>Indice de Precios: ' + this.x.toFixed(3) + ' </b>';
          }
        },
        marker: {
          radius: 0
        }
      };
      seriesData.push(slopeSeries);
    }

    if (this.inelastic[pos]) {
      const inelasticSeries = {
        name: 'Lim Inferior',
        color: 'black',
        data: this.inelastic[pos],
        type: 'line',
        tooltip: {
          pointFormatter: function () {
            return '<b>Venta Litros: ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.<br/>Indice de Precios: ' + this.x.toFixed(3) + ' </b>';
          }
        },
        dashStyle: 'Dash',
        marker: {
          radius: 0
        }
      };
      seriesData.push(inelasticSeries);
    }

    if (this.elastic[pos]) {
      const elasticSeries = {
        name: 'Lim Superior',
        color: 'black',
        data: this.elastic[pos],
        type: 'line',
        tooltip: {
          pointFormatter: function () {
            return '<b>Venta Litros: ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.<br/>Indice de Precios: ' + this.x.toFixed(3) + ' </b>';
          }
        },
        dashStyle: 'Dash',
        marker: {
          radius: 0
        }
      };
      seriesData.push(elasticSeries);
    }

    this.chartOptions.series = [...seriesData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }
}