<div class="row" style="margin:10px;" *ngIf="reqst === 0">
    <app-table-loader style="width: 100%;"></app-table-loader>
</div>
<table *ngIf="reqst === 1">
    <thead>
        <tr>
            <th style="width: 40%;padding: 0 1px !important;" class="text-right">
                <span style="font-size: 14px;text-decoration: underline;margin-right: 20px;">
                    Recomendación según periodo
                </span>
            </th>
            <th *ngFor="let row of rowsRecord"
                [ngClass]="{ 'low':row.market === 'low' && row.flag_actual, 'medium-l':row.market === 'medium_low' && row.flag_actual,'medium-h':row.market === 'medium_high' && row.flag_actual,'high':row.market === 'high' && row.flag_actual, 'border-all': !row.flag_actual}"
                [ngStyle]="{ 'width': rowsLength + '%' }">
                <span *ngIf="row.idx === 0">Actual</span>
                <span *ngIf="row.idx === 2">180 días</span>
                <span *ngIf="row.idx === 3">30 días</span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Precio de Venta
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
                        </span>
                        Precio de venta promedio diario reportado a la CRE por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':row.market === 'low' && row.flag_actual, 'medium-l-sides':row.market === 'medium_low' && row.flag_actual,'medium-h-sides':row.market === 'medium_high' && row.flag_actual,'high-sides':row.market === 'high' && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.selling_price !== '-'">
                    $ {{row.selling_price| number : '1.2-2'}}
                </span>
                <span *ngIf="row.selling_price === '-'">
                    {{row.selling_price}}
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Movimiento
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
                        </span>
                        Movimiento en el precio de venta
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':row.market === 'low' && row.flag_actual, 'medium-l-sides':row.market === 'medium_low' && row.flag_actual,'medium-h-sides':row.market === 'medium_high' && row.flag_actual,'high-sides':row.market === 'high' && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span style="display: flex;justify-content: center;align-items: center;column-gap: 10px;">
                    <i class="fa-regular fa-minus" aria-hidden="true" *ngIf="row.movement === 0"></i>
                    <i class="fa-solid fa-arrow-down trend-down" aria-hidden="true" *ngIf="row.movement === 1"></i>
                    <i class="fa-solid fa-arrow-up trend-up" aria-hidden="true" *ngIf="row.movement === 2"></i>
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Zona de Mercado
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
                        </span>
                        Zona de Mercado
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':row.market === 'low' && row.flag_actual, 'medium-l-sides':row.market === 'medium_low' && row.flag_actual,'medium-h-sides':row.market === 'medium_high' && row.flag_actual,'high-sides':row.market === 'high' && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.market === 0">Baja</span>
                <span *ngIf="row.market === 1">Media Baja</span>
                <span *ngIf="row.market === 2">Media Alta</span>
                <span *ngIf="row.market === 3">Alta</span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Indice de Precios
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
                        </span>
                        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es <
                            1.0 significa que tu precio es menor, mientras que si es> 1.0 tu precio es más alto que el
                            de tus competidores.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':row.market === 'low' && row.flag_actual, 'medium-l-sides':row.market === 'medium_low' && row.flag_actual,'medium-h-sides':row.market === 'medium_high' && row.flag_actual,'high-sides':row.market === 'high' && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span style="display: flex;justify-content: center;align-items: center;column-gap: 10px;">
                    {{row.quotient}}
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Venta Litros
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
                        </span>
                        Tendencia general de ventas.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':row.market === 'low' && row.flag_actual, 'medium-l-sides':row.market === 'medium_low' && row.flag_actual,'medium-h-sides':row.market === 'medium_high' && row.flag_actual,'high-sides':row.market === 'high' && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.sales !== '-'">
                    {{row.sales| number : '1.0-0'}}
                </span>
                <span *ngIf="row.sales === '-'">
                    {{row.sales}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Margen Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
                        </span>
                        Promedio de margen de ganancia diario calculado por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':row.market === 'low' && row.flag_actual, 'medium-l-sides':row.market === 'medium_low' && row.flag_actual,'medium-h-sides':row.market === 'medium_high' && row.flag_actual,'high-sides':row.market === 'high' && row.flag_actual, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.margin !== '-'">
                    $ {{row.margin| number : '1.2-2'}}
                </span>
                <span *ngIf="row.margin === '-'">
                    {{row.margin}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Ganancia Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
                        </span>
                        Promedio de volumen de venta en litros multiplicado en margen bruto.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-bottom':row.market === 'low' && row.flag_actual, 'medium-l-bottom':row.market === 'medium_low' && row.flag_actual,'medium-h-bottom':row.market === 'medium_high' && row.flag_actual,'high-bottom':row.market === 'high' && row.flag_actual, 'border-bottom': !row.flag_actual }">
                <span *ngIf="row.earning !== '-'">
                    $ {{row.earning| number : '1.0-0'}}
                </span>
                <span *ngIf="row.earning === '-'">
                    {{row.earning}}
                </span>
            </td>
        </tr>
        <tr style="height:10px">
            <td *ngFor="let row of rowsRecord; let index = index"
                style="height: 10px !important;color: white !important;font-size: 1px !important;">
                &nbsp;
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Nueva venta con cambio de -0.30 en PV
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index" style="border-bottom: none !important;"
                [ngClass]="{ 'low':row.market === 'low' && row.flag_actual, 'medium-l':row.market === 'medium_low' && row.flag_actual,'medium-h':row.market === 'medium_high' && row.flag_actual,'high':row.market === 'high' && row.flag_actual, 'border-top': !row.flag_actual}">
                <span *ngIf="row['new_sales_minus_0_3']  !== '-'">
                    {{row['new_sales_minus_0_3'] | number :'1.0-0'}}
                </span>
                <span *ngIf="row['new_sales_minus_0_3']  === '-'">
                    {{row['new_sales_minus_0_3'] }}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Nueva venta con cambio de +0.30 en PV
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border-bottom: none !important;border-top: none !important;"
                [ngClass]="{ 'low':row.market === 'low' && row.flag_actual, 'medium-l':row.market === 'medium_low' && row.flag_actual,'medium-h':row.market === 'medium_high' && row.flag_actual,'high':row.market === 'high' && row.flag_actual, 'border-top': !row.flag_actual}">
                <span *ngIf="row['new_sales_plus_0_3']  !== '-'">
                    {{row['new_sales_plus_0_3'] | number : '1.0-0'}}
                </span>
                <span *ngIf="row['new_sales_plus_0_3']  === '-'">
                    {{row['new_sales_plus_0_3'] }}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Cambio venta con cambio de -0.30 en PV
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border-bottom: none !important;border-top: none !important;"
                [ngClass]="{ 'low':row.market === 'low' && row.flag_actual, 'medium-l':row.market === 'medium_low' && row.flag_actual,'medium-h':row.market === 'medium_high' && row.flag_actual,'high':row.market === 'high' && row.flag_actual, 'border-top': !row.flag_actual}">
                <span *ngIf="row['sales_change_minus_0_3']  !== '-'">
                    <span *ngIf="row['sales_change_minus_0_3']  > 0">+</span>{{row['sales_change_minus_0_3'] | number :
                    '1.0-0'}}
                </span>
                <span *ngIf="row['sales_change_minus_0_3']  === '-'">
                    {{row['sales_change_minus_0_3'] }}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Cambio venta con cambio de +0.30 en PV
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border-bottom: none !important;border-top: none !important;"
                [ngClass]="{ 'low':row.market === 'low' && row.flag_actual, 'medium-l':row.market === 'medium_low' && row.flag_actual,'medium-h':row.market === 'medium_high' && row.flag_actual,'high':row.market === 'high' && row.flag_actual, 'border-top': !row.flag_actual}">
                <span *ngIf="row['sales_change_plus_0_3']  !== '-'">
                    <span *ngIf="row['sales_change_plus_0_3']  > 0">+</span>{{row['sales_change_plus_0_3'] | number :
                    '1.0-0'}}
                </span>
                <span *ngIf="row['sales_change_plus_0_3']  === '-'">
                    {{row['sales_change_plus_0_3'] }}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Sensibilidad -0.30 en PV
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border-bottom: none !important;border-top: none !important;"
                [ngClass]="{ 'low':row.market === 'low' && row.flag_actual, 'medium-l':row.market === 'medium_low' && row.flag_actual,'medium-h':row.market === 'medium_high' && row.flag_actual,'high':row.market === 'high' && row.flag_actual, 'border-top': !row.flag_actual}">
                <span *ngIf="row['sensibility_minus_0_3'] !== '-'">
                    {{row['sensibility_minus_0_3']| number: '1.0-0'}}
                </span>
                <span *ngIf="row['sensibility_minus_0_3'] === '-'">
                    {{row['sensibility_minus_0_3']}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Sensibilidad +0.30 en PV
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border-bottom: 1px solid black !important;border-top: none !important;"
                [ngClass]="{ 'low':row.market === 'low' && row.flag_actual, 'medium-l':row.market === 'medium_low' && row.flag_actual,'medium-h':row.market === 'medium_high' && row.flag_actual,'high':row.market === 'high' && row.flag_actual, 'border-top': !row.flag_actual}">
                <span *ngIf="row['sensibility_plus_0_3'] !== '-'">
                    {{row['sensibility_plus_0_3']| number: '1.0-0'}}
                </span>
                <span *ngIf="row['sensibility_plus_0_3'] === '-'">
                    {{row['sensibility_plus_0_3']}}
                </span>
            </td>
        </tr>
    </tbody>
</table>

