import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ClusterType } from 'src/app/modules/gasprecio/enums/cluster-type.enum';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-cluster3',
  templateUrl: './select-cluster3.component.html',
  styleUrls: ['./select-cluster3.component.scss']
})
export class SelectCluster3Component implements OnInit, OnDestroy {

  public items: any[] = [
    { name: 'Sin Filtro', value: null }
  ];
  private unsubscribe$ = new Subject<void>();
  public cluster: any = [];
  public ngxDisabled = true;
  idGroup$: Subscription;
  idGroup;
  selectedClusters;
  selectedClusters$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.sharedService.sharedGroup
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((idGroup: string) => {
        if (idGroup !== this.idGroup) {
          this.idGroup = idGroup;
          this.getClusters();
        }
      });

    this.sharedService.getSharedSelectedClusters(ClusterType.Cluster3)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedClusters => {
        this.selectedClusters = selectedClusters;
      });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getClusters() {
    this.items = [
      { name: 'Sin Filtro', value: null }
    ];
    this.gasprecioService.getClusters(this.idGroup, 'clusters_3')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: any) => {
          this.items.push(...data.map((group) => ({
            name: group['name'],
            value: group['_id']
          })));
        },
        complete: () => {
          this.ngxDisabled = false;
        }
      });
  }

  changeCluster(event: any) {
    this.sharedService.setNextSelectedClusters(ClusterType.Cluster3, event);
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }
}
