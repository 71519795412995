<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/images/login/img_login.svg">
    <div class="login-box card">
        <div class="card-body center-card">
            <form class="form-horizontal form-material full" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                <a href="javascript:void(0)" class="text-center db">
                    <img src="assets/images/logo-icon.svg" width="20%" alt="Home" />
                </a>

                <h2 class="welcome">Registro</h2>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <input class="form-control" type="text" placeholder="Nombre" name="name" formControlName="name" #name>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <input class="form-control" type="text" placeholder="Email" name="email" formControlName="email" #email>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <input class="form-control" type="text" placeholder="CRE" name="cre" formControlName="cre" #cre>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-info btn-red btn-lg btn-block btn-rounded" color="primary" type="submit" value="submit" [disabled]="!signupForm.valid">
                            Registrarme
                        </button>
                    </div>
                </div>

                <div class="form-group row text-center">
                    <div class="col-md-6  offset-md-3">
                        <a [routerLink]="['/login']" class="text-info m-l-5"><b>Iniciar Sesión</b></a>
                    </div>
                </div>

            </form>
        </div>
    </div>
</section>