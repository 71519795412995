import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-delimitation',
  templateUrl: './select-delimitation.component.html',
  styleUrls: ['./select-delimitation.component.scss']
})
export class SelectDelimitationComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Todos los comp.', value: 'all' },
    { name: 'Mi Micromercado', value: 'cluster' }
  ];

  public delimitation: any = ['all'];
  public ngxDisabled = false;
  delimitation$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.delimitation$ = this.sharedService.sharedDelimitation.subscribe(delimitation => {
      this.delimitation = delimitation;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.delimitation$.unsubscribe();
  }

  changeDelimitation(event: any) {
    this.delimitation = event;
    this.sharedService.nextDelimitation = this.delimitation;
  }
}
