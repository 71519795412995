import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product-type',
  templateUrl: './select-product-type.component.html',
  styleUrls: ['./select-product-type.component.scss']
})
export class SelectProductTypeComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Prods. y Total', value: 'all' },
    { name: 'Productos', value: 'gasoline' },
    { name: 'Total', value: 'total' }
  ];

  public productTypeBudget: any = [];
  public ngxDisabled = false;
  productTypeBudget$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productTypeBudget$ = this.sharedService.sharedProductTypeBudget.subscribe(productTypeBudget => {
      if (this.productTypeBudget !== productTypeBudget) {
        this.productTypeBudget = productTypeBudget;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.productTypeBudget$.unsubscribe();
  }

  changeProductTypeBudget(event: any) {
    this.productTypeBudget = event;
    this.sharedService.nextProductTypeBudget = this.productTypeBudget;
  }
}
