import { Directive, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { Sort } from '../utils/sort';

@Directive({
    selector: '[appSort]'
})
export class SortDirective {

    @Input() appSort: Array<any>;
    @Output() myCustomEvent = new EventEmitter();

    constructor(private targetElem: ElementRef) { }

    @HostListener('click')
    sortData() {
        const sort = new Sort();
        const elem = this.targetElem.nativeElement;
        const order = elem.getAttribute('data-order') === 'asc' ? 'desc' : 'asc';
        const property = elem.getAttribute('data-name');
        const orderName = property + '-' + order;
        this.appSort.sort(sort.startSort(property, order, 'string')); // Ajusta el tipo de datos según corresponda
        elem.setAttribute('data-order', order);
        this.myCustomEvent.emit(orderName);
    }
}
