import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-sales-quartil-comparative',
  templateUrl: './table-sales-quartil-comparative.component.html',
  styleUrls: ['./table-sales-quartil-comparative.component.scss']
})
export class TableSalesQuartilComparativeComponent implements OnInit, OnDestroy {

  market = '';
  productType = 'regular';
  productType$!: Subscription;
  rowsCurrent: any;
  rowsRecord: any;
  rowsLength: number;
  reqst = 0;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getTableSalesQuartilComparative();
        }
      }
    });
  }

  ngOnInit(): void {
    this.getTableSalesQuartilComparative();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
  }

  getRowHeight(row: any) {
    return row.height;
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getTableSalesQuartilComparative() {
    this.reqst = 0;
    this.gasprecioService.getTableSalesQuartilComparative(this.productType).subscribe((data: any) => {
      if (data['data']) {
        this.rowsLength = (60 / data['data'].length);
        data['data'].map((element: any, index: number) => {
          element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
          element['sales'] = element['sales'] !== null ? element['sales'] : '-';
          element['earning'] = element['earning'] !== null ? element['earning'] : '-';
          element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
          element['margin'] = element['margin'] !== null ? element['margin'] : '-';
        });

        this.rowsRecord = data['data'];
      }
      this.reqst = 1;
    });
  }

}
