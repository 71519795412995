<ng-container *ngIf="!ngxDisabled">
    <div class="budget-white" *ngIf="format">
        <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name" 
            [(ngModel)]="year" appendTo="body" placeholder="Seleccionar Año" (select)="changeYear($event)"
            [disabled]="ngxDisabled" dropDownMenuOtherClasses="budget">
            <ng-template ngx-select-option-not-found let-input>
                "{{input}}" no encontrado
            </ng-template>
        </ngx-select>
    </div>

    <div *ngIf="!format">
        <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name" [(ngModel)]="year"
            placeholder="Seleccionar Año" (select)="changeYear($event)">
            <ng-template ngx-select-option-not-found let-input>
                "{{input}}" no encontrado
            </ng-template>
        </ngx-select>
    </div>
</ng-container>


