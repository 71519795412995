import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-margin',
  templateUrl: './select-margin.component.html',
  styleUrls: ['./select-margin.component.scss']
})
export class SelectMarginComponent implements OnDestroy {

  public items: any[] = [
    {
      name: 'Margen Inventario', value: 'purchase' },
    { name: 'Margen Reposición Diario', value: 'rep' },
    { name: 'Margen por Capa', value: 'layer' }
  ];

  public margin: any = [];
  public ngxDisabled = false;
  margin$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.margin$ = this.sharedService.sharedMargin.subscribe(margin => {
      this.margin = margin;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.margin$.unsubscribe();
  }

  changeMargin(event: any) {
    this.margin = event;
    this.sharedService.nextMargin = this.margin;
  }
}
