import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import moment from 'moment';

@Component({
  selector: 'app-heat-map-shift',
  templateUrl: './heat-map-shift.component.html',
  styleUrls: ['./heat-map-shift.component.scss']
})
export class HeatMapShiftComponent implements OnDestroy {

  @Input() variable = 'transactions';
  @Input() type = 'Autos';
  @Input() shift: any = 1;
  @Input() station: any = false;
  chartOptions: Highcharts.Options;
  data = [];
  date = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
  event$!: Subscription;
  Highcharts = Highcharts;
  hours = [];
  idGroup: GroupIds | string = GroupIds.Pa;
  loading = false;
  private destroy$ = new Subject<void>();
  productType = 'regular';
  period = 'day';
  reqst = 0;
  rol;
  route;
  stationId: any;
  zone;
  changeZone = false;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedZone,
      this.sharedService.sharedStationSelected,
      this.sharedService.sharedSelectedMonth,
      this.sharedService.sharedProductTypeTotal,
    ]).pipe(takeUntil(this.destroy$)).subscribe(([idGroup, zone, stationId, period, productType]) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }

      if (this.zone !== zone) {
        if(this.zone !== undefined) {
          this.changeZone = true;
        }
        this.zone = zone;
      }

      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }

      if (period !== this.date) {
        this.date = period;
      }

      if (this.productType !== productType) {
        this.productType = productType;
      }

      if (!this.loading) {
        this.getHeatMapShift();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getHeatMapShift() {
    this.loading = true;
    this.reqst = 0;
    this.data = [];
    this.hours = [];
    setTimeout(() => {
      // const url = this.changeZone ? this.gasprecioService.getHeatMapShift(this.idGroup, this.stationId, this.variable, this.productType, this.shift, this.date, true) : this.gasprecioService.getHeatMapShift(this.idGroup, this.stationId, this.variable, this.productType, this.shift, this.date);
      const url = this.gasprecioService.getHeatMapShift(this.idGroup, this.stationId, this.variable, this.productType, this.shift, this.date);
      url.subscribe({
        next: (data: any) => {
          this.data = data['data_formatted'];
          this.hours = data['hours'];
          this.setData();
        }, complete: () => {
          this.reqst = 1;
        }, error: () => {
          this.loading = false;
          this.reqst = 2;
        }
      });
    }, 400);
  }

  setData() {
    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'heatmap',
        plotBorderWidth: 1
      },
      title: {
        text: `Mapa de Calor - Turno ${this.shift}`
      },
      xAxis: {
        categories: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        title: {
          text: 'Días de la Semana',
          style: {
            fontSize: '0'
          }
        }
      },
      yAxis: {
        categories: this.hours,
        title: {
          text: 'Horas del día',
          style: {
            fontSize: '0'
          }
        },
        reversed: true
      },
      colorAxis: {
        min: 0,
        minColor: '#FFFFFF',
        maxColor: '#AC4F3D'  // Color para valores altos
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280
      },
      tooltip: {
        formatter: function () {
          return `Día: <b>${this.series.xAxis.categories[this.point.x]}</b><br>
                  Hora: <b>${this.series.yAxis.categories[this.point.y]}</b><br>
                  Autos: <b>${this.point.value}</b>`;
        }
      },
      series: [{
        type: 'heatmap',
        name: `${this.type} en una semana`,
        borderWidth: 1,
        data: this.data, // Genera datos en función del turno
        dataLabels: {
          enabled: true,
          color: '#000000'
        }
      }]
    };

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
}
