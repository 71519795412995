<div class="modal-dialog medium-dialog modal-dialog-centered" role="document">
    <div class="modal-content" *ngIf="step === 'select'">
        <div class="modal-header">
            <h5 class="modal-title text-center" id="uploadSalesLabel">Selecciona una de
                las opciones</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetOption()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="pricing-columns-data" (click)="changeOption('create');">
                <div class="plan-container">
                    <h5 class="title" style="font-size: 18px !important;">Crear Grupo</h5>
                    <br>
                    <i class="fa-regular fa-file-excel icon" aria-hidden="true"></i>
                </div>
                <div class="plan-container" (click)="changeOption('list');this.getClusters();">
                    <h5 class="title" style="font-size: 18px !important;">Editar/Eliminar Grupo</h5>
                    <br>
                    <i class="fa-regular fa-upload icon" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="footer-modal">
        </div>
    </div>

    <div class="modal-content" *ngIf="step === 'create'">
        <div class="modal-header">
            <button type="button" class="close">
            </button>
            <h5 class="modal-title text-center" id="tableColumns">
                Crear Grupo
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetOption()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12" style="padding: 0;">
                        <label>Nombre del grupo</label>
                        <input type="text" class="form-control" [(ngModel)]="clusterName" />
                        <label class="mt-3">Selecciona las estaciones que corresponden al grupo:</label>
                        <div
                            style="display: inline-block;color: #626262;font-size: 14px;font-weight: 700;margin-bottom: 10px;">
                            Filtrar estaciones por plaza:
                            <div class="select-type">
                                <app-select-market></app-select-market>
                            </div>
                        </div>
                        <br>

                        <div
                            style="display: flex;color: #626262;font-size: 14px;font-weight: 700;margin-bottom: 10px;align-items: center;">
                            Buscar estación: &nbsp;
                            <label
                                style="margin-bottom: 0;width: 50%;font-size: 1rem;font-weight: 400;line-height: 1.5;">
                                <input type="search" class="form-control form-control-sm"
                                    placeholder="Nombre de la estación"
                                    style="background: white;font-size: 1rem;font-weight: 400;line-height: 1.5;"
                                    (keyup)="filterList($event)" aria-controls="datatable" />
                            </label>
                        </div>
                        <br>
                        <table class="table table-hover htable btable no-margin no-padding">
                            <thead>
                                <tr>
                                    <td style="width: 30%;" class="text-center">
                                        <strong>
                                            Seleccionar
                                        </strong>
                                    </td>
                                    <td style="width: 70%;" class="text-center">
                                        <strong>
                                            Estación
                                        </strong>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border-bottom: none !important;">
                                    <td style="width: 30%;" class="text-center">
                                        <input type="checkbox" id="selectAll" [(ngModel)]="selectAll"
                                            (ngModelChange)="selectStations()">
                                        <label for="selectAll"></label>
                                    </td>
                                    <td class="without" style="width: 70%;">
                                        <span>
                                            <strong>
                                                Seleccionar Todas las Estaciones
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngFor="let station of stationsGroup">
                                    <td style="width: 30%;" class="text-center">
                                        <input type="checkbox" id="{{station.cre}}" [(ngModel)]="station.selected"
                                            (change)="modelChangeFn($event)">
                                        <label for="{{station.cre}}"></label>
                                    </td>
                                    <td style="width: 70%;">{{station.alias}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center mt-3">
                        <button type="button" class="save" data-dismiss="modal" (click)="createCluster()">Crear
                            Grupo</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-content" *ngIf="step === 'list'">
        <div class="modal-header">
            <h5 class="modal-title text-center" id="uploadSalesLabel">
                Lista de Grupos
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetOption()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <table class="table table-hover htable btable no-margin no-padding">
                <thead>
                    <tr>
                        <td style="width: 70%;" class="text-center">
                            <strong>
                                Grupo
                            </strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cluster of clusters">
                        <td style="width: 70%;">
                            {{cluster.name}}
                        </td>
                        <td class="without-border text-center" style="width: 5%;" *ngIf="rol !== 'Lector'">
                            <i class="fa-regular fa-pencil-square-o icon edit" aria-hidden="true"
                                (click)="changeOption('edit', cluster)"></i>
                        </td>
                        <td class="without-border text-center" style="width: 5%;" *ngIf="rol !== 'Lector'">
                            <i class="fa-regular fa-trash-o icon delete" aria-hidden="true"
                                (click)="warningDeleteCluster(cluster._id)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="footer-modal">
        </div>
    </div>

    <div class="modal-content" *ngIf="step === 'edit'">
        <div class="modal-header">
            <button type="button" class="close">
            </button>
            <h5 class="modal-title text-center" id="tableColumns">
                Editar Grupo
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetOption()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12" style="padding: 0;">
                        <label>Nombre del grupo</label>
                        <input type="text" class="form-control" [(ngModel)]="clusterName" />
                        <label class="mt-3">Selecciona las estaciones que corresponden al grupo</label>
                        <div
                            style="display: inline-block;color: #626262;font-size: 14px;font-weight: 700;margin-bottom: 10px;">
                            Filtrar estaciones por plaza:
                            <div class="select-type">
                                <app-select-market></app-select-market>
                            </div>
                        </div>
                        <br>

                        <div
                            style="display: flex;color: #626262;font-size: 14px;font-weight: 700;margin-bottom: 10px;align-items: center;">
                            Buscar estación: &nbsp;
                            <label
                                style="margin-bottom: 0;width: 50%;font-size: 1rem;font-weight: 400;line-height: 1.5;">
                                <input type="search" class="form-control form-control-sm"
                                    placeholder="Nombre de la estación"
                                    style="background: white;font-size: 1rem;font-weight: 400;line-height: 1.5;"
                                    (keyup)="filterList($event)" aria-controls="datatable" />
                            </label>
                        </div>
                        <table class="table table-hover htable btable no-margin no-padding">
                            <thead>
                                <tr>
                                    <td style="width: 30%;" class="text-center">
                                        <strong>
                                            Seleccionar
                                        </strong>
                                    </td>
                                    <td style="width: 70%;" class="text-center">
                                        <strong>
                                            Estación
                                        </strong>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border-bottom: none !important;">
                                    <td style="width: 30%;" class="text-center">
                                        <input type="checkbox" id="selectAll" [(ngModel)]="selectAll"
                                            (ngModelChange)="selectStations()">
                                        <label for="selectAll"></label>
                                    </td>
                                    <td class="without" style="width: 70%;">
                                        <span>
                                            <strong>
                                                Seleccionar Todas las Estaciones
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngFor="let station of stationsGroup">
                                    <td style="width: 30%;" class="text-center">
                                        <input type="checkbox" id="{{station.cre}}" [(ngModel)]="station.selected"
                                            (change)="modelChangeFn($event)">
                                        <label for="{{station.cre}}"></label>
                                    </td>
                                    <td style="width: 70%;">{{station.alias}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center mt-3">
                        <button type="button" class="save" data-dismiss="modal" (click)="updateCluster()">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>