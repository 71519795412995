<div class="row">
  <div class="col-md-12">
    <div class="table-responsive supertable-init no-margin no-padding">
      <table class="table table-hover htable btable no-margin no-padding">
        <thead>
          <tr class="row-title">
            <td *ngFor="let item of [].constructor(columns)" class=""><span class="placeholder-text"></span></td>
          </tr>
        </thead>
        <tbody>
          <tr class="placeholder-row" *ngFor="let item of [].constructor(rows)">
            <td *ngFor="let item of [].constructor(columns)" class=""><span class="placeholder-text"></span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>