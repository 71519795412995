<ng-container *ngIf="!ngxDisabled">
    <div class="budget-white" *ngIf="format">
        <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
            [(ngModel)]="week" appendTo="body" placeholder="Seleccionar Semana" (select)="changeWeek($event)"
            [disabled]="ngxDisabled" dropDownMenuOtherClasses="budget">
            <ng-template ngx-select-option-not-found let-input>
                "{{input}}" no encontrado
            </ng-template>
        </ngx-select>
    </div>

    <div *ngIf="!format">
        <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
            [(ngModel)]="week" placeholder="Seleccionar Semana" (select)="changeWeek($event)">
            <ng-template ngx-select-option-not-found let-input>
                "{{input}}" no encontrado
            </ng-template>
        </ngx-select>
    </div>
</ng-container>