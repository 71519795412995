import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-price-rules',
  templateUrl: './price-rules.component.html',
  styleUrls: ['./price-rules.component.scss']
})
export class PriceRulesComponent implements OnInit, OnChanges {

  @Input() idStation: any;
  @Input() product: any;
  @Input() status: any;
  @Input() secondary: boolean;
  @Output() changeList: EventEmitter<any> = new EventEmitter();

  dropdownList = [];

  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas las estaciones',
    unSelectAllText: 'Ninguna estación',
    itemsShowLimit: 2,
    allowSearchFilter: false,
    maxHeight: 140,
    noDataAvailablePlaceholderText: 'No hay datos disponibles'
  };

  dropdownSingleSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas las estaciones',
    unSelectAllText: 'Ninguna estación',
    itemsShowLimit: 1,
    allowSearchFilter: false,
    maxHeight: 140,
    noDataAvailablePlaceholderText: 'No hay datos disponibles'
  };

  reqst = 0;
  view = 'rules';
  step = 'rules';
  rules: any;
  idRule: any;
  rule: any;
  productType = 'regular';
  newRule = { name: 'Precio Máximo', value: '5f91cec38257b357a7e78353' };
  stationOptions: any = [];
  public ruleForm: UntypedFormGroup;
  public addresses: UntypedFormArray;
  name: any;
  description: any;
  competitors: any;
  condition = 'all';
  operation = 'add';
  operator = 'equal';
  quantity = .1;
  unit = 'price';
  stationPriceRuleAssociated: any;
  activeRule: any;
  idActiveRule: any;
  ruleSelected = 0;
  stationsSelected = false;
  hasRule = false;
  addRuleA = false;
  typeRule = 'Simple';
  typeC = '';
  currentStation = this.gasprecioService.currentStation;
  currentStationSub = this.gasprecioService.currentStation$.subscribe(station => {
    this.currentStation = station;
  });

  regularSelected = false;
  premiumSelected = false;
  dieselSelected = false;

  constructor(
    private gasprecioService: GasprecioService,
    private authenticationService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.ruleForm = this.formBuilder.group({
      ruleName: ['', [Validators.required]],
      ruleDescription: ['', [Validators.required]],
      newRule: ['5f91cec38257b357a7e78353', [Validators.required]],
      competitors: ['all'],
      listCompetitors: [[]],
      operation: ['add', [Validators.required]],
      quantity: [.01, [Validators.required]],
      unit: ['price', [Validators.required]],
      addresses: this.formBuilder.array([])
    });
  }

  modelChangeFn(e) {
    if (this.ruleSelected === 2) {
      this.competitors.forEach(element => {
        if (e.target.checked && e.target.id === element.cre_id) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      });

    }
  }

  onValueChanges(): void {

    this.ruleForm.get('competitors')?.valueChanges
      .subscribe(value => {
        if (value === 'some') {
          this.ruleForm.get('listCompetitors').setValidators([Validators.required]);
          this.ruleForm.get('listCompetitors').updateValueAndValidity();
        } else {
          this.ruleForm.get('listCompetitors').setValidators([]);
          this.ruleForm.get('listCompetitors').updateValueAndValidity();
        }
      });

    this.ruleForm.get('newRule')?.valueChanges.subscribe(val => {
      if (val === '62c71c0e6958593f71527a96') {
        this.ruleForm.controls['operation'].setValue('add');
        this.ruleForm.controls['competitors'].setValue('some');
        this.ruleForm.controls['listCompetitors'].setValue([this.dropdownList[0]]);
        this.ruleForm.controls['quantity'].setValue(1.0);
        this.ruleForm.controls['unit'].setValue('price');
      } else if (val === '64120a06b3577f98cbeb8676') {
        this.ruleForm.controls['operation'].setValue('equal');
        this.ruleForm.controls['competitors'].setValue('some');
        this.ruleForm.controls['listCompetitors'].setValue([this.dropdownList[0]]);
        this.ruleForm.controls['quantity'].setValue(1.0);
        this.ruleForm.controls['unit'].setValue('price');
      } else if (val === '5fcfddd74dfe332667b0236f') {
        this.ruleForm.controls['operation'].setValue('major_equal');
        this.ruleForm.controls['competitors'].setValue('all');
        this.ruleForm.controls['quantity'].setValue(1.0);
        this.ruleForm.controls['unit'].setValue('price');
      } else if (val === '5ff67ce8d33a1863fb16a6ed') {
        this.ruleForm.controls['operation'].setValue('minor_equal');
        this.ruleForm.controls['competitors'].setValue('all');
        this.ruleForm.controls['quantity'].setValue(1.0);
      } else if (val === '5fa6dfac0215d0e7515991ab') {
        this.ruleForm.controls['operation'].setValue('major');
        this.ruleForm.controls['competitors'].setValue('all');
        this.ruleForm.controls['quantity'].setValue(16.0);
        this.ruleForm.controls['unit'].setValue('price');
      } else if (val === '66a11d079524f85624fa03c4') {
        this.ruleForm.controls['operation'].setValue('add');
        this.ruleForm.controls['competitors'].setValue('all');
        this.ruleForm.controls['quantity'].setValue(.10);
        this.ruleForm.controls['unit'].setValue('price');
      } else {
        this.ruleForm.controls['operation'].setValue('add');
        this.ruleForm.controls['competitors'].setValue('all');
        this.ruleForm.controls['quantity'].setValue(.10);
        this.ruleForm.controls['unit'].setValue('price');
      }
    });

    this.ruleForm.get('addresses')?.valueChanges.subscribe(val => {
      val.forEach((element: any, index: any) => {

        if (element.operator === 'major' || element.operator === 'major_equal' || element.operator === 'equal' || element.operator === 'minor_equal' || element.operator === 'minor') {
          this.addresses.at(index).patchValue({
            unit: 'price'
          }, { emitEvent: false, onlySelf: true });
        }
        if (element.idRule === '62c71c0e6958593f71527a96') {
          if (element.competitors === 'all') {
            this.addresses.at(index).patchValue({
              competitors: 'some',
            }, { emitEvent: false, onlySelf: true });
          }
        } else if (element.idRule === '64120a06b3577f98cbeb8676') {
          if (element.competitors === 'all') {
            this.addresses.at(index).patchValue({
              competitors: 'some',
            }, { emitEvent: false, onlySelf: true });
          }
          if (element.operator === 'add' || element.operator === 'subtract' || element.operator === 'without') {
            this.addresses.at(index).patchValue({
              operator: 'equal'
            }, { emitEvent: false, onlySelf: true });
          }
        } else if (element.idRule === '5fcfddd74dfe332667b0236f' && (element.operator !== 'major_equal' && element.operator !== 'major')) {
          this.addresses.at(index).patchValue({
            operator: 'major_equal'
          }, { emitEvent: false, onlySelf: true });
        } else if (element.idRule === '66a11d079524f85624fa03c4' && (element.operator !== 'add' )) {
          this.addresses.at(index).patchValue({
            operator: 'add',
            unit: 'price'
          }, { emitEvent: false, onlySelf: true });
        } else if (element.idRule === '5ff67ce8d33a1863fb16a6ed' && (element.operator !== 'minor_equal' && element.operator !== 'minor')) {
          this.addresses.at(index).patchValue({
            operator: 'minor_equal'
          }, { emitEvent: false, onlySelf: true });
        }
      });
    });
  }

  ngOnInit(): void {
    this.idStation = localStorage.getItem('idStation') ? localStorage.getItem('idStation') : this.authenticationService.idStation;
    this.getListPriceRules();
    this.getCompetitors();
    this.onValueChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.status === 'new') {
      this.clearRules();
      this.typeC = '';
      this.view = 'rules';
      this.step = 'rules';
      this.ruleForm.get('ruleName')?.enable();
      this.ruleForm.get('ruleDescription')?.enable();
      this.ruleForm.patchValue({
        ruleName: '',
        ruleDescription: '',
        newRule: '5f91cec38257b357a7e78353',
        competitors: 'all',
        listCompetitors: [],
        operation: 'add',
        quantity: .01,
        unit: 'price'
      });
    }
    if (changes.idStation) {
      if (this.rules !== undefined) {
        this.reqst = 0;
        this.getCompetitors();
        this.getListPriceRules();
      }
      if (this.product === undefined || this.product === null || this.product === '') {
        this.productType = 'regular';
      } else {
        this.productType = this.product;
      }
    } else if (changes.product) {
      this.reqst = 0;
      this.productType = this.product;
      this.hasRule = false;
      this.ruleSelected = 0;
      this.getCompetitors();
      this.getListPriceRules();
    } else if (changes.status) {
      this.reqst = 0;
      this.productType = this.product;
      this.addRuleA = false;
      this.hasRule = false;
      this.ruleSelected = 0;
      this.condition = 'all';
      this.operation = 'add';
      this.operator = 'equal';
      this.quantity = .01;
      this.unit = 'price';
      this.getCompetitors();
      this.getListPriceRules();
    }
  }

  get rulesControls() {
    return this.ruleForm.get('addresses')['controls'];
  }

  createRule(): UntypedFormGroup {
    return this.formBuilder.group({
      idRule: '62c71c0e6958593f71527a96',
      competitors: 'some',
      listCompetitors: this.competitors[0].cre_id,
      operator: 'add',
      quantity: .01,
      unit: 'price',
    });
  }

  addRule(type: any): void {
    this.typeC = type;
    this.addresses = this.ruleForm.get('addresses') as UntypedFormArray;
    if (this.addresses.length <= 3) {
      this.addresses.push(this.createRule());
      if (this.addresses.controls.length > 0) {
        this.typeRule = 'Compuesta';
      }
    } else {
      this.gasprecioService.displayMessage('error', 'Error', 'Has alcanzado el limite de condiciones para esta regla')
    }
  }

  removeRule(i: number) {
    this.addresses.removeAt(i);
    if (this.addresses.controls.length === 0) {
      this.typeRule = 'Simple';
      this.typeC = '';
    }
  }

  clearRules() {
    const control = this.ruleForm.get('addresses') as UntypedFormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
  }

  // Get info

  getListPriceRules() {
    if (this.idStation !== undefined) {
      this.gasprecioService.getListPriceRules(this.idStation, this.productType).subscribe({
        next: (data: any) => {
          this.rules = data;
          this.rules.forEach((element: any, index: number) => {
            element.id = index + 1;
            element.selected = false;
            this.reqst = 1;
          });

          if (this.status === 'edit') {
            this.step = '';
            this.typeC = '';
            this.view = '';
            this.getActiveRule();
          }
        }, error: (err) => {
          console.log(err);
        }
      });
    }
  }

  getActiveRule() {
    if (this.idStation !== undefined) {
      const url = this.secondary ? this.gasprecioService.getActiveRuleSecondary(this.idStation, this.productType) : this.gasprecioService.getActiveRule(this.idStation, this.productType);
      url.subscribe({
        next: (data: any) => {
          if (data[0]) {
            if (this.competitors !== undefined) {
              this.competitors.forEach((station: any) => {
                station.selected = false;
              });
            }
            this.activeRule = data[0];
            this.idActiveRule = this.activeRule._id;
            if (this.activeRule.rule._id) {
              this.idRule = this.activeRule.rule._id;
              this.hasRule = true;
              this.addRuleA = true;
            }
            if (this.activeRule.composition.rules[0]) {
              if (this.activeRule.composition.rules[0].operations[0]) {
                this.unit = this.activeRule.composition.rules[0].operations[0].unit;
                this.quantity = this.activeRule.composition.rules[0].operations[0].value;
                this.operator = this.activeRule.composition.rules[0].operations[0].type;
              }
              if (this.activeRule.composition.rules[0].competitors) {
                this.activeRule.composition.rules[0].competitors.forEach((element: any) => {
                  if (this.competitors !== undefined) {
                    this.competitors.forEach((station: any) => {
                      if (station.cre_id === element) {
                        station.selected = true;
                      }
                    });
                  }
                });
              }
            }

            if (this.activeRule.composition.rules.length > 1) {
              this.clearRules();
              const rulesC = this.activeRule.composition.rules;
              rulesC.forEach((element: any, index: number) => {
                if (index > 0) {
                  let rule;
                  if (element.operations.length > 0) {
                    if (element.hwmCompetitors === 'all') {
                      rule = this.formBuilder.group({
                        idRule: element.rule._id,
                        competitors: 'all',
                        listCompetitors: [],
                        operator: element.operations[0].type,
                        quantity: element.operations[0].value,
                        unit: element.operations[0].unit,
                      });
                    } else {
                      if (element.rule._id === '62c71c0e6958593f71527a96' || element.rule._id === '64120a06b3577f98cbeb8676') {
                        rule = this.formBuilder.group({
                          idRule: element.rule._id,
                          competitors: element.hwmCompetitors,
                          listCompetitors: element.competitors,
                          operator: element.operations[0].type,
                          quantity: element.operations[0].value,
                          unit: element.operations[0].unit,
                        });
                      } else {
                        rule = this.formBuilder.group({
                          idRule: element.rule._id,
                          competitors: element.hwmCompetitors,
                          listCompetitors: [element.competitors],
                          operator: element.operations[0].type,
                          quantity: element.operations[0].value,
                          unit: element.operations[0].unit,
                        });
                      }
                    }
                  } else {
                    if (element.hwmCompetitors === 'all') {
                      rule = this.formBuilder.group({
                        idRule: element.rule._id,
                        competitors: 'all',
                        listCompetitors: [],
                        operator: 'without'
                      });
                    } else {
                      if (element.rule._id === '62c71c0e6958593f71527a96' || element.rule._id === '64120a06b3577f98cbeb8676') {
                        rule = this.formBuilder.group({
                          idRule: element.rule._id,
                          competitors: element.hwmCompetitors,
                          listCompetitors: element.competitors,
                          operator: 'without'
                        });
                      } else {
                        rule = this.formBuilder.group({
                          idRule: element.rule._id,
                          competitors: element.hwmCompetitors,
                          listCompetitors: [element.competitors],
                          operator: 'without'
                        });
                      }
                    }
                  }
                  this.typeC = element.operator.toUpperCase();
                  this.addresses = this.ruleForm.get('addresses') as UntypedFormArray;
                  this.addresses.push(rule);
                }
              });
            } else {
              this.clearRules();
            }
            this.rules.forEach((element: any) => {
              if (this.activeRule.rule.name === element.name) {
                element.selected = true;
                this.ruleSelected = element.id;
                this.rule = element.id;
              }
            });

            if (this.status === 'edit' && !this.activeRule.addRule) {
              if (this.ruleSelected === 1) {
                this.step = 'rules';
                this.view = 'rules';
              } else if (this.ruleSelected === 2 || this.ruleSelected === 3 || this.ruleSelected === 4 || this.ruleSelected === 5 || this.ruleSelected === 6 || this.ruleSelected === 10) {
                this.step = 'stations';
                this.view = 'rules';
              } else if (this.ruleSelected === 7 || this.ruleSelected === 8 || this.ruleSelected === 9) {
                this.step = 'operators';
                this.view = 'rules';
              } else if (this.ruleSelected === 11 && this.productType === 'premium') {
                this.step = 'reg-prem';
                this.view = 'rules';
              } else {
                this.view = 'new';
                this.step = 'new';
              }
            } else if (this.status === 'edit' && this.activeRule.addRule) {
              this.view = 'new';
              this.step = 'new';
            }
            if (this.activeRule.addRule) {
              if (this.activeRule.composition.rules[0].operations[0]) {
                this.ruleForm.patchValue({
                  ruleName: this.activeRule.rule.name,
                  ruleDescription: this.activeRule.rule.description,
                  newRule: this.activeRule.composition.rules[0].rule._id,
                  operation: this.activeRule.composition.rules[0].operations[0].type,
                  quantity: this.activeRule.composition.rules[0].operations[0].value,
                  unit: this.activeRule.composition.rules[0].operations[0].unit,
                  competitors: this.activeRule.composition.rules[0].hwmCompetitors,
                });
              } else {
                this.ruleForm.patchValue({
                  ruleName: this.activeRule.rule.name,
                  ruleDescription: this.activeRule.rule.description,
                  newRule: this.activeRule.composition.rules[0].rule._id,
                  operation: 'without',
                  quantity: 0,
                  unit: 'price',
                  competitors: this.activeRule.composition.rules[0].hwmCompetitors,
                });
              }

              this.ruleForm.get('ruleName');
              this.ruleForm.get('ruleDescription');
              if (this.activeRule.composition.rules[0].hwmCompetitors === 'all') {
                const competitors: any = [];
                this.stationOptions.forEach(() => {
                  this.ruleForm.controls['listCompetitors'].setValue(competitors);
                });
              } else {
                this.ruleForm.controls['listCompetitors'].setValue(this.activeRule.composition.rules[0].competitors);
              }
            }
          } else {
            this.hasRule = false;
            this.ruleSelected = 0;
            this.addRuleA = false;
          }
        }, error: (err) => {
          console.log(err);
        }
      });
    }
  }

  getCompetitors() {
    this.gasprecioService.getCompetitors(this.idStation).subscribe((data: any) => {
      this.stationOptions = [];
      this.dropdownList = [];
      this.competitors = data;
      this.competitors.forEach((element: any) => {
        element.selected = true;
        element['name'] = element['name'] ? this.gasprecioService.removeSADECV(element['name']) : '';
        element['brand'] = element['brand'] ? this.gasprecioService.removeSADECV(element['brand']) : '';
        element['brand'] = element['brand'] === 'Bp' ? 'BP' : element['brand'];
        this.stationOptions.push({ price: '$ ' + element[this.product], name: element.name, distance: element['distance'], value: element.cre_id });
        let price = element[this.product];
        this.dropdownList.push({ item_id: element['cre_id'], item_text: element.name + ' - ' + this.gasprecioService.formatDistance(element['distance']) + ' - (Precio: $ ' + price + ')' });
      });
    });
  }

  // Post info

  registerRule() {
    const stationsSelected: any = [];
    if (this.rule === 2 || this.rule === 3 || this.rule === 4 || this.rule === 5 || this.rule === 6 || this.rule === 10) {
      this.competitors.forEach((element: any) => {
        if (element.selected === true) {
          stationsSelected.push(element.cre_id);
        }
      });
    }

    if (!this.hasRule) {
      this.gasprecioService.registerRule(this.rule, this.productType, this.idStation, this.idRule, stationsSelected, this.quantity, this.unit, this.operator).subscribe({
        next: () => {
          this.successSave();
        }, error: (err) => {
          console.log(err);
        }
      });
    } else if (this.hasRule) {
      this.gasprecioService.registerRule(this.rule, this.productType, this.idStation, this.idRule, stationsSelected, this.quantity, this.unit, this.operator, this.idActiveRule).subscribe({
        next: () => {
          this.successUpdate();
        }, error: () => {
          this.gasprecioService.displayMessage('error', 'Error', 'Revisa que hayas seleccionado al menos un competidor')
        }
      });
    }
  }

  registerRuleSecondary() {
    const stationsSelected: any = [];
    if (this.rule === 2 || this.rule === 3 || this.rule === 4 || this.rule === 5 || this.rule === 6 || this.rule === 10) {
      this.competitors.forEach((element: any) => {
        if (element.selected === true) {
          stationsSelected.push(element.cre_id);
        }
      });
    }
    if (!this.hasRule) {
      this.gasprecioService.registerRuleSecondary(this.rule, this.productType, this.idStation, this.idRule, stationsSelected, this.quantity, this.unit, this.operator).subscribe({
        next: () => {
          this.successSave();
        }, error: (err) => {
          console.log(err);
        }
      });
    } else if (this.hasRule) {
      this.gasprecioService.registerRuleSecondary(this.rule, this.productType, this.idStation, this.idRule, stationsSelected, this.quantity, this.unit, this.operator, this.idActiveRule).subscribe({
        next: () => {
          this.successUpdate();
        }, error: () => {
          this.gasprecioService.displayMessage('error', 'Error', 'Revisa que hayas seleccionado al menos un competidor')
        }
      });
    }
  }

  setActiveRule() {
    this.gasprecioService.statusRule(this.stationPriceRuleAssociated, 'active').subscribe(() => {
      this.successSave();
    });
  }

  registerNewRuleComponent() {
    const rulesLength = this.ruleForm.get('addresses')?.value;
    let typeOperator: any;
    let operator: any;
    let data;
    let rules;

    if (this.ruleForm.get('competitors')?.value === 'all') {
      const competitors: any = [];
      this.stationOptions.forEach((station: any) => {
        competitors.push(station.value);
        this.ruleForm.controls['listCompetitors'].setValue(competitors);
      });
    }

    if (this.ruleForm.get('competitors')?.value === 'some' && this.ruleForm.get('listCompetitors')?.value.length === 0) {
      this.ruleForm.controls['listCompetitors'].setValue([this.dropdownList[0]]);
    }

    if (this.typeC === 'AND') {
      typeOperator = 'and';
    } else if (this.typeC === 'OR') {
      typeOperator = 'or';
    }

    if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
      operator = 'arithmetic';
    } else {
      operator = 'comparison';
    }

    if (!this.hasRule) {
      if (this.ruleForm.get('operation')?.value !== 'without') {
        if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
          operator = 'arithmetic';
        } else {
          operator = 'comparison';
        }

        if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': undefined,
            'competitors': [],
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        } else {
          const competitorsList = []
          this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
            if (station['item_id']) {
              competitorsList.push(station['item_id']);
            } else {
              competitorsList.push(station)
            }
          });

          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': this.ruleForm.get('competitors')?.value,
            'competitors': competitorsList,
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        }
      } else {
        const competitorsList = []
        this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
          if (station['item_id']) {
            competitorsList.push(station['item_id']);
          } else {
            competitorsList.push(station)
          }
        });

        rules = [{
          'rule': this.ruleForm.get('newRule')?.value,
          'hwmCompetitors': this.ruleForm.get('competitors')?.value,
          'competitors': competitorsList,
          'operations': [],
          'priority': 1,
          'operator': undefined
        }];
      }

      if (rulesLength.length > 0) {
        rulesLength.forEach((element: any, index: number) => {
          const competitors: any = [];
          if (element.competitors === 'all') {
            this.stationOptions.forEach((station: any) => {
              competitors.push(station.value);
            });
          } else {
            element['listCompetitors'].forEach((station: any) => {
              competitors.push(station.item_id);
            });
          }

          if (element.operator === 'add' || element.operator === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (element.operator !== 'without') {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': competitors,
              'operations': [
                { 'type': element.operator, 'value': element.quantity, 'unit': element.unit, 'operator': operator }
              ],
              'priority': index + 2,
              'operator': typeOperator
            });
          } else {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': competitors,
              'operations': [],
              'priority': index + 2,
              'operator': typeOperator
            });
          }
        });
      }

      if (rulesLength.length === 0) {
        if (this.ruleForm.get('operation')?.value !== 'without') {
          if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        } else {
          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        }
      } else {
        data = {
          'name': this.ruleForm.get('ruleName')?.value,
          'description': this.ruleForm.get('ruleDescription')?.value,
          'product': this.product,
          'station': this.idStation,
          'addRule': 'true',
          'composition': {
            'type': 'compound',
            'rules': rules
          },
          'secondary': true
        };
      }
    } else if (this.hasRule) {
      if (this.ruleForm.get('operation')?.value !== 'without') {
        if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
          operator = 'arithmetic';
        } else {
          operator = 'comparison';
        }

        if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': undefined,
            'competitors': [],
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        } else {
          const competitorsList = []
          this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
            if (station['item_id']) {
              competitorsList.push(station['item_id']);
            } else {
              competitorsList.push(station)
            }
          });

          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': this.ruleForm.get('competitors')?.value,
            'competitors': competitorsList,
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        }
      } else {
        const competitorsList = []
        this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
          if (station['item_id']) {
            competitorsList.push(station['item_id']);
          } else {
            competitorsList.push(station)
          }
        });

        rules = [{
          'rule': this.ruleForm.get('newRule')?.value,
          'hwmCompetitors': this.ruleForm.get('competitors')?.value,
          'competitors': competitorsList,
          'operations': [],
          'priority': 1,
          'operator': undefined
        }];
      }

      if (rulesLength.length > 0) {
        rulesLength.forEach((element: any, index: number) => {
          if (element.competitors === 'all') {
            const competitors: any = [];
            this.stationOptions.forEach((station: any) => {
              competitors.push(station.value);
              element.listCompetitors = competitors;
            });
          }

          if (element.operator === 'add' || element.operator === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (element.operator !== 'without') {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': element.listCompetitors,
              'operations': [
                { 'type': element.operator, 'value': element.quantity, 'unit': element.unit, 'operator': operator }
              ],
              'priority': index + 2,
              'operator': typeOperator
            });
          } else {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': element.listCompetitors,
              'operations': [],
              'priority': index + 2,
              'operator': typeOperator
            });
          }
        });
      }

      if (rulesLength.length === 0) {
        if (this.ruleForm.get('operation')?.value !== 'without') {

          if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        } else {
          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        }
      } else {
        data = {
          'name': this.ruleForm.get('ruleName')?.value,
          'description': this.ruleForm.get('ruleDescription')?.value,
          'product': this.product,
          'station': this.idStation,
          'addRule': 'true',
          'composition': {
            'type': 'compound',
            'rules': rules
          },
          'secondary': true
        };
      }
    }

    if (!this.hasRule) {
      if (this.regularSelected) {
        const newData = JSON.parse(JSON.stringify(data));
        newData['product'] = 'regular';
        this.gasprecioService.registerNewRuleC(newData).subscribe(() => {

        });
      }

      if (this.premiumSelected) {
        const newData = JSON.parse(JSON.stringify(data));
        newData['product'] = 'premium';
        this.gasprecioService.registerNewRuleC(newData).subscribe(() => {

        });
      }

      if (this.dieselSelected) {
        const newData = JSON.parse(JSON.stringify(data));
        newData['product'] = 'diesel';
        this.gasprecioService.registerNewRuleC(newData).subscribe(() => {

        });
      }

      this.gasprecioService.registerNewRuleC(data).subscribe(() => {
        this.successSave();
      });
    } else if (this.hasRule) {
      this.gasprecioService.updateRuleC(this.idActiveRule, data).subscribe(() => {
        this.successUpdate();
      });
    }
  }

  registerNewRuleSecondaryComponent() {
    const rulesLength = this.ruleForm.get('addresses')?.value;
    let typeOperator: any;
    let operator: any;
    let data;
    let rules;
    
    if (this.ruleForm.get('competitors')?.value === 'all') {
      const competitors: any = [];
      this.stationOptions.forEach((station: any) => {
        competitors.push(station.value);
        this.ruleForm.controls['listCompetitors'].setValue(competitors);
      });
    }

    if (this.ruleForm.get('competitors')?.value === 'some' && this.ruleForm.get('listCompetitors')?.value.length === 0) {
      this.ruleForm.controls['listCompetitors'].setValue([this.dropdownList[0]]);
    }

    if (this.typeC === 'AND') {
      typeOperator = 'and';
    } else if (this.typeC === 'OR') {
      typeOperator = 'or';
    }

    if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
      operator = 'arithmetic';
    } else {
      operator = 'comparison';
    }

    if (!this.hasRule) {
      if (this.ruleForm.get('operation')?.value !== 'without') {
        if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
          operator = 'arithmetic';
        } else {
          operator = 'comparison';
        }

        if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': undefined,
            'competitors': [],
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        } else {
          const competitorsList = []
          this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
            if (station['item_id']) {
              competitorsList.push(station['item_id']);
            } else {
              competitorsList.push(station)
            }
          });

          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': this.ruleForm.get('competitors')?.value,
            'competitors': competitorsList,
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        }
      } else {
        const competitorsList = []
        this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
          if (station['item_id']) {
            competitorsList.push(station['item_id']);
          } else {
            competitorsList.push(station)
          }
        });

        rules = [{
          'rule': this.ruleForm.get('newRule')?.value,
          'hwmCompetitors': this.ruleForm.get('competitors')?.value,
          'competitors': competitorsList,
          'operations': [],
          'priority': 1,
          'operator': undefined
        }];
      }

      if (rulesLength.length > 0) {
        rulesLength.forEach((element: any, index: number) => {
          const competitors: any = [];
          if (element.competitors === 'all') {
            this.stationOptions.forEach((station: any) => {
              competitors.push(station.value);
            });
          } else {
            element['listCompetitors'].forEach((station: any) => {
              competitors.push(station.item_id);
            });
          }

          if (element.operator === 'add' || element.operator === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (element.operator !== 'without') {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': competitors,
              'operations': [
                { 'type': element.operator, 'value': element.quantity, 'unit': element.unit, 'operator': operator }
              ],
              'priority': index + 2,
              'operator': typeOperator
            });
          } else {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': competitors,
              'operations': [],
              'priority': index + 2,
              'operator': typeOperator
            });
          }
        });
      }

      if (rulesLength.length === 0) {
        if (this.ruleForm.get('operation')?.value !== 'without') {
          if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        } else {
          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        }
      } else {
        data = {
          'name': this.ruleForm.get('ruleName')?.value,
          'description': this.ruleForm.get('ruleDescription')?.value,
          'product': this.product,
          'station': this.idStation,
          'addRule': 'true',
          'composition': {
            'type': 'compound',
            'rules': rules
          },
          'secondary': true
        };
      }
    } else if (this.hasRule) {
      if (this.ruleForm.get('operation')?.value !== 'without') {
        if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
          operator = 'arithmetic';
        } else {
          operator = 'comparison';
        }

        if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': undefined,
            'competitors': [],
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        } else {
          const competitorsList = []
          this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
            if (station['item_id']) {
              competitorsList.push(station['item_id']);
            } else {
              competitorsList.push(station)
            }
          });

          rules = [{
            'rule': this.ruleForm.get('newRule')?.value,
            'hwmCompetitors': this.ruleForm.get('competitors')?.value,
            'competitors': competitorsList,
            'operations': [
              { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
            ],
            'priority': 1,
            'operator': undefined
          }];
        }
      } else {
        const competitorsList = []
        this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
          if (station['item_id']) {
            competitorsList.push(station['item_id']);
          } else {
            competitorsList.push(station)
          }
        });

        rules = [{
          'rule': this.ruleForm.get('newRule')?.value,
          'hwmCompetitors': this.ruleForm.get('competitors')?.value,
          'competitors': competitorsList,
          'operations': [],
          'priority': 1,
          'operator': undefined
        }];
      }

      if (rulesLength.length > 0) {
        rulesLength.forEach((element: any, index: number) => {
          if (element.competitors === 'all') {
            const competitors: any = [];
            this.stationOptions.forEach((station: any) => {
              competitors.push(station.value);
              element.listCompetitors = competitors;
            });
          }

          if (element.operator === 'add' || element.operator === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (element.operator !== 'without') {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': element.listCompetitors,
              'operations': [
                { 'type': element.operator, 'value': element.quantity, 'unit': element.unit, 'operator': operator }
              ],
              'priority': index + 2,
              'operator': typeOperator
            });
          } else {
            rules.push({
              'rule': element.idRule,
              'hwmCompetitors': element.competitors,
              'competitors': element.listCompetitors,
              'operations': [],
              'priority': index + 2,
              'operator': typeOperator
            });
          }
        });
      }

      if (rulesLength.length === 0) {
        if (this.ruleForm.get('operation')?.value !== 'without') {

          if (this.ruleForm.get('operation')?.value === 'add' || this.ruleForm.get('operation')?.value === 'subtract') {
            operator = 'arithmetic';
          } else {
            operator = 'comparison';
          }

          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [
                    { 'type': this.ruleForm.get('operation')?.value, 'value': this.ruleForm.get('quantity')?.value, 'unit': this.ruleForm.get('unit')?.value, 'operator': operator }
                  ],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        } else {
          if (this.ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || this.ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed' || this.ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab') {
            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': undefined,
                  'competitors': [],
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          } else {
            const competitorsList = []
            this.ruleForm.get('listCompetitors')?.value.forEach((station: any) => {
              if (station['item_id']) {
                competitorsList.push(station['item_id']);
              } else {
                competitorsList.push(station)
              }
            });

            data = {
              'name': this.ruleForm.get('ruleName')?.value,
              'description': this.ruleForm.get('ruleDescription')?.value,
              'product': this.product,
              'station': this.idStation,
              'addRule': 'true',
              'composition': {
                'type': 'simple',
                'rules': [{
                  'rule': this.ruleForm.get('newRule')?.value,
                  'hwmCompetitors': this.ruleForm.get('competitors')?.value,
                  'competitors': competitorsList,
                  'operations': [],
                  'priority': 1
                }]
              },
              'secondary': true
            };
          }
        }
      } else {
        data = {
          'name': this.ruleForm.get('ruleName')?.value,
          'description': this.ruleForm.get('ruleDescription')?.value,
          'product': this.product,
          'station': this.idStation,
          'addRule': 'true',
          'composition': {
            'type': 'compound',
            'rules': rules
          },
          'secondary': true
        };
      }
    }

    if (!this.hasRule) {
      if (this.regularSelected) {
        const newData = JSON.parse(JSON.stringify(data));
        newData['product'] = 'regular';
        this.gasprecioService.registerNewRuleCSecondary(newData).subscribe(() => {

        });
      }

      if (this.premiumSelected) {
        const newData = JSON.parse(JSON.stringify(data));
        newData['product'] = 'premium';
        this.gasprecioService.registerNewRuleCSecondary(newData).subscribe(() => {

        });
      }

      if (this.dieselSelected) {
        const newData = JSON.parse(JSON.stringify(data));
        newData['product'] = 'diesel';
        this.gasprecioService.registerNewRuleCSecondary(newData).subscribe(() => {

        });
      }

      this.gasprecioService.registerNewRuleCSecondary(data).subscribe(() => {
        this.successSave();
      });
    } else if (this.hasRule) {
      this.gasprecioService.updateRuleCSecondary(this.idActiveRule, data).subscribe(() => {
        this.successUpdate();
      });
    }
  }

  // Selects

  selectRule(idRule: any, rule: any, stationPriceRuleAssociated?: any) {
    this.idRule = idRule;
    this.rule = rule;
    if (this.rule === 7 || this.rule === 8 || this.rule === 9) {
      this.unit = 'percent';
      this.quantity = 1;
    } else {
      this.unit = 'price';
      this.quantity = .10;
    }

    if (stationPriceRuleAssociated) {
      this.stationPriceRuleAssociated = stationPriceRuleAssociated;
    }

    this.rules.forEach((element: any) => {
      if (idRule === element._id) {
        element.selected = true;
        this.ruleSelected = element.id;
      } else {
        element.selected = false;
      }
    });
  }

  selectStations(status: any) {
    this.stationsSelected = !this.stationsSelected;
    this.competitors.forEach((element: any) => {
      element.selected = status;
    });
  }

  unitChange($event: any) {
    this.unit = $event.target.value;
  }

  conditionChange($event: any) {
    this.condition = $event.target.value;
  }

  operationChange($event: any) {
    this.operation = $event.target.value;
  }

  operatorChange($event: any) {
    this.operator = $event.target.value;
  }

  // Views actions

  changeView(view: any) {
    this.view = view;
  }

  continue(step: any, rule: any) {
    if (this.ruleSelected === 2) {
      this.competitors.forEach((element: any, index: number) => {
        if (index === 0) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      });
    } else {
      this.competitors.forEach((element: any) => {
        element.selected = true;
      });
    }
    this.step = step;
    this.ruleSelected = rule;
  }

  back(step: any) {
    this.step = step;
  }

  cancel() {
    this.clearRules();
    this.view = 'rules';
    this.condition = 'all';
    this.operation = 'add';
    this.typeRule = 'Simple';
    this.typeC = '';
  }

  // Format data

  formatDistance(distance: any) {
    return this.gasprecioService.formatDistance(distance);
  }

  formatPrice(station: any, type: string, diff = false) {
    if (!station) {
      return false;
    }
    if (diff && this.currentStation) {
      const prevPrice = this.currentStation;
      if (!prevPrice[type] || !station[type]) {
        return 'N/A';
      }
      return (station[type] - prevPrice[type]).toFixed(2);
    }
    return station[type] ? ('$ ' + station[type]) : 'N/A';
  }

  // Modals

  async successSave() {
    Swal.fire({
      title: 'Regla de precio agregada',
      text: 'Se ha agregado la regla de precio con éxito.',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      preConfirm: () => {
        this.view = 'rules';
        this.condition = 'all';
        this.operation = 'add';
        this.typeRule = 'Simple';
        this.typeC = '';
        this.clearRules();
        this.changeList.emit();
      }
    });
  }

  async successUpdate() {
    Swal.fire({
      title: 'Regla de precio actualizada',
      text: 'Se ha actualizado la regla de precio con éxito.',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      preConfirm: () => {
        this.view = 'rules';
        this.condition = 'all';
        this.operation = 'add';
        this.typeRule = 'Simple';
        this.typeC = '';
        this.clearRules();
        this.changeList.emit();
      }
    });
  }

  onSelect(item: any) {
    this.selectedItems.push(item); 
  }

  onSelectSingle(item: any) {
    this.selectedItems = [];
    this.selectedItems.push(item);
  }

  onDeSelect(item: any) {
    this.selectedItems = this.selectedItems.filter(element => element.item_id !== item.item_id);
  }

  allItems(items) {
    this.selectedItems = items;
  }

  anyItems() {
    this.selectedItems = [];
  }

}
