import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-table-sales-quartil',
  templateUrl: './table-sales-quartil.component.html',
  styleUrls: ['./table-sales-quartil.component.scss']
})
export class TableSalesQuartilComponent implements OnInit, OnDestroy {

  daysAgo = 180
  daysAgo$!: Subscription;
  idGroup = '';
  idGroupSub$!: Subscription;
  market = '';
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  rol;
  rowsSimulation: any;
  rowsCurrent: any;
  rowsLength: number;
  rowsRecord: any;
  showSplitZones = false;
  showTable = false;
  showSimulation = false;
  showSubsubdivision = 0;
  showSubsubdivision$!: Subscription;
  showSubdivision = 0;
  showSubdivision$!: Subscription;
  showMegagas = false;
  splitZones = false;
  type;
  variableSelected = 'sales';
  variableSelected$!: Subscription;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.showMegagas = this.idGroup === GroupIds.Megagas;
    this.idGroupSub$ = this.sharedService.sharedGroup.subscribe(idGroup => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
        this.showMegagas = this.idGroup === GroupIds.Megagas;
      }
    });

    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.variableSelected$ = this.sharedService.sharedVariablePxOpt.subscribe(variableSelected => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.daysAgo$ = this.sharedService.sharedDaysOptimumPx.subscribe(daysAgo => {
      if (this.daysAgo !== daysAgo) {
        this.daysAgo = daysAgo;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.showSubdivision$ = this.sharedService.sharedShowSubdivison.subscribe(showSubdivision => {
      if (this.showSubdivision !== showSubdivision) {
        this.showSubdivision = showSubdivision;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.showSubsubdivision$ = this.sharedService.sharedShowSubsubdivison.subscribe(showSubsubdivision => {
      if (this.showSubsubdivision !== showSubsubdivision) {
        this.showSubsubdivision = showSubsubdivision;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });
  }

  ngOnInit(): void {
    this.getSalesQuartilSplitZones();
    this.getTableSalesQuartil();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.variableSelected$.unsubscribe();
    this.daysAgo$.unsubscribe();
  }

  changeShowTable() {
    this.showTable = !this.showTable;
  }

  getRowHeight(row: any) {
    return row.height;
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  changeSplitZones() {
    this.showSimulation = false;
    this.splitZones = !this.splitZones;
    this.showSubdivision = this.showSubdivision === 1 ? 0 : 1;
    if (this.showSubdivision === 0) {
      this.showSubsubdivision = 0;
    }
    this.sharedService.nextShowSubdivison = this.showSubdivision;
    this.getTableSalesQuartil();
  }

  changeSubSplitZones() {
    this.showSimulation = false;
    this.showSubsubdivision = this.showSubsubdivision === 1 ? 0 : 1;
    this.sharedService.nextShowSubsubdivison = this.showSubsubdivision;
    this.getTableSalesQuartil();
  }

  getSalesQuartilSplitZones() {
    this.gasprecioService.getSalesQuartilSplitZones(this.productType, this.daysAgo, this.showSubsubdivision).subscribe((data: any) => {
      this.showSplitZones = data;
    })
  }

  getTableSalesQuartil() {
    this.reqst = 0;
    this.rowsCurrent = [];
    this.rowsRecord = [];
    setTimeout(() => {
      this.gasprecioService.getTableSalesQuartil(this.productType, this.variableSelected, this.daysAgo, this.showSubdivision, this.showSubsubdivision).subscribe((data: any) => {
        if (data['record']) {
          this.rowsLength = (73 / data['record'].length);
          data['record'].map((element: any, index) => {
            if (element['flag_actual']) {
              this.market = element['market'];
            }
            element['pos'] = index + 1;
            data['current'][index]['showExpand'] = element['n_obs'] >= 60;
            element['showExpand'] = element['n_obs'] >= 60;
            element['n_obs'] = element['n_obs'] !== null ? element['n_obs'] : '-';
            element['diff_price_competitors'] = element['diff_price_competitors'] !== null ? element['diff_price_competitors'] : '-';
            element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
            element['sales'] = element['sales'] !== null ? element['sales'] : '-';
            element['earning'] = element['earning'] !== null ? element['earning'] : '-';
            element['sales_change'] = element['sales_change'] !== null ? element['sales_change'] : '-';
            element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
            element['margin'] = element['margin'] !== null ? element['margin'] : '-';
            element['sales_pct'] = element['sales_pct'] !== null ? element['sales_pct'] * 100 : '-';
          });

          data['current'].map((element: any, index: number) => {
            element['pos'] = index + 1;
            if (element['flag_actual']) {
              this.market = element['market'];
            }
            element['diff_price_competitors'] = element['diff_price_competitors'] !== null ? element['diff_price_competitors'] : '-';
            element['price_change'] = element['price_change'] !== null ? element['price_change'] : '-';
            element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
            element['sales7'] = element['sales7'] !== null ? element['sales7'] : '-';
            element['earning'] = element['earning'] !== null ? element['earning'] : '-';
            element['supply_price'] = element['supply_price'] !== null ? element['supply_price'] : '-';
            element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
            element['margin'] = element['margin'] !== null ? element['margin'] : '-';
          });

          this.rowsCurrent = data['current'];
          this.rowsRecord = data['record'];
        }
        this.reqst = 1;
      });
    }, 300);
  }

  simulationStatus(price: number, position: number, type: string) {
    this.reqst = 0;
    this.type = type;
    this.showSimulation = !this.showSimulation;
    if (this.showSimulation) {
      const newRowCurrent =  type === 'selling_price' ? {
        "idx": 5,
        "diff_price_competitors": '-',
        "earning": '-',
        "flag_actual": false,
        "flag_max_earning": false,
        "margin": '-',
        "price_change": '-',
        "quotient": '-',
        "sales7": '-',
        "selling_price": price,
        "supply_price": '-',
        "has_subdivisions": false,
        "has_subsubdivisions": false,
        "market": "simulation",
        "showExpand": false
      } : {
        "idx": 5,
        "diff_price_competitors": price,
        "earning": '-',
        "flag_actual": false,
        "flag_max_earning": false,
        "margin": '-',
        "price_change": '-',
        "quotient": '-',
        "sales7": '-',
        "selling_price": '-',
        "supply_price": '-',
        "has_subdivisions": false,
        "has_subsubdivisions": false,
        "market": "simulation",
        "showExpand": false
      };

      const newRowRecord = {
        "idx": 5,
        "n_obs": '-',
        "diff_price_competitors": '-',
        "earning": '-',
        "flag_actual": false,
        "flag_max_earning": false,
        "margin": '-',
        "price_change": '-',
        "quotient": '-',
        "sales": '-',
        "sales_change": '-',
        "sales_pct": '-',
        "selling_price": '-',
        "supply_price": '-',
        "has_subdivisions": false,
        "has_subsubdivisions": false,
        "market": "simulation",
        "showExpand": false
      };

      // Inserta en la posición especificada
      this.rowsCurrent.splice(position, 0, newRowCurrent);
      this.rowsRecord.splice(position, 0, newRowRecord);

      this.rowsLength = (73 / this.rowsCurrent.length);
      this.reqst = 1;
    } else {
      const index = this.rowsCurrent.findIndex(item => item.idx === 5);
      if (index !== -1) {
        this.rowsCurrent.splice(index, 1);
        this.rowsRecord.splice(index, 1);
      }
      this.rowsLength = (73 / this.rowsCurrent.length);
      this.reqst = 1;
    }
  }

  simulationChange(price: number, type: string) {
    this.reqst = 0;
    this.gasprecioService.showLoader('Generando Simulación', 'Los datos tardan un poco en generarse, aguarda un momento...');

    setTimeout(() => {
      this.gasprecioService.getTableSalesQuartilSimulation(this.productType, this.variableSelected, this.daysAgo, this.showSubdivision, this.showSubsubdivision, price, type).subscribe((data: any) => {
        if (data['simulation']) {
          const simulation = data['simulation'];
          const updatedRows = this.rowsCurrent.map((element: any) => {
            if (element['idx'] === 5) {
              return {
                ...element,
                "selling_price": simulation['selling_price'],
                "diff_price_competitors": simulation['diff_price_competitors'],
                "earning": simulation['earning'],
                "margin": simulation['margin'],
                "price_change": simulation['price_change'],
                "quotient": simulation['quotient'],
                "sales7": simulation['sales7'],
                "supply_price": simulation['supply_price'],
              };
            }
            return element;
          });

          this.rowsCurrent = updatedRows;
          this.reqst = 1;

          Swal.close();
        }
      });
    }, 300);
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;
  }
}
