import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-dif-max-price',
  templateUrl: './table-dif-max-price.component.html',
  styleUrls: ['./table-dif-max-price.component.scss']
})
export class TableDifMaxPriceComponent implements OnInit, OnDestroy {

  daysAgo = 180
  daysAgo$!: Subscription;
  market = '';
  productType = 'regular';
  productType$!: Subscription;
  rowsCurrent: any;
  rowsRecord: any;
  rowsLength: number;
  reqst = 0;
  variableSelected = 'sales';
  variableSelected$!: Subscription;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getDiffPxMax();
        }
      }
    });

    this.variableSelected$ = this.sharedService.sharedVariablePxOpt.subscribe(variableSelected => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
        this.getDiffPxMax();
      }
    });


    this.daysAgo$ = this.sharedService.sharedDaysOptimumPx.subscribe(daysAgo => {
      if (this.daysAgo !== daysAgo) {
        this.daysAgo = daysAgo;
        this.getDiffPxMax();
      }
    });
  }

  ngOnInit(): void {
    this.getDiffPxMax();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.variableSelected$.unsubscribe();
    this.daysAgo$.unsubscribe();
  }

  getDiffPxMax() {
    this.reqst = 0;
    this.gasprecioService.getDiffPxMax(this.productType, this.variableSelected, this.daysAgo).subscribe((data: any) => {
      if (data['record_data_180']) {
        this.rowsLength = (73 / data['record_data_180'].length);
        data['record_data_180'].map((element: any, index: number) => {
          if (element['flag_actual'] !== undefined) {
            this.market = element['flag_actual'] === 0 ? 'negative' : element['flag_actual'] === 1 ? 'low' : element['flag_actual'] === 2 ? 'medium_low' : element['flag_actual'] === 3 ? 'medium_high' : 'high';
          }
          element['n_obs'] = element['n_obs'] !== null ? element['n_obs'] : '-';
          element['dif_p_prom'] = element['dif_p_prom'] !== null ? element['dif_p_prom'] : '-';
          element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
          element['sales'] = element['sales'] !== null ? element['sales'] : '-';
          element['earning'] = element['earning'] !== null ? element['earning'] : '-';
          element['sales_change'] = element['sales_change'] !== null ? element['sales_change'] : '-';
          element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
          element['margin'] = element['margin'] !== null ? element['margin'] : '-';
          element['sales_pct'] = element['sales_pct'] !== null ? element['sales_pct'] * 100 : '-';
        });

        data['current_data'].map((element: any, index: number) => {
          if (element['flag_actual'] !== undefined) {
            this.market = element['flag_actual'] === 0 ? 'negative' : element['flag_actual'] === 1 ? 'low' : element['flag_actual'] === 2 ? 'medium_low' : element['flag_actual'] === 3 ? 'medium_high' : 'high';
          }
          element['dif_p_prom'] = element['dif_p_prom'] !== null ? element['dif_p_prom'] : '-';
          element['price_change'] = element['price_change'] !== null ? element['price_change'] : '-';
          element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
          element['sales'] = element['sales'] !== null ? element['sales'] : '-';
          element['earning'] = element['earning'] !== null ? element['earning'] : '-';
          element['supply_price'] = element['supply_price'] !== null ? element['supply_price'] : '-';
          element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
          element['margin'] = element['margin'] !== null ? element['margin'] : '-';
        });

        this.rowsCurrent = data['current_data'];
        this.rowsRecord = data['record_data_180'];
      }
      this.reqst = 1;
    });
  }

}


