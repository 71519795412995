import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variables-price-index',
  templateUrl: './select-variables-price-index.component.html',
  styleUrls: ['./select-variables-price-index.component.scss']
})
export class SelectVariablesPriceIndexComponent implements  OnDestroy {

  public items: any[] = [
    { name: 'Indice de Precios', value: 'quotient' },
    { name: 'Dif de Precios', value: 'diff_selling_price' },
    { name: 'Precio de Venta', value: 'selling_price' },
    { name: 'Vol de Venta', value: 'volume' }
    // { name: 'Zona Mercado', value: 'idx_zone' }
  ];

  public variableSelected: any = [];
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariablePriceIndex.subscribe((variableSelected: string) => {
      this.variableSelected = variableSelected;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariablePriceIndex = this.variableSelected;
  }

}
