import * as moment from 'moment-timezone';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-sales-chart',
  templateUrl: './sales-chart.component.html',
  styleUrls: ['./sales-chart.component.scss']
})
export class SalesChartComponent implements OnInit, OnDestroy {

  @ViewChild('editModal') editModal: TemplateRef<any>;
  @Input() section: any = false;
  adjusted;
  changeModal$!: Subscription;
  changeModal;
  forecast;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  sales: any;
  stationId$!: Subscription;
  stationId: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: 'Ventas - Venta Tendencia - Venta Pronosticada',
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        format: '{value:,.0f} lts.'
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: 'Ventas - Venta Tendencia - Venta Pronosticada',
      sourceWidth: 1200,
      sourceHeight: 800
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -16
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getChartSales();
      }
    });
  }

  async ngOnInit() {
    if (this.section) {
      this.getChartSales();
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
  }

  getChartSales() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null) {
      return;
    }
    this.gasprecioService.getChartSales(this.stationId).subscribe({
      next: (data: any) => {
        this.sales = data['record'];
        this.adjusted = data['adjusted'];
        this.forecast = data['forecast'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorLight = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorGold = this.gasprecioService.getColorString(this.gasprecioService.colors[0]);

    const lineChartData: any = [
      {
        name: 'Ventas',
        data: this.sales.map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        lineWidth: 2,
        color: color,
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartNClicked(event['point']['index'])
          }
        }
      },
      {
        name: 'Venta Tendencia',
        data: this.adjusted.map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        lineWidth: 2,
        color: colorLight,
        dashStyle: 'ShortDot',
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartNClicked(event['point']['index'])
          }
        }
      },
      {
        name: 'Venta Pronosticada',
        data: this.forecast.map((v: any) => +v[this.productType]),
        lineWidth: 2,
        color: colorGold,
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts. </b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartNClicked(event['point']['index'])
          }
        }
      }
    ];

    const lineXaxisLegend = [];
    this.sales.forEach((v: any) =>
      lineXaxisLegend.push(this.gasprecioService.parseDateDM(v['date']))
    );

    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions['series'] = lineChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  chartNClicked(index: any) {
    const date = this.sales[index].date;
    const name = this.sales[index].name;
    this.changeDataTable(date, name);
    this.openModal();
  }

  changeDataTable(date, name) {
    this.sharedService.nextDate = moment(date).tz('America/Mexico_City').format('YYYY-MM-DD');
    this.sharedService.nextProductType = this.productType;
    this.sharedService.nextStationId = this.stationId;
    this.sharedService.nextNameStation = name;
    setTimeout(() => {
      this.sharedService.nextChangeModal = 'table';
    }, 100);
  }

  openModal() {
    this.modalService.open(this.editModal, { windowClass: 'tableModal' });
  }

  closeModal() {
    this.modalService.dismissAll(this.editModal);
  }

}
