import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-days-evolution-change',
  templateUrl: './select-days-evolution-change.component.html',
  styleUrls: ['./select-days-evolution-change.component.scss']
})
export class SelectDaysEvolutionChangeComponent implements OnDestroy {

  public items: any[] = [
    { value: 7, name: 'Últ. 7 días' },
    { value: 14, name: 'Últ. 14 días' },
    { value: 30, name: 'Últ. 30 días' },
    { value: 60, name: 'Últ. 60 días' },
    { value: 90, name: 'Últ. 90 días' },
    { value: 120, name: 'Últ. 120 días' },
    { value: 99, name: 'Mes Actual' },
    { value: 0, name: 'Mes Anterior' }
  ];

  public daysAgo: any = [];
  public ngxDisabled = false;
  daysAgo$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.daysAgo$ = this.sharedService.sharedDaysEvolutionChange.subscribe(daysAgo => {
      this.daysAgo = daysAgo;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.daysAgo$.unsubscribe();
  }

  changeDaysAgo(event: any) {
    this.daysAgo = event;
    this.sharedService.nextDaysEvolutionChange = this.daysAgo;
  }
}
