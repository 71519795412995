import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-statistics-budget',
  templateUrl: './select-statistics-budget.component.html',
  styleUrls: ['./select-statistics-budget.component.scss']
})
export class SelectStatisticsBudgetComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Actual', value: '0' },
    { name: 'Cierre', value: '1' }
  ];

  public statisticSelected: any = [];
  public ngxDisabled = true;
  statisticSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.statisticSelected$ = sharedService.sharedStatisticBudget.subscribe((statisticSelected: string) => {
      if (this.statisticSelected !== statisticSelected) {
        this.statisticSelected = statisticSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.statisticSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.statisticSelected = event;
    this.sharedService.nextStatisticBudget = this.statisticSelected;
  }

}