import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {

    transform(items: any, search: any, defaultFilter: boolean): any {
        if (!search) {
            return items;
        }

        if (!Array.isArray(items)) {
            return items;
        }

        if (search && Array.isArray(items)) {
            const searchKeys = Object.keys(search);

            if (defaultFilter) {
                return items.filter(item =>
                    searchKeys.reduce((x: any, keyName: any) =>
                        (x && new RegExp(search[keyName], 'gi').test(item[keyName])) || search[keyName] === '', true));
            } else {
                return items.filter(item => {
                    return searchKeys.some((keyName) => {
                        return new RegExp(search[keyName], 'gi').test(item[keyName]) || search[keyName] === '';
                    });
                });
            }
        }
    }
}
