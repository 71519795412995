<div class="container">
    <div class="row flexi" style="padding-bottom: 10px !important;">
        <h5 style="color: #179446;font-weight: bold;">
            Regla de Precio {{typeRule}} <span *ngIf="typeC !== ''">({{typeC}})</span>
        </h5>
        <p>
        </p>
    </div>
    <div class="row" *ngIf="reqst === 1">
        <table class="table htable btable no-margin no-padding rules" *ngIf="view === 'rules' && step === 'rules'">
            <thead style="display: block;">
                <tr style="width: 100%;display: table;" class="row-title">
                    <td colspan="4" style="width: 100%;" class="text-center">
                        <span style="font-size: 14px;">
                            <strong>
                                Reglas más utilizadas
                            </strong>
                        </span>
                    </td>
                </tr>
                <tr style="width: 100%;display: table;" class="row-title">

                    <td style="width: 16%;" class="text-center">
                        <span>
                            <strong>
                                Nombre
                            </strong>
                        </span>
                    </td>
                    <td style="width: 22.5%;" class="text-center">
                        <span>
                            <strong>
                                Fórmula
                            </strong>
                        </span>
                    </td>
                    <td style="width: 49%;" class="text-center">
                        <span>
                            <strong>
                                Descripción
                            </strong>
                        </span>
                    </td>
                    <td style="width: 12.5%;" class="text-center">
                        <span>
                            <strong>

                            </strong>
                        </span>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style="width: 100%;display: table;" class="row-title" *ngFor="let rule of rules; let index = index">
                    <td style="width: 16%;">
                        <span>
                            {{rule.id}} &nbsp;&nbsp; {{rule.name}}
                        </span>
                    </td>
                    <td style="width: 22.5%;">
                        <span>
                            {{rule.formula}}
                        </span>
                    </td>
                    <td style="width: 49%;">
                        <span>
                            {{rule.description}}
                        </span>
                    </td>
                    <td style="width: 12.5%;" class="text-center">
                        <button type="button" [ngClass]="rule.selected === true ? 'selected' : 'add'"
                            (click)="selectRule(rule._id, rule.id, rule.station_price_rule_associated)">
                            <span> {{ rule.selected === true ? 'Seleccionada': 'Seleccionar' }}</span>
                        </button>
                    </td>
                </tr>

                <tr style="width: 100%;display: table;border-bottom: none;" class="row-title">
                    <td style="width: 15%;border-top: 1px solid white;" class="text-center"></td>
                    <td style="width: 12.5%;border-top: 1px solid white;"></td>
                    <td style="width: 22.5%; color: white !important;border-top: 1px solid white;">
                        <span>
                            .
                        </span>
                    </td>
                    <td style="width: 50%;border-top: 1px solid white;">
                    </td>
                </tr>

                <tr style="width: 100%;display: table;border-bottom: none;border-top: none !important;"
                    class="row-title">
                    <td style="width: 85%;border-top: 1px solid white;">
                        <button class="addRule" (click)="changeView('new')" *ngIf="view === 'rules'">
                            <i class="fa-regular fa-plus-circle icon" aria-hidden="true"></i>
                            <span>
                                Crear nueva regla
                            </span>
                        </button>
                    </td>
                    <td style="width: 15%;border-top: 1px solid white;" class="text-right">
                        <button type="button" [ngClass]="ruleSelected === 0 ? 'deactive' : 'save'"
                            [disabled]="ruleSelected === 0"
                            *ngIf="(ruleSelected === 0 || ruleSelected === 1) && !secondary" (click)="registerRule()"
                            data-dismiss="modal">
                            {{ hasRule ? 'Actualizar': 'Guardar' }}
                        </button>
                        <button type="button" [ngClass]="ruleSelected === 0 ? 'deactive' : 'save'"
                            [disabled]="ruleSelected === 0"
                            *ngIf="(ruleSelected === 0 || ruleSelected === 1) && secondary"
                            (click)="registerRuleSecondary()" data-dismiss="modal">
                            {{ hasRule ? 'Actualizar': 'Guardar' }}
                        </button>
                        <button type="button" class="save"
                            *ngIf="(ruleSelected >= 12 && productType !== 'premium') || ruleSelected > 12 "
                            (click)="setActiveRule()" data-dismiss="modal">
                            {{ hasRule ? 'Actualizar': 'Guardar' }}
                        </button>
                        <button type="button" class="save" *ngIf="ruleSelected === 2 || ruleSelected === 3 || ruleSelected === 4 || ruleSelected === 5 || ruleSelected === 6 || ruleSelected === 10" (click)="continue('stations', ruleSelected)">
                            Continuar
                        </button>
                        <button type="button" class="save" *ngIf="ruleSelected === 7 || ruleSelected === 8  || ruleSelected === 9" (click)="continue('operators', ruleSelected)">
                            Continuar
                        </button>
                        <button type="button" class="save" *ngIf="ruleSelected === 11" (click)="continue('arithmetic', ruleSelected)">
                            Continuar
                        </button>
                        
                        <button type="button" class="save" *ngIf="ruleSelected === 12 && productType === 'premium'" (click)="continue('reg-prem', ruleSelected)">
                            Continuar
                        </button>
                        <button type="button" class="save" *ngIf="ruleSelected !== 0 && ruleSelected !== 1 && ruleSelected !== 2 && ruleSelected !== 3 && ruleSelected !== 4 && ruleSelected !== 5 && ruleSelected !== 6 && ruleSelected !== 7 && ruleSelected !== 8 && ruleSelected !== 9 && ruleSelected !== 10 && ruleSelected !== 11 && (ruleSelected !== 12 && productType === 'premium') && addRuleA" (click)="changeView('new')">
                            Editar
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>

        <form [formGroup]="ruleForm" novalidate style="display: inline-block !important;width: 100%"
            *ngIf="view === 'new'">
            <div class="row justify-content-center">
                <div class="col-12" style="padding: 0;">
                    <section class="container left-line mb-2 p-3"
                        style="border: 1px solid gray;padding-bottom: 0 !important;">
                        <div class="row" style="padding-bottom: 0;">
                            <div class="col-6" style="padding: 0;">
                                <h5>Condición 1</h5>
                            </div>
                            <div class="col-6 text-right">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6" style="padding: 0 8px 0 0;">
                                <label>Nombre de la regla</label>
                                <input type="text" class="form-control" formControlName="ruleName" />
                            </div>
                            <div class="col-6" style="padding: 0;">
                                <label>Descripción de la regla</label>
                                <input type="text" class="form-control" formControlName="ruleDescription" />
                            </div>
                        </div>
                        <div class="row" style="padding-bottom: 0 !important;">
                            <div class="col-3" style="padding: 0 8px 0 0;">
                                <label>Mi precio debe ser = al </label>
                                <select class="form-control" formControlName="newRule">
                                    <option value="5f91cd85c69fb65707d05320">Precio Óptimo</option>
                                    <option value="62c71c0e6958593f71527a96">Precio Estación</option>
                                    <option value="5f91ce438257b357a7e78351">Precio Mínimo</option>
                                    <option value="5f91cec38257b357a7e78353">Precio Máximo</option>
                                    <option value="5f91d0741adb6159abb81a02">Precio Estación Mín.</option>
                                    <option value="5f91d1431adb6159abb81a05">Precio Estación Prom.</option>
                                    <option value="5fcfddd74dfe332667b0236f">Margen Mínimo</option>
                                    <option value="5ff67ce8d33a1863fb16a6ed">Margen Máximo</option>
                                    <option value="64120a06b3577f98cbeb8676">Precio Diferencial Competencia</option>
                                    <option value="5fa6dfac0215d0e7515991ab">Nivel de Precio</option>
                                    <option value="66a11d079524f85624fa03c4">Precio TAR</option>
                                </select>
                            </div>
                            <div class="col-3" style="padding: 0 8px 0 0;"
                                *ngIf="ruleForm.get('newRule')?.value !== '5fa6dfac0215d0e7515991ab' && ruleForm.get('newRule')?.value !== '5f91cd85c69fb65707d05320' && ruleForm.get('newRule')?.value !== '5fcfddd74dfe332667b0236f' && ruleForm.get('newRule')?.value !== '5ff67ce8d33a1863fb16a6ed' && ruleForm.get('newRule')?.value !== '66a11d079524f85624fa03c4'">
                                <label>de</label>
                                <select class="form-control" formControlName="competitors"
                                    placeholder="Todas las estaciones">
                                    <option value="all"
                                        *ngIf="ruleForm.get('newRule')?.value !== '62c71c0e6958593f71527a96'">
                                        Todas las estaciones</option>
                                    <option value="some"
                                        *ngIf="ruleForm.get('newRule')?.value !== '62c71c0e6958593f71527a96'">
                                        Algunas estaciones</option>
                                    <option value="some"
                                        *ngIf="ruleForm.get('newRule')?.value === '62c71c0e6958593f71527a96'">
                                        Un Competidor</option>
                                </select>
                            </div>

                            <div class="col-2" style="padding: 0 8px 0 0;"
                                *ngIf="ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f'">
                                <label>operación</label>
                                <select class="form-control" formControlName="operation" placeholder=">">
                                    <option value="major_equal"> >= </option>
                                    <option value="major"> > </option>
                                </select>
                            </div>

                            <div class="col-2" style="padding: 0 8px 0 0;"
                                *ngIf="ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed'">
                                <label>operación</label>
                                <select class="form-control" formControlName="operation" placeholder="<">
                                    <option value="minor_equal">
                                        <= </option>
                                    <option value="minor">
                                        < </option>
                                </select>
                            </div>

                            <div class="col-2" style="padding: 0 8px 0 0;"
                                *ngIf="ruleForm.get('newRule')?.value === '5fa6dfac0215d0e7515991ab'">
                                <label>operador</label>
                                <select class="form-control" formControlName="operation" placeholder=">">
                                    <option value="major"> > </option>
                                    <option value="major_equal"> >= </option>
                                    <option value="equal"> = </option>
                                    <option value="minor_equal">
                                        <= </option>
                                    <option value="minor">
                                        < </option>
                                </select>
                            </div>

                            <div class="col-2" style="padding: 0 8px 0 0;" *ngIf="ruleForm.get('newRule')?.value === '66a11d079524f85624fa03c4'">
                                <label>operación</label>
                                <select class="form-control" formControlName="operation" placeholder="+">
                                    <option value="add"> + </option>
                                </select>
                            </div>

                            <div class="col-2" style="padding: 0 8px 0 0;"
                                *ngIf="ruleForm.get('newRule')?.value !== '5fa6dfac0215d0e7515991ab' && ruleForm.get('newRule')?.value !== '5fcfddd74dfe332667b0236f' && ruleForm.get('newRule')?.value !== '5ff67ce8d33a1863fb16a6ed' && ruleForm.get('newRule')?.value !== '66a11d079524f85624fa03c4'">
                                <label>operación</label>
                                <select class="form-control" formControlName="operation" placeholder=">">
                                    <option value="add"> + </option>
                                    <option value="subtract"> - </option>
                                    <option value="major"> > </option>
                                    <option value="major_equal"> >= </option>
                                    <option value="equal"
                                        *ngIf="ruleForm.get('newRule')?.value !== '62c71c0e6958593f71527a96'">
                                        =
                                    </option>
                                    <option value="minor_equal">
                                        <= </option>
                                    <option value="minor">
                                        < </option>
                                    <option value="without"> N/A </option>
                                </select>
                            </div>

                            <div class="col-2" style="padding: 0 8px 0 0;"
                                *ngIf="ruleForm.get('operation')?.value !== 'without'">
                                <label>esta cantidad</label>
                                <input type="number" step=".01" class="form-control" formControlName="quantity" />
                            </div>
                            <div class="col-2" style="padding: 0;"
                                *ngIf="ruleForm.get('operation')?.value !== 'without'">
                                <label>unidad</label>
                                <select class="form-control" formControlName="unit">
                                    <option value="price">$</option>
                                    <option value="percent"
                                        *ngIf="(ruleForm.get('operation')?.value === 'add' || ruleForm.get('operation')?.value === 'subtract' || ruleForm.get('newRule')?.value === '5fcfddd74dfe332667b0236f' || ruleForm.get('newRule')?.value === '5ff67ce8d33a1863fb16a6ed') && ruleForm.get('newRule')?.value !== '66a11d079524f85624fa03c4'">
                                        %</option>
                                </select>
                            </div>
                            <!-- <div style="padding: 0 8px 0 0;margin-left: 25%;width: auto;margin-top: 12px;"
                                *ngIf="ruleForm.get('competitors')?.value === 'some' && ruleForm.get('newRule')?.value !== '62c71c0e6958593f71527a96'">
                                <select class="form-control" formControlName="listCompetitors" multiple>
                                    <option *ngFor="let station of stationOptions" [ngValue]="station.value" >
                                        {{station.name}} - {{formatDistance(station.distance)}} (Precio: {{station.price}})</option>
                                </select>
                            </div> 

                            <div style="padding: 0 8px 0 0;margin-left: 25%;width: auto;margin-top: 12px;"
                                *ngIf="ruleForm.get('competitors')?.value === 'some' && (ruleForm.get('newRule')?.value === '62c71c0e6958593f71527a96')">
                                <select class="form-control" formControlName="listCompetitors">
                                    <option *ngFor="let station of stationOptions" [ngValue]="station.value">
                                        {{station.name}} - {{formatDistance(station.distance)}} (Precio: {{station.price}}) </option>
                                </select>
                            </div>-->
                        </div>
                        <div class="row" style="padding-bottom: 10px !important;padding-top: 10px !important;">
                            <div class="col-3" style="padding: 0 8px 0 0;">

                            </div>

                            <div class="col-3" style="padding: 0 8px 0 0;">
                                <div
                                    *ngIf="ruleForm.get('competitors')?.value === 'some' && ruleForm.get('newRule')?.value !== '62c71c0e6958593f71527a96'">
                                    <ng-multiselect-dropdown class="rule-multi"
                                        [placeholder]="'Seleccionar Competidor(es)'" [settings]="dropdownSettings"
                                        [data]="dropdownList" appendTo="body" formControlName="listCompetitors"
                                        (onSelect)="onSelect($event)" (onDeSelect)="onDeSelect($event)"
                                        (onSelectAll)="allItems($event)" (onDeSelectAll)="anyItems()"
                                        dropdownClass="rule-multi">
                                    </ng-multiselect-dropdown>
                                </div>


                                <ng-multiselect-dropdown class="rule-single"
                                    *ngIf="ruleForm.get('competitors')?.value === 'some' && ruleForm.get('newRule')?.value === '62c71c0e6958593f71527a96'"
                                    [placeholder]="'Seleccionar Competidor'" [settings]="dropdownSingleSettings"
                                    [data]="dropdownList" appendTo="body" formControlName="listCompetitors"
                                    (onSelect)="onSelectSingle($event)" dropdownClass="rule-multi">
                                </ng-multiselect-dropdown>
                            </div>

                        </div>
                    </section>

                    <section class="container left-line-calc mb-2 p-3"
                        style="border: 1px solid gray;padding-bottom: 2px !important;" formArrayName="addresses"
                        *ngFor="let address of rulesControls; let i = index;">
                        <div [formGroupName]="i">
                            <div class="row" style="padding-bottom: 0;">
                                <div class="col-6" style="padding: 0;">
                                    <h5>{{ typeC === 'AND' ? 'Condición': 'Definición' }} {{i + 2}} </h5>
                                </div>
                                <div class="col-6 text-right">
                                    <button type="button" class="remove" (click)="removeRule(i)">
                                        <i class="fa-regular fa-minus" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row" style="padding-bottom: 0 !important;">
                                <div class="col-3" style="padding: 0 8px 0 0;">
                                    <div class="form-group">
                                        <label>Precio = al</label>
                                        <select class="form-control" formControlName="idRule"
                                            placeholder="Precio Mínimo">
                                            <option value="5f91cd85c69fb65707d05320">Precio Óptimo</option>
                                            <option value="62c71c0e6958593f71527a96">Precio Estación</option>
                                            <option value="5f91ce438257b357a7e78351">Precio Mínimo</option>
                                            <option value="5f91cec38257b357a7e78353">Precio Máximo</option>
                                            <option value="5f91d0741adb6159abb81a02">Precio Estación Mín.</option>
                                            <option value="5f91d1431adb6159abb81a05">Precio Estación Prom.</option>
                                            <option value="5fcfddd74dfe332667b0236f">Margen Mínimo</option>
                                            <option value="5ff67ce8d33a1863fb16a6ed">Margen Máximo</option>
                                            <option value="64120a06b3577f98cbeb8676">Precio Diferencial Competencia</option>
                                            <option value="5fa6dfac0215d0e7515991ab">Nivel de Precio</option>
                                            <option value="66a11d079524f85624fa03c4">Precio TAR</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-3" style="padding: 0 8px 0 0;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].idRule !== '5fa6dfac0215d0e7515991ab' && ruleForm.get('addresses')?.value[i].idRule !== '5f91cd85c69fb65707d05320' && ruleForm.get('addresses')?.value[i].idRule !== '5fcfddd74dfe332667b0236f' && ruleForm.get('addresses')?.value[i].idRule !== '5ff67ce8d33a1863fb16a6ed' && ruleForm.get('addresses')?.value[i].idRule !== '66a11d079524f85624fa03c4'">
                                    <div class="form-group">
                                        <label>de</label>
                                        <select class="form-control" formControlName="competitors"
                                            placeholder="Todas las estaciones">
                                            <option value="all"
                                                *ngIf="ruleForm.get('addresses')?.value[i].idRule !== '62c71c0e6958593f71527a96'">
                                                Todas las estaciones</option>
                                            <option value="some"
                                                *ngIf="ruleForm.get('addresses')?.value[i].idRule !== '62c71c0e6958593f71527a96'">
                                                Algunas estaciones</option>
                                            <option value="some"
                                                *ngIf="ruleForm.get('addresses')?.value[i].idRule === '62c71c0e6958593f71527a96'">
                                                Un Competidor</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-1" style="padding: 0 8px 0 0;margin-top: 30px;text-align: center;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].idRule === '5fcfddd74dfe332667b0236f' || ruleForm.get('addresses')?.value[i].idRule === '5ff67ce8d33a1863fb16a6ed'">
                                    <div class="form-group">
                                        <span
                                            style="color: #626262 !important;font-size: 14px !important;font-weight: bold !important;">
                                            Margen
                                        </span>
                                    </div>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].idRule === '5fcfddd74dfe332667b0236f'">
                                    <label>operación</label>
                                    <select class="form-control" formControlName="operator" placeholder=">=">
                                        <option value="major_equal"> >= </option>
                                        <option value="major"> > </option>
                                    </select>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].idRule === '5ff67ce8d33a1863fb16a6ed'">
                                    <label>operación</label>
                                    <select class="form-control" formControlName="operator" placeholder="<=">
                                        <option value="minor_equal">
                                            <= </option>
                                        <option value="minor">
                                            < </option>

                                    </select>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].idRule === '5fa6dfac0215d0e7515991ab'">
                                    <div class="form-group">
                                        <label>operador</label>
                                        <select class="form-control" formControlName="operator" placeholder=">">
                                            <option value="major"> > </option>
                                            <option value="major_equal"> >= </option>
                                            <option value="equal"> = </option>
                                            <option value="minor_equal">
                                                <= </option>
                                            <option value="minor">
                                                < </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;" *ngIf="ruleForm.get('addresses')?.value[i].idRule === '66a11d079524f85624fa03c4'">
                                    <label>operación</label>
                                    <select class="form-control" formControlName="operator" placeholder="+">
                                        <option value="add"> + </option>
                                    </select>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].idRule !== '5fa6dfac0215d0e7515991ab' && ruleForm.get('addresses')?.value[i].idRule !== '5fcfddd74dfe332667b0236f' && ruleForm.get('addresses')?.value[i].idRule !== '5ff67ce8d33a1863fb16a6ed' && ruleForm.get('addresses')?.value[i].idRule !== '66a11d079524f85624fa03c4'">
                                    <div class="form-group">
                                        <label>operador</label>
                                        <select class="form-control" formControlName="operator" placeholder="+">
                                            <option value="add"> + </option>
                                            <option value="subtract"> - </option>
                                            <option value="major"> > </option>
                                            <option value="major_equal"> >= </option>
                                            <option value="equal"
                                                *ngIf="ruleForm.get('addresses')?.value[i].idRule !== '62c71c0e6958593f71527a96'">
                                                = </option>
                                            <option value="minor_equal">
                                                <= </option>
                                            <option value="minor">
                                                < </option>
                                            <option value="without"> N/A </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].operator !== 'without'">
                                    <label>a esta cantidad</label>
                                    <input type="number" step=".01" class="form-control" formControlName="quantity" />
                                </div>
                                <div class="col-2" style="padding: 0;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].operator !== 'without'">
                                    <label>unidad</label>
                                    <select class="form-control" formControlName="unit">
                                        <option value="price">$</option>
                                        <option value="percent" *ngIf="(ruleForm.get('addresses')?.value[i].operator === 'add' || ruleForm.get('addresses')?.value[i].operator === 'subtract') && ruleForm.get('addresses')?.value[i].idRule !== '66a11d079524f85624fa03c4'">
                                            %
                                        </option>
                                    </select>
                                </div>
                                <!-- <div style="padding: 0 8px 0 0;margin-left: 25%;width: auto;margin-bottom: 12px;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].competitors === 'some' && ruleForm.get('addresses')?.value[i].idRule !== '62c71c0e6958593f71527a96' && ruleForm.get('addresses')?.value[i].idRule !== '5ff67ce8d33a1863fb16a6ed' && ruleForm.get('addresses')?.value[i].idRule !== '5fcfddd74dfe332667b0236f' && ruleForm.get('addresses')?.value[i].idRule !== '5fa6dfac0215d0e7515991ab' && ruleForm.get('addresses')?.value[i].idRule !== '5f91cd85c69fb65707d05320'">
                                    <select class="form-control" id="operator-groups" formControlName="listCompetitors"
                                        multiple>
                                        <option *ngFor="let station of stationOptions" [ngValue]="station.value">
                                            {{station.name}} - {{formatDistance(station.distance)}} (Precio:
                                            {{station.price}}) </option>
                                    </select>
                                </div>

                                <div style="padding: 0 8px 0 0;margin-left: 25%;width: auto;margin-bottom: 12px;"
                                    *ngIf="ruleForm.get('addresses')?.value[i].competitors === 'some' && (ruleForm.get('addresses')?.value[i].idRule === '62c71c0e6958593f71527a96')">
                                    <select class="form-control" id="operator-groups" formControlName="listCompetitors"
                                        placeholder="Selecciona un Competidor">
                                        <option *ngFor="let station of stationOptions" [ngValue]="station.value">
                                            {{station.name}} - {{formatDistance(station.distance)}} (Precio:
                                            {{station.price}}) </option>
                                    </select>
                                </div> -->
                            </div>
                            <div class="row" style="padding-bottom: 10px !important;padding-top: 10px !important;">
                                <div class="col-3" style="padding: 0 8px 0 0;">

                                </div>

                                <div class="col-3" style="padding: 0 8px 0 0;">
                                    <ng-multiselect-dropdown class="rule-multi"
                                        *ngIf="ruleForm.get('addresses')?.value[i].competitors === 'some' && ruleForm.get('addresses')?.value[i].idRule !== '62c71c0e6958593f71527a96' && ruleForm.get('addresses')?.value[i].idRule !== '5ff67ce8d33a1863fb16a6ed' && ruleForm.get('addresses')?.value[i].idRule !== '5fcfddd74dfe332667b0236f' && ruleForm.get('addresses')?.value[i].idRule !== '5fa6dfac0215d0e7515991ab' && ruleForm.get('addresses')?.value[i].idRule !== '5f91cd85c69fb65707d05320' && ruleForm.get('addresses')?.value[i].idRule !== '66a11d079524f85624fa03c4'"
                                        id="operator-groups" [placeholder]="'Seleccionar Competidor(es)'"
                                        [settings]="dropdownSettings" [data]="dropdownList" appendTo="body"
                                        formControlName="listCompetitors" [(ngModel)]="selectedItems"
                                        (onSelect)="onSelect($event)" (onDeSelect)="onDeSelect($event)"
                                        (onSelectAll)="allItems($event)" (onDeSelectAll)="anyItems()"
                                        dropdownClass="rule-multi">
                                    </ng-multiselect-dropdown>

                                    <ng-multiselect-dropdown class="rule-single"
                                        *ngIf="ruleForm.get('addresses')?.value[i].competitors === 'some' && (ruleForm.get('addresses')?.value[i].idRule === '62c71c0e6958593f71527a96')"
                                        id="operator-groups" [placeholder]="'Seleccionar Competidor'"
                                        [settings]="dropdownSingleSettings" [data]="dropdownList" appendTo="body"
                                        formControlName="listCompetitors" [(ngModel)]="selectedItems"
                                        (onSelect)="onSelectSingle($event)" dropdownClass="rule-multi">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="row" style="padding-top: 10px;">
                        <div class="col-6" style="padding: 0 8px 0 0;">
                            <button type="button" class="addRule" (click)="addRule('AND')"
                                *ngIf="typeC === '' || typeC === 'AND'">
                                <i class="fa-regular fa-plus-circle" style="vertical-align: middle;" aria-hidden="true"></i>
                                <span style="vertical-align: middle;"> Añadir regla AND</span>
                            </button>
                            &nbsp;
                            <button type="button" class="addRule" (click)="addRule('OR')"
                                *ngIf="typeC === '' || typeC === 'OR'">
                                <i class="fa-regular fa-plus-circle" style="vertical-align: middle;" aria-hidden="true"></i>
                                <span style="vertical-align: middle;"> Añadir regla OR </span>
                            </button>

                            <epy-tooltip placement="top" style="display: inline-block;margin-left: 10px;">
                                <span class="difference" slot="trigger">
                                    <input type="checkbox" id="checkbox-r" *ngIf="productType !== 'regular' && !hasRule"
                                        [(ngModel)]="regularSelected" [ngModelOptions]="{standalone: true}"> <label
                                        style="font-size: 14px;color: #159546; font-weight: bold;" for="checkbox-r"
                                        *ngIf="productType !== 'regular' && !hasRule">Regular</label>
                                </span>
                                Al seleccionar este checkbox la regla creada se agregará al producto Regular
                            </epy-tooltip>

                            <epy-tooltip placement="top" style="display: inline-block;margin-left: 10px;">
                                <span class="difference" slot="trigger">
                                    <input type="checkbox" id="checkbox-p" *ngIf="productType !== 'premium' && !hasRule"
                                        [(ngModel)]="premiumSelected" [ngModelOptions]="{standalone: true}"> <label
                                        style="font-size: 14px;color: #ea0000; font-weight: bold;" for="checkbox-p"
                                        *ngIf="productType !== 'premium' && !hasRule">Premium</label>
                                </span>
                                Al seleccionar este checkbox la regla creada se agregará al producto Diesel
                            </epy-tooltip>

                            <epy-tooltip placement="top" style="display: inline-block;margin-left: 10px;">
                                <span class="difference" slot="trigger">
                                    <input type="checkbox" id="checkbox-d" *ngIf="productType !== 'diesel' && !hasRule"
                                        [(ngModel)]="dieselSelected" [ngModelOptions]="{standalone: true}"> <label
                                        style="font-size: 14px;color: #1e2228; font-weight: bold;" for="checkbox-d"
                                        *ngIf="productType !== 'diesel' && !hasRule">Diesel</label>
                                </span>
                                Al seleccionar este checkbox la regla creada se agregará al producto Diesel
                            </epy-tooltip>
                        </div>
                        <div class="col-6 text-right" style="padding: 0" *ngIf="!secondary">
                            <button type="button" class="cancel" (click)="cancel()"
                                *ngIf="status !== 'edit'">Cancelar</button>
                            <button type="button" [ngClass]="ruleForm.invalid ? 'deactive' : 'save'"
                                [disabled]="ruleForm.invalid" (click)="registerNewRuleComponent()"
                                data-dismiss="modal">{{ hasRule ? 'Actualizar': 'Guardar' }}</button>
                        </div>

                        <div class="col-6 text-right" style="padding: 0" *ngIf="secondary">
                            <button type="button" class="cancel" (click)="cancel()"
                                *ngIf="status !== 'edit'">Cancelar</button>
                            <button type="button" [ngClass]="ruleForm.invalid ? 'deactive' : 'save'"
                                [disabled]="ruleForm.invalid" (click)="registerNewRuleSecondaryComponent()"
                                data-dismiss="modal">{{ hasRule ? 'Actualizar': 'Guardar' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <table class="table htable btable no-margin no-padding price"
            *ngIf="step === 'stations' || condition === 'select'">
            <thead>
                <tr>
                    <td style="width: 15%;border-top: 1px solid white;" class="text-center">
                        <span>
                            <strong>
                            </strong>
                        </span>
                    </td>
                    <td style="width: 70%;" colspan="5" class="text-center">
                        <span *ngIf="(step === 'stations' || condition === 'select') && ruleSelected !== 2">
                            <strong>
                                Selecciona un conjunto de estaciones (Mín. 1)
                            </strong>
                        </span>

                        <span *ngIf="step === 'stations' && (ruleSelected === 2)">
                            <strong>
                                Selecciona un Competidor
                            </strong>
                        </span>
                    </td>
                    <td style="width: 15%;border-top: 1px solid white;" class="text-center">
                        <span>
                            <strong>
                            </strong>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td style="width: 15%;border-top: 1px solid white;" class="text-center">
                        <span>
                            <strong>
                            </strong>
                        </span>
                    </td>
                    <td style="width: 10%;" class="text-center">
                        <epy-tooltip placement="top" *ngIf="ruleSelected !== 2">
                            <span class="difference" slot="trigger">
                                <input type="checkbox" id="selection" (click)="selectStations(stationsSelected)">
                                <label for="selection"></label>
                            </span>
                            {{ stationsSelected === true ? 'Marcar todas las estaciones': 'Desmarcar todas las estaciones' }}
                        </epy-tooltip>
                    </td>
                    <td style="width: 30%;" class="text-center">
                        <span>
                            <strong>
                                Nombre
                            </strong>
                        </span>
                    </td>
                    <td style="width: 10%;" class="text-center">
                        <span class="text-capitalize">
                            <strong>
                                {{productType}}
                            </strong>
                        </span>
                    </td>
                    <td style="width: 10%;" class="text-center">
                        <span>
                            <strong>
                                Distancia
                            </strong>
                        </span>
                    </td>
                    <td style="width: 10%;" class="text-center">
                        <span>
                            <strong>
                                Marca
                            </strong>
                        </span>
                    </td>
                    <td style="width: 15%;border-top: 1px solid white;" class="text-center">
                        <span>
                            <strong>
                            </strong>
                        </span>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cop of competitors">
                    <td style="border-top: 1px solid white;"></td>
                    <td class="text-center">
                        <input type="checkbox" id="{{cop.cre_id}}" [(ngModel)]="cop.selected"
                            (change)="modelChangeFn($event)">
                        <label for="{{cop.cre_id}}"></label>
                    </td>
                    <td>{{cop.name}}</td>
                    <td class="text-center">
                        <span *ngIf="cop[productType]">
                            $ {{cop[productType] | number:'1.2-2' }}
                        </span>
                    </td>
                    <td class="text-center">{{formatDistance(cop.distance)}}</td>
                    <td class="text-center">{{cop.brand}}</td>
                    <td style="border-top: 1px solid white;border-top: 1px solid white;"></td>
                </tr>

                <tr style="border-bottom: none;border-bottom: none;border-top: none !important;">
                    <td colspan="7" style="color: white !important;border-top: 1px solid white;">.</td>
                </tr>

                <tr style="border-bottom: none;">
                    <td colspan="7" style="border-top: 1px solid white;"
                        *ngIf="view !== 'new' && (ruleSelected === 6 || ruleSelected === 5)">
                        <strong>
                            Ingresa la cantidad a restar:
                        </strong>
                        &nbsp;
                        <input class="quantityI" type="number" step=".01" [(ngModel)]="quantity">
                        <select class="unit" type="text" (change)="unitChange($event)">
                            <option value="price" [selected]="unit === 'price'"> $ </option>
                            <option value="percent" [selected]="unit === 'percent'"> % </option>
                        </select>
                    </td>
                    <td colspan="7" style="border-top: 1px solid white;" *ngIf="view !== 'new' && ruleSelected === 2">
                        <strong>
                            Selecciona la operación e ingresa la cantidad:
                        </strong>
                        <select class="unit" type="text" (change)="operatorChange($event)">
                            <option value="add" [selected]="operator === 'add'"> + </option>
                            <option value="subtract" [selected]="operator === 'subtract'"> - </option>
                            <option value="without" [selected]="operator === 'without'"> N/A </option>
                        </select>
                        <input class="quantityI" type="number" step=".01" [(ngModel)]="quantity"
                            *ngIf="operator !== 'without'">
                        <select class="unit" type="text" (change)="unitChange($event)" *ngIf="operator !== 'without'">
                            <option value="price" [selected]="unit === 'price'"> $ </option>
                            <option value="percent" [selected]="unit === 'percent'"> % </option>
                        </select>
                    </td>
                    <td colspan="7" style="border-top: 1px solid white;" *ngIf="view !== 'new' && ruleSelected === 10">
                        <div class="form-group" style="width: 70%;margin-left: 15%;display: inline-block;">
                            <label>La diferencia de precio debe de ser</label>
                            &nbsp;
                            <select class="unit" (change)="operatorChange($event)">
                                <option value="major" [selected]="operator === 'major'"> > </option>
                                <option value="major_equal" [selected]="operator === 'major_equal'"> >= </option>
                                <option value="equal" [selected]="operator === 'equal'"> = </option>
                                <option value="minor_equal" [selected]="operator === 'minor_equal'">
                                    <= </option>
                                <option value="minor" [selected]="operator === 'minor'">
                                    < </option>
                            </select>
                            &nbsp;
                            <input class="quantityI" type="number" step=".01" [(ngModel)]="quantity">
                            &nbsp;
                            <select class="unit" type="text" (change)="unitChange($event)"
                                *ngIf="operator !== 'without'">
                                <option value="price" [selected]="unit === 'price'"> $ </option>
                                <option value="percent" [selected]="unit === 'percent'"> % </option>
                            </select>
                        </div>
                    </td>
                    <td colspan="7" style="border-top: 1px solid white;"
                        *ngIf="(view !== 'new' && (ruleSelected !== 2 && ruleSelected !== 5 && ruleSelected !== 6)) || view === 'new'">
                    </td>
                </tr>

                <tr style="border-bottom: none;">
                    <td colspan="3" style="border-top: 1px solid white;">

                    </td>
                    <td colspan="4" class="text-right" style="border-top: 1px solid white;">
                        <button type="button" class="cancel" (click)="cancel()"
                            *ngIf="view === 'new' && status !== 'edit'">Cancelar</button>
                        <button type="button" class="cancel" (click)="back('rules')"
                            *ngIf="view === 'rules' && status !== 'edit'">Regresar</button>
                        <button type="button" class="save" (click)="registerRule()" data-dismiss="modal"
                            *ngIf="!secondary">
                            {{ hasRule ? 'Actualizar': 'Guardar' }}
                        </button>
                        <button type="button" class="save" (click)="registerRuleSecondary()" data-dismiss="modal"
                            *ngIf="secondary">
                            {{ hasRule ? 'Actualizar': 'Guardar' }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <table class="table htable btable no-margin no-padding" *ngIf="step === 'reg-prem'">
            <thead style="display: block;">
                <tr style="width: 100%;display: table;" class="row-title">
                    <td class="text-center">
                        <span>
                            <strong>
                                Precio Regular-Premium
                            </strong>
                        </span>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style="border-bottom: none;border-bottom: none;border-top: none !important;">
                    <td style="color: white !important;border-top: 1px solid white;">.</td>
                </tr>
                <tr style="width: 100%;display: table;border-bottom: none;border-bottom: none;border-top: none !important;"
                    class="row-title">
                    <td style="width: 100%;border-top: 1px solid white;text-align: center;">
                        <span>
                            Agregarle
                        </span>

                        <input class="quantity" style="width: 70px !important;" type="number" step=".01"
                            [(ngModel)]="quantity">
                        <select class="unit" style="width: 40px !important;" type="text" (change)="unitChange($event)">
                            <option value="price" [selected]="unit === 'price'"> $ </option>
                            <option value="percent" [selected]="unit === 'percent'"> % </option>
                        </select>

                        <span>
                            del precio Regular
                        </span>
                    </td>
                </tr>

                <tr style="border-bottom: none;border-bottom: none;border-top: none !important;">
                    <td style="color: white !important;border-top: 1px solid white;">.</td>
                </tr>

                <tr style="width: 100%;display: table;border-bottom: none;" *ngIf="condition !== 'select'">
                    <td class="text-right" style="width: 40%;border-top: 1px solid white;">
                        <button type="button" class="cancel" (click)="back('rules')">Regresar</button>
                        <button type="button" class="save" (click)="registerRule()" data-dismiss="modal"
                            *ngIf="!secondary">
                            {{ hasRule ? 'Actualizar': 'Guardar' }}
                        </button>
                        <button type="button" class="save" (click)="registerRuleSecondary()" data-dismiss="modal"
                            *ngIf="secondary">
                            {{ hasRule ? 'Actualizar': 'Guardar' }}
                        </button>
                    </td>
                </tr>
                <br>
            </tbody>
        </table>

        <table class="table htable btable no-margin no-padding" *ngIf="step === 'operators'">
            <thead style="display: block;">
                <tr style="width: 100%;display: table;" class="row-title">
                    <td class="text-center">
                        <span *ngIf="ruleSelected === 7">
                            <strong>
                                Precio Margen
                            </strong>
                        </span>
                        <span *ngIf="ruleSelected === 8">
                            <strong>
                                Margen Mínimo
                            </strong>
                        </span>
                        <span *ngIf="ruleSelected === 9">
                            <strong>
                                Margen Máximo
                            </strong>
                        </span>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style="border-bottom: none;border-bottom: none;border-top: none !important;">
                    <td style="color: white !important;border-top: 1px solid white;">.</td>
                </tr>
                <tr style="width: 100%;display: table;border-bottom: none;border-bottom: none;border-top: none !important;"
                    class="row-title">
                    <td style="width: 100%;border-top: 1px solid white;text-align: center;">
                        <span *ngIf="ruleSelected === 7">
                            Mi margen debe de ser =
                        </span>
                        <span *ngIf="ruleSelected === 8">
                            Mi margen debe de ser >=
                        </span>
                        <span *ngIf="ruleSelected === 9">
                            Mi margen debe de ser <= </span>
                                <input class="quantity" style="width: 70px !important;" type="number" step=".01"
                                    [(ngModel)]="quantity">
                                <select class="unit" style="width: 40px !important;" type="text"
                                    (change)="unitChange($event)">
                                    <option value="percent" [selected]="unit === 'percent'"> % </option>
                                    <option value="price" [selected]="unit === 'price'"> $ </option>
                                </select>
                    </td>
                </tr>

                <tr style="border-bottom: none;border-bottom: none;border-top: none !important;">
                    <td style="color: white !important;border-top: 1px solid white;">.</td>
                </tr>

                <tr style="width: 100%;display: table;border-bottom: none;" *ngIf="condition !== 'select'">
                    <td class="text-right" style="width: 40%;border-top: 1px solid white;">
                        <button type="button" class="cancel" (click)="back('rules')" *ngIf="status !== 'edit'">
                            Regresar
                        </button>
                        <button type="button" class="save" (click)="registerRule()" data-dismiss="modal"
                            *ngIf="!secondary">
                            Guardar
                        </button>
                        <button type="button" class="save" (click)="registerRuleSecondary()" data-dismiss="modal"
                            *ngIf="secondary">
                            Guardar
                        </button>
                    </td>
                </tr>
                <br>
            </tbody>
        </table>

        <table class="table htable btable no-margin no-padding" *ngIf="step === 'arithmetic'">
            <thead style="display: block;">
                <tr style="width: 100%;display: table;" class="row-title">
                    <td class="text-center">
                        <span *ngIf="ruleSelected === 11">
                            <strong>
                                Precio TAR + $X
                            </strong>
                        </span>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style="border-bottom: none;border-bottom: none;border-top: none !important;">
                    <td style="color: white !important;border-top: 1px solid white;">.</td>
                </tr>
                <tr style="width: 100%;display: table;border-bottom: none;border-bottom: none;border-top: none !important;"
                    class="row-title">
                    <td style="width: 100%;border-top: 1px solid white;text-align: center;">
                        <span>
                            Mi precio debe de ser el precio TAR
                        </span>
                        <select class="unit" (change)="operatorChange($event)">
                            <option value="add" [selected]="operator === 'add'"> + </option>
                        </select>
                        <input class="quantity" style="width: 70px !important;" type="number" step=".01"
                            [(ngModel)]="quantity">
                        <select class="unit" style="width: 40px !important;" type="text" (change)="unitChange($event)">
                            <option value="price" [selected]="unit === 'price'"> $ </option>
                        </select>
                    </td>
                </tr>

                <tr style="border-bottom: none;border-bottom: none;border-top: none !important;">
                    <td style="color: white !important;border-top: 1px solid white;">.</td>
                </tr>

                <tr style="width: 100%;display: table;border-bottom: none;" *ngIf="condition !== 'select'">
                    <td class="text-right" style="width: 40%;border-top: 1px solid white;">
                        <button type="button" class="cancel" (click)="back('rules')" *ngIf="status !== 'edit'">
                            Regresar
                        </button>
                        <button type="button" class="save" (click)="registerRule()" data-dismiss="modal"
                            *ngIf="!secondary">
                            Guardar 1
                        </button>
                        <button type="button" class="save" (click)="registerRuleSecondary()" data-dismiss="modal"
                            *ngIf="secondary">
                            Guardar S
                        </button>
                    </td>
                </tr>
                <br>
            </tbody>
        </table>

    </div>
    <div class="row" style="margin-top: 20px;" *ngIf="reqst === 0">
        <app-table-loader style="width: 100%;"></app-table-loader>
    </div>
</div>