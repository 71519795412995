import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import { ClusterType } from 'src/app/modules/gasprecio/enums/cluster-type.enum';

@Component({
  selector: 'app-chart-comparative-dashboard',
  templateUrl: './chart-comparative-dashboard.component.html',
  styleUrls: ['./chart-comparative-dashboard.component.scss']
})
export class ChartComparativeDashboardComponent implements OnInit, OnDestroy {

  currentStation: any;
  data: any;
  days = 30;
  id;
  idGroup: GroupIds | string = GroupIds.Pa;
  idStation;
  importants;
  months = 12;
  period = 'day';
  private destroy$ = new Subject<void>();
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  rol;
  selectedCluster;
  selectedCluster2;
  selectedCluster3;
  variableValue: any = 'selling_price';
  weeks = 12;
  zoneSelected = null;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Comparativo Zona`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
    },
    yAxis: {
      title: {
        text: 'Precio de Venta',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        format: '$ {value:.2f}'
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      formatter: function () {
        var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
        var currentPoint = this;
        var addedStations = [];
        this.series.chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
              tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>$ ' + point.y.toFixed(2) + '</b><br/>';
            }
          });
        });
        return tooltip;
      }
      // pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: `Comparativo Zona`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -6
      }
    },
    series: []
  };

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');

    combineLatest([
      this.sharedService.sharedProductTypeTotal,
      this.sharedService.sharedVariableDashboard,
      this.sharedService.sharedGroup,
      this.sharedService.sharedZoneDashboard,
      this.sharedService.sharedPeriodDashboard,
      this.sharedService.sharedDaysDashboard,
      this.sharedService.sharedWeeksAgo,
      this.sharedService.sharedMonthsAgo,
      this.sharedService.sharedImportants,
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster1),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster2),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster3)
    ]).pipe(takeUntil(this.destroy$)).subscribe(([
      productType,
      variableValue,
      idGroup,
      zone,
      period,
      days,
      weeks,
      months,
      importants,
      selectedCluster,
      selectedCluster2,
      selectedCluster3
    ]) => {
      if (this.productType !== productType) {
        this.productType = productType;
      }

      if (this.variableValue !== variableValue) {
        this.variableValue = variableValue;
      }

      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
      }

      const previousZone = this.zoneSelected;
      this.zoneSelected = zone === 'Total' ? null : zone;
      if (zone !== previousZone) {
        if (this.reqst > 0) {
          this.getDashboardComparative();
        }
      }

      if (this.period !== period) {
        this.period = period;
      }

      if (this.days !== days) {
        this.days = days;
      }

      if (this.weeks !== weeks) {
        this.weeks = weeks;
      }

      if (this.months !== months) {
        this.months = months;
      }

      if (this.importants !== importants) {
        this.importants = importants;
      }

      if (this.selectedCluster !== selectedCluster) {
        this.selectedCluster = selectedCluster;
      }

      if (this.selectedCluster2 !== selectedCluster2) {
        this.selectedCluster2 = selectedCluster2;
      }

      if (this.selectedCluster3 !== selectedCluster3) {
        this.selectedCluster3 = selectedCluster3;
      }

      if (this.reqst > 0) {
        this.getDashboardComparative();
      }
    });
  }

  ngOnInit(): void {
    this.getDashboardComparative();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDashboardComparative() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    setTimeout(() => {
      this.gasprecioService.getDashboardComparative(this.idGroup, this.variableValue, this.productType, this.period, this.zoneSelected, this.importants, typeValue, n, this.selectedCluster, this.selectedCluster2, this.selectedCluster3).subscribe({
        next: (data: any) => {
          if (this.period === 'month' && this.idGroup === GroupIds.Rendichicas) {
            this.data = data.slice(0, 8);
          } else {
            this.data = data;
          }
          this.setData();
        }, complete: () => {
          this.reqst = 2;
        }, error: () => {
          this.reqst = 3;
        }
      });
    }, 300);
  }

  setData() {
    this.reqst = 1;
    const seriesData = [];
    this.data.forEach((element, index) => {
      let color = `rgb(${this.gasprecioService.colors[index].r}, ${this.gasprecioService.colors[index].g}, ${this.gasprecioService.colors[index].b})`;
      let pointFormat = {};
      if (this.variableValue === 'volume') {
        this.chartOptions['yAxis']['labels']['format'] = '{value:,.0f} lts.'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts. </b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      } else if (this.variableValue === 'quotient') {
        this.chartOptions['yAxis']['labels']['format'] = '{value:.3f}'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(3) + '</b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat

      } else {
        this.chartOptions['yAxis']['labels']['format'] = '$ {value:.2f}'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>$ ' + point.y.toFixed(2) + '</b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      }
      const lineChartData = {
        name: element['zone']['zone_name'],
        data: element['values'].map((v: any) => v['value']),
        color: color,
        lineWidth: 1.5,
        marker: {
          radius: 4
        }
      };

      const lineXaxisLegendGenerator = {
        day: (v: any) => this.gasprecioService.parseDateDM(v.date),
        week: (v: any) => v.date_alias,
        month: (v: any) => this.gasprecioService.parseDateM(v.date)
      };

      const lineXaxisLegend = element['values'].map(lineXaxisLegendGenerator[this.period]);
      this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
      seriesData.push(lineChartData);
    });
    this.chartOptions.series = seriesData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas: any = document.getElementById('mychart');
    const imgData = canvas.toDataURL('image/png');
    this.sharedService.nextImgData = imgData;
  }
}
