import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-variable-second-cross-report',
  templateUrl: './select-variable-second-cross-report.component.html',
  styleUrls: ['./select-variable-second-cross-report.component.scss']
})
export class SelectVariableSecondCrossReportComponent implements OnDestroy {

  public items: any[] = [
    { value: '', variable: '', name: 'Solo Venta/PDM' },
    { value: 'selling_price', variable: 'selling_price', name: '+ Precio de Venta' },
    { value: 'index_price', variable: 'index_price', name: '+ Indice de Precio' },
    { value: 'all', variable: 'all', name: '+ Precio e Indice' },
  ];

  public variableSelected: any = [];
  public ngxDisabled = true;
  variableSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.variableSelected$ = sharedService.sharedVariableSecondCrossReport.subscribe((variableSelected: string) => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariableSecondCrossReport = this.variableSelected;
  }

}
