import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../models/user.model';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    token: any;
    idStation: any;
    creId: any;
    rol;
    showPassword = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authenticationService: AuthService
    ) {
        this.token = localStorage.getItem('token');
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();

        if (this.authenticationService.currentUserValue && this.token) {
            this.router.navigate(['/gasprecio/monitor']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .subscribe({
                next: (data: any) => {
                    console.log(data)
                    this.idStation = data.user.stations[0]._id;
                    this.creId = data.user.stations[0].cre;
                    this.token = data.user.token;
                    this.rol = data.user.rol;
                    data.authdata = window.btoa(this.f.email.value + ':' + this.f.password.value);
                    localStorage.setItem('creId', this.creId);
                    localStorage.setItem('currentUser', JSON.stringify(data));
                    localStorage.setItem('idStation', this.idStation);
                    localStorage.setItem('token', this.token);
                    localStorage.setItem('rol', this.rol);
                    this.currentUserSubject.next(data);
                    this.reloadPage();
                }, error: () => {
                    this.errorLogin();
                }
            });
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    reloadPage(): void {
        window.location.reload();
    }

    errorLogin() {
        Swal.fire({
            title: 'El usuario o la contraseña son inválidos',
            text: 'Inténtalo de nuevo',
            icon: 'error',
            confirmButtonColor: '#00e07b',
            confirmButtonText: 'Aceptar'
        });
    }

    async recoverPassword() {
        const { value: email } = await Swal.fire({
            title: 'Recuperar contraseña',
            text: 'Ingrese su cuenta de correo electrónico y le enviaremos instrucciones para recuperar su contraseña.',
            input: 'email',
            inputPlaceholder: 'Correo electrónico',
            confirmButtonText: 'Enviar',
            confirmButtonColor: '#00e07b'
        });

        if (email) {
            await this.authenticationService.sendRecoverPassword(email);
            Swal.fire({
                title: 'Correo enviado',
                text: `Te hemos enviado un correo a ${email} con las instrucciones para recuperar tu contraseña.`,
                icon: 'success',
                confirmButtonColor: '#00e07b',
                confirmButtonText: 'Aceptar'
            });
        }
    }
}
