import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-week',
  templateUrl: './select-week.component.html',
  styleUrls: ['./select-week.component.scss']
})
export class SelectWeekComponent implements OnInit, OnDestroy {

  public items: any[] = [];
  public week: any = [];
  public weekSelected;
  public ngxDisabled = true;
  week$: Subscription;
  weekSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.week$ = sharedService.sharedWeek.subscribe((week: string) => {
      if (week !== this.week) {
        this.week = week;
      }
    });

    this.weekSelected$ = this.sharedService.sharedWeekSelected.subscribe(weekSelected => {
      if (weekSelected !== this.weekSelected) {
        this.weekSelected = weekSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getWeeksSchedule();
  }

  ngOnDestroy() {
    this.week$.unsubscribe();
    this.weekSelected$.unsubscribe();
  }

  getWeeksSchedule() {
    this.gasprecioService.getWeeksSchedule().subscribe((data: any) => {
      const weeks: any = data;
      weeks.forEach((element: any) => {
        this.items.push({ name: element['name'], value: element['name'], interval: element['interval'] });
      });
      if (this.week === '' || this.weekSelected === '') {
        this.week = [this.items[1]['name']];
        this.weekSelected = this.items[1]['interval'];
        this.sharedService.nextWeekSelected = this.weekSelected;
      }
      this.ngxDisabled = false;
    });
  }

  changeWeek(event: any) {
    this.week = event;
    this.items.forEach((element: any) => {
      if (element['name'] === this.week) {
        this.weekSelected = element['interval'];
        this.sharedService.nextWeekSelected = this.weekSelected;
      }
    });
    this.sharedService.nextWeek = this.week;
  }
}
