import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-multiselect-clusters',
  templateUrl: './multiselect-clusters.component.html',
  styleUrls: ['./multiselect-clusters.component.scss']
})
export class MultiselectClustersComponent implements OnInit, OnDestroy {

  dropdownList = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Quitar grupos seleccionados',
    unSelectAllText: 'Quitar grupos seleccionados',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    enableCheckAll: false,
    searchPlaceholderText: 'Buscar Grupo...',
    maxHeight: 140,
    noDataAvailablePlaceholderText: 'No hay datos disponibles'
  };
  idGroup: any;
  idGroup$: Subscription;
  rol;
  selectedComplementClusters = null;
  selectedComplementClusters$!: Subscription;
  selectedItems = [];
  url: string;

  constructor(
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.getClusters();
      }
    });

    this.selectedComplementClusters$ = this.sharedService.sharedSelectedComplementClusters.subscribe(selectedComplementClusters => {
      if (selectedComplementClusters !== this.selectedComplementClusters) {
        this.selectedItems = [...selectedComplementClusters];
      }
    });
  }

  ngOnInit(): void {
    this.getClusters();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe(); 
    this.selectedComplementClusters$.unsubscribe(); 
  }

  getClusters() {
    const items = [];
    this.gasprecioService.getClusters(this.idGroup, 'clusters').subscribe((data: any) => {
      const cluster: any = data;
      cluster.forEach((element: any) => {
        items.push({ item_id: element['_id'], item_text: this.gasprecioService.capitalizeFirstLetter(element['name']) });
      });

      this.dropdownList = items;
    });
  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'clusters_ids[]=' + item.item_id + '&';
    });
    this.sharedService.nextComplementClusters = this.url;
    this.sharedService.nextSelectedComplementClusters = this.selectedItems;
  }

  allItems() {
    this.selectedItems = [];
    this.url = '';
    this.sharedService.nextComplementClusters = this.url;
    this.sharedService.nextSelectedComplementClusters = this.selectedItems;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = '';
    this.sharedService.nextComplementClusters = this.url;
    this.sharedService.nextSelectedComplementClusters = this.selectedItems;
  }
}
