import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-table-alerts',
  templateUrl: './table-alerts.component.html',
  styleUrls: ['./table-alerts.component.scss']
})
export class TableAlertsComponent implements  OnDestroy {

  @Input() section: any = false;
  @Input() dateType: any = false;
  alert;
  changeModal$!: Subscription;
  changeModal;
  date$!: Subscription;
  date: any = moment().tz('America/Mexico_City').format('YYYY-MM-DD');
  yesterday: any = moment().tz('America/Mexico_City').subtract(1, 'days').format('YYYY-MM-DD');
  nameStation$!: Subscription;
  nameStation;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  rows;
  stationId$!: Subscription;
  stationId: any;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.stationId = localStorage.getItem('idStation');

    this.date$ = this.sharedService.sharedDate.subscribe((date: any) => {
      if (this.date !== date) {
        switch (this.dateType) {
          case 'format':
            this.date = moment(date, 'DD/MM/YY').tz('America/Mexico_City').format('YYYY-MM-DD');
            this.yesterday = moment(date, 'DD/MM/YY').tz('America/Mexico_City').subtract(1, 'days').format('YYYY-MM-DD');
            break;
          case 'normal':
            this.date = moment(date, 'DD-MM-YY').tz('America/Mexico_City').format('YYYY-MM-DD');
            this.yesterday = moment(date, 'DD-MM-YY').tz('America/Mexico_City').subtract(1, 'days').format('YYYY-MM-DD');
            break;
          default:
            this.date = date;
            this.yesterday = moment(date).tz('America/Mexico_City').subtract(1, 'days').format('YYYY-MM-DD');
            break;
        }
      }
    });

    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.nameStation$ = this.sharedService.sharedNameStation.subscribe(nameStation => {
      this.nameStation = nameStation;
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'table') {
        this.getTableAlertVariables();
      }
    });
  }

  ngOnDestroy(): void {
    this.date$.unsubscribe();
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.nameStation$.unsubscribe();
    this.changeModal$.unsubscribe();
  }

  getTableAlertVariables() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null || this.productType === null || this.date === null || this.date === moment().tz('America/Mexico_City').format('YYYY-MM-DD')) {
      return;
    }
    if (!moment(this.date).isValid()) {
      return;
    }

    this.gasprecioService.getTableAlertVariables(this.date, this.stationId, this.productType).subscribe((data: any) => {
      this.alert = data['alert'];
      this.alert['diff_liters_pct'] = this.alert['diff_liters_pct'] != null ? this.alert['diff_liters_pct'].toFixed(0) + ' %' : '-';
      this.rows = data['variables'];
      this.rows.map((row) => {
        switch (row.var_name) {
          case 'selling_price':
            row.name = 'Precio TU estación';
            row.today_value = row.today_value != null ? '$ ' + row.today_value.toFixed(2) : '-';
            row.yesterday_value = row.yesterday_value != null ? '$ ' + row.yesterday_value.toFixed(2) : '-';
            row.diff = row.diff_value != null ? row.diff_value : '-';
            row.diff_value = row.diff_value != null ? '$ ' + row.diff_value.toFixed(2) : '-';
            break;
          case 'ppc':
            row.name = 'Precio Competencia';
            row.today_value = row.today_value != null ? '$ ' + row.today_value.toFixed(2) : '-';
            row.yesterday_value = row.yesterday_value != null ? '$ ' + row.yesterday_value.toFixed(2) : '-';
            row.diff = row.diff_value != null ? +row.diff_value.toFixed(2) : '-';
            row.diff_value = row.diff_value != null ? '$ ' + row.diff_value.toFixed(2) : '-';
            break;
          case 'quotient':
            row.name = 'Indice Precio';
            row.today_value = row.today_value != null ? row.today_value.toFixed(3) : '-';
            row.yesterday_value = row.yesterday_value != null ? row.yesterday_value.toFixed(3) : '-';
            row.diff = row.diff_value != null ? row.diff_value : '-';
            row.diff_value = row.diff_value != null ? row.diff_value.toFixed(3) : '-';
            break;
          case 'traffic':
            row.name = 'Tráfico';
            row.today_value = row.today_value != null ? row.today_value.toFixed(2) : '-';
            row.yesterday_value = row.yesterday_value != null ? row.yesterday_value.toFixed(2) : '-';
            row.diff = row.diff_value != null ? row.diff_value : '-';
            row.diff_value = row.diff_value != null ? row.diff_value.toFixed(2) : '-';
            break;
          case 'construction':
            row.name = 'Obra';
            row.today_value = row.today_value != null ? row.today_value.toFixed(0) : '-';
            row.yesterday_value = row.yesterday_value != null ? row.yesterday_value.toFixed(0) : '-';
            row.diff = row.diff_value != null ? row.diff_value : '-';
            row.diff_value = row.diff_value != null ? row.diff_value.toFixed(0) : '';
            break;
          case 'stock':
            row.name = 'Inventario';
            row.today_value = row.today_value != null ? row.today_value.toFixed(0) : '-';
            row.yesterday_value = row.yesterday_value != null ? row.yesterday_value.toFixed(0) : '-';
            row.diff_value = row.diff_value != null ? row.diff_value.toFixed(0) : '';
            break;
        }
      });
      this.reqst = 2;
    }, err => {
      console.log(err);
    });
  }

}
