import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import { ClusterType } from 'src/app/modules/gasprecio/enums/cluster-type.enum';

@Component({
  selector: 'app-chart-tar-buy',
  templateUrl: './chart-tar-buy.component.html',
  styleUrls: ['./chart-tar-buy.component.scss']
})
export class ChartTarBuyComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  date: any;
  days = 30;
  days$!: Subscription;
  event$!: Subscription;
  Highcharts: typeof Highcharts = Highcharts;
  idGroup: GroupIds | string = GroupIds.Pa;
  idGroup$!: Subscription;
  imgData2;
  importants;
  importants$: Subscription;
  isAnimationEnabled = false;
  months = 12;
  months$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 'regular';
  productType$!: Subscription;
  private destroy$ = new Subject<void>();
  purchasePrice;
  reqst = 0;
  rol;
  route;
  selectedCluster;
  selectedCluster$!: Subscription;
  selectedCluster2;
  selectedCluster2$!: Subscription;
  selectedCluster3;
  selectedCluster3$!: Subscription;
  showEnerser = false;
  showYAxis = false;
  tarPrice: any;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Precio TAR - Precio Compra`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Precio TAR',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Precio Compra',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '11px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
      pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: `Precio TAR - Precio Compra`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.showEnerser = this.idGroup === GroupIds.Enerser || this.idGroup === GroupIds.Combuservicio;
    this.isAnimationEnabled = this.showEnerser ;

    combineLatest([
      this.sharedService.sharedProductTypeTotal,
      this.sharedService.sharedGroup,
      this.sharedService.sharedZoneDashboard,
      this.sharedService.sharedPeriodDashboard,
      this.sharedService.sharedDaysDashboard,
      this.sharedService.sharedWeeksAgo,
      this.sharedService.sharedMonthsAgo,
      this.sharedService.sharedImportants,
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster1),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster2),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster3)
    ]).pipe(takeUntil(this.destroy$)).subscribe(([
      productType,
      idGroup,
      zone,
      period,
      days,
      weeks,
      months,
      importants,
      selectedCluster,
      selectedCluster2,
      selectedCluster3
    ]) => {
      if (this.productType !== productType) {
        this.productType = productType;
      }

      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.zoneSelected = '';

      }

      if (zone !== this.zoneSelected) {
        this.zoneSelected = zone === 'Total' ? null : zone;

      }

      if (period !== this.period) {
        this.period = period;

      }

      if (days !== this.days) {
        this.days = days;

      }

      if (weeks !== this.weeks) {
        this.weeks = weeks;

      }

      if (months !== this.months) {
        this.months = months;

      }

      if (importants !== this.importants) {
        this.importants = importants;

      }

      if (selectedCluster !== this.selectedCluster) {
        this.selectedCluster = selectedCluster;

      }

      if (selectedCluster2 !== this.selectedCluster2) {
        this.selectedCluster2 = selectedCluster2;

      }

      if (selectedCluster3 !== this.selectedCluster3) {
        this.selectedCluster3 = selectedCluster3;

      }

      if (this.reqst > 1) {
        this.getDashboardTarPricePurchasePrice();
      }
    });
  }

  ngOnInit() {
    this.getDashboardTarPricePurchasePrice();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  getDashboardTarPricePurchasePrice() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    setTimeout(() => {
      const url = this.station ?
        this.gasprecioService.getDashboardTarPricePurchasePriceStation(this.period, typeValue, n) :
        this.gasprecioService.getDashboardTarPricePurchasePrice(this.idGroup, this.zoneSelected, this.period, this.importants, typeValue, n, this.selectedCluster, this.selectedCluster2, this.selectedCluster3);
      url.subscribe({
        next: (data: any) => {
          const info = data;
          this.tarPrice = info['tar_price'];
          this.purchasePrice = info['purchase_price'];
          this.setData();
        }, complete: () => {
          this.reqst = 2;
        }, error: () => {
          this.reqst = 3;
        }
      });
    }, 200);
  }

  setData() {
    this.reqst = 1;
    const colorLight = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const tarChartData: any = {
      name: 'Precio TAR',
      data: this.tarPrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorLight,
      dashStyle: 'ShortDot',
      lineWidth: 2,
      marker: { radius: 2 },
      dataLabels: {
        enabled: this.isAnimationEnabled,
        format: '$ {y:.2f}',
        style: { fontSize: '10' },
      },
      yAxis: 0
    };

    const purchaseChartData: any = {
      name: 'Precio de Compra',
      data: this.purchasePrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorLight,
      lineWidth: 2,
      dataLabels: {
        enabled: this.isAnimationEnabled,
        format: '$ {y:.2f}',
        style: { fontSize: '10' },
      },
      yAxis: this.showYAxis ? 0 : 1
    };

    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.purchasePrice.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions.series = [tarChartData, purchaseChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas2: any = document.getElementById('mychart2');
    const imgData2 = canvas2.toDataURL('image/png');
    this.sharedService.nextImgData2 = imgData2;
  }
}