import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-select-schedule',
  templateUrl: './select-schedule.component.html',
  styleUrls: ['./select-schedule.component.scss']
})
export class SelectScheduleComponent implements OnInit, OnDestroy {

  @Input() disabled: any;
  public items: any[] = [ ];
  public schedule: any = [];
  public scheduleSelected: any = [];
  public ngxDisabled = false;
  schedule$: Subscription;
  public idGroup: any;
  idGroup$: Subscription;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }
    });

    this.schedule$ = this.sharedService.sharedSchedule.subscribe(schedule => {
      if (this.schedule !== schedule) {
        this.schedule = schedule;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getSchedules();
  }

  ngOnDestroy() {
    this.schedule$.unsubscribe();
  }

  getSchedules() {
    this.gasprecioService.getSchedules(this.idGroup).subscribe((data: any) => {
      const schedules: any = data;
      schedules.forEach((element: any) => {
        this.items.push({ name: 'Horario ' + element['num_schedule'], value: element['_id'], numSchedule: element['num_schedule'] });
      });
      this.ngxDisabled = false;
    });
  }

  changeSchedule(event: any) {
    this.schedule = event;
    this.items.forEach((element: any) => {
      if (element['value'] === this.schedule) {
        this.scheduleSelected = element['numSchedule'];
        this.sharedService.nextScheduleSelected = this.scheduleSelected;
      }
    });
    this.sharedService.nextSchedule = this.schedule;
  }
}
