<div *ngIf="reqst === 1">
    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
        style="width: 100%; height: 380px; display: block;">
    </highcharts-chart>
</div>

<div *ngIf="reqst === 0 " class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>