<div style="display: inline-block;">
    Ventas - Venta Tendencia
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Tendencia general de las ventas
    </epy-tooltip>
    - Venta Pronosticada
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Venta Pronosticada según modelo matemático
    </epy-tooltip>
</div>
<div class="flex-item" *ngIf="reqst===2">
    <div>
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
            style="width: 100%;height: 380px;display: block;overflow: hidden;"></highcharts-chart>
    </div>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<ng-template #editModal let-modal class="modal-dialog big-dialog">
    <div class="modal-header" #tableModal>
        <h5 class="modal-title" id="downloadPricesLabel">
        </h5>
        <button type="button" class="close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0;">
        <app-table-alerts></app-table-alerts>
    </div>
    <div class="footer-modal" style="padding-top: 0;">

    </div>
</ng-template>