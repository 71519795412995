import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-conco-discount',
  templateUrl: './select-conco-discount.component.html',
  styleUrls: ['./select-conco-discount.component.scss']
})
export class SelectConcoDiscountComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Sin Conco', value: null },
    { name: 'Conco 1', value: '1' },
    { name: 'Conco 2', value: '2' }
  ];

  public concoDiscount: any = [];
  public ngxDisabled = false;
  concoDiscount$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.concoDiscount$ = this.sharedService.sharedConcoDiscount.subscribe(concoDiscount => {
      this.concoDiscount = concoDiscount;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.concoDiscount$.unsubscribe();
  }

  changeConcoDiscount(event: any) {
    this.concoDiscount = event;
    this.sharedService.nextConcoDiscount = this.concoDiscount;
  }
}
