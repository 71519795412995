import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-days-change',
  templateUrl: './select-days-change.component.html',
  styleUrls: ['./select-days-change.component.scss']
})
export class SelectDaysChangeComponent implements  OnDestroy {

  public items: any[] = [
    { value: 1, name: 'Últ. día' },
    { value: 3, name: 'Últ. 3 días' },
    { value: 7, name: 'Últ. 7 días' },
    { value: 14, name: 'Últ. 14 días' },
    { value: 30, name: 'Últ. 30 días' },
    { value: 60, name: 'Últ. 60 días' },
    { value: 90, name: 'Últ. 90 días' }
  ];

  public daysChange: any = [];
  public ngxDisabled = false;
  daysChange$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.daysChange$ = this.sharedService.sharedDaysAgoChange.subscribe(daysChange => {
      this.daysChange = daysChange;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.daysChange$.unsubscribe();
  }

  changeDaysAgo(event: any) {
    this.daysChange = event;
    this.sharedService.nextDaysAgoChange = this.daysChange;
  }
}
