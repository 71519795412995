<div class="margin-type">
    <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
        [disabled]="ngxDisabled" [autoClearSearch]="false" [(ngModel)]="purchaseSource"
        placeholder="Seleccionar Producto" (select)="changePurchaseSource($event)">

        <ng-template ngx-select-option-not-found let-input>
            "{{input}}" no encontrado
        </ng-template>

    </ngx-select>
</div>