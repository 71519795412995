import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-market-type',
  templateUrl: './select-market-type.component.html',
  styleUrls: ['./select-market-type.component.scss']
})
export class SelectMarketTypeComponent implements  OnDestroy {

  public items: any[] = [
    { name: 'Cluster', value: '1' },
    { name: 'No Cluster', value: '0' },
    { name: 'Sin Filtro', value: 'null' }
  ];
  public markeTypeSelected: any = [];
  public ngxDisabled = false;
  public nationalSelected: any;
  public page = '';
  markeTypeSelected$: Subscription;
  page$: Subscription;
  nationalSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.markeTypeSelected$ = sharedService.sharedMarkeType.subscribe((markeType: string) => {
      if (this.markeTypeSelected !== markeType) {
        this.markeTypeSelected = markeType;
      }
    });

    this.nationalSelected$ = sharedService.sharedNational.subscribe((national: string) => {
      if (national !== this.nationalSelected) {
        this.nationalSelected = national;
        if (this.page === 'state' && this.nationalSelected === '1') {
          this.markeTypeSelected = '1';
        }
      }
    });

    this.page$ = sharedService.sharedPageMetric.subscribe((page: string) => {
      if (this.page !== page) {
        this.page = page;
        if (this.page === 'state' && this.nationalSelected === '1') {
          this.markeTypeSelected = '1';
        }
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.markeTypeSelected$.unsubscribe();
    this.nationalSelected$.unsubscribe();
    this.page$.unsubscribe();
  }

  changeMarketType(event: any) {
    this.markeTypeSelected = event;
    this.sharedService.nextMarketType = this.markeTypeSelected;
  }

}
