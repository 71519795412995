import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-market',
  templateUrl: './select-market.component.html',
  styleUrls: ['./select-market.component.scss']
})
export class SelectMarketComponent implements OnInit, OnDestroy {

  public items: any[] = [{ value: '', name: 'Zonas' }];
  public idGroup: any;
  public zone: any = [];
  public ngxDisabled = true;
  private subscriptions: Subscription[] = [];

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.idGroup = localStorage.getItem('group');
    this.subscriptions.push(
      this.sharedService.sharedGroup.subscribe((idGroup: string) => {
        if (idGroup !== this.idGroup) {
          this.idGroup = idGroup;
        }
      }),
      this.sharedService.sharedZone.subscribe((zone: any) => {        
        this.zone = zone;
      })
    );
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getZones();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getZones() {
    this.gasprecioService.getZones(this.idGroup).subscribe((data: any) => {
      this.items.push(...data.map((element: any) => {
        element.name = this.formatZoneName(element.name);
        return { name: this.gasprecioService.capitalizeFirstLetter(element.name), value: element.zone_id };
      }));
      this.ngxDisabled = false;
    });
  }

  private formatZoneName(name: string): string {
    const replacements = {
      'Ciudad De México': 'CDMX',
      'Estado De México': 'Edo. Méx.',
      'Veracruz De Ignacio De La Llave': 'Veracruz',
      'Baja California Sur': 'B.C.S',
      'Coahuila De Zaragoza': 'Coahuila',
      'Michoacán De Ocampo': 'Michoacán',
      'San Miguel de Allende': 'San Miguel de All'
    };
    return replacements[name] || name;
  }

  changeMarket(event: any) {
    this.zone = event;
    this.sharedService.nextZone = this.zone;
  }
}
