<div class="header-c">
    Precio de Venta
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Precio de venta promedio diario reportado a la CRE por estación.
    </epy-tooltip>
    - Precio de Comp.
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Precio promedio de los competidores con mayor impacto <br>en tu volumen de venta (mayor
        correlación).
    </epy-tooltip>
    <span *ngIf="station && productType !== 'total'">
        ------>
        &nbsp; <i title="Ver Evolución Competencia" class="fa-regular fa-line-chart chart" aria-hidden="true"
            (click)="getEvolution();openModalChartEvol();"></i>
    </span>
    &nbsp;
    <input type="checkbox" id="checkbox-sale-earning" [(ngModel)]="showPriceComp" (ngModelChange)="setData()"> <label
        style="font-size: 13px;margin-bottom: 0;" for="checkbox-sale-earning" title="Ver Min y Max Comp">Ver Min y Max Comp</label>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div [ngClass]="{
  'flex-item': ((period === 'week' && (weeks === 8 || weeks === 12)) || period === 'month' || (period === 'day' && (days === 15 || days === 30))) && !station,
  'flex-right': !(period === 'week' && (weeks === 8 || weeks === 12)) && period !== 'month' && !(period === 'day' && (days === 15 || days === 30)) || station
}" *ngIf="reqst===2">
    <div style="margin-right: 1%;">
        <highcharts-chart id="mychart4" [Highcharts]="Highcharts" [options]="chartOptions" [ngStyle]="{ 'height': station || period !== 'month' && !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) ? 'auto': '310px'}" style="width: 100%;display: block;overflow: hidden;"></highcharts-chart>
    </div>
</div>

<ng-template #chartModalEvol let-modal class="modal-dialog">
    <div class="modal-header">
        <h5 class="modal-title" id="downloadPricesLabel">
            <p class="text-center station-date" style="margin-bottom: 0 !important;">
                <strong>{{nameStation}}</strong>
            </p>
        </h5>
        <button type="button" class="close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0;">
        <div *ngIf="reqstEvol===2">
            <div>
                <highcharts-chart [Highcharts]="Highcharts" [options]="chartEvolOptions" style="width: 100%; height: 80vh; display: block;"></highcharts-chart>
            </div>
        </div>
        <div *ngIf="reqstEvol===1" class="text-center loader-container">
            <div class="spinner-grow align-middle text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="footer-modal" style="padding-top: 0;">

    </div>
</ng-template>
