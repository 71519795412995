<div  [ngClass]="{'lvl-discount': section}">
    <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
        [disabled]="ngxDisabled" [autoClearSearch]="false" [(ngModel)]="levelDiscount"
        placeholder="Seleccionar Nivel Descuento" (select)="changeLevelDiscount($event)">

        <ng-template ngx-select-option-not-found let-input>
            "{{input}}" no encontrado
        </ng-template>

    </ngx-select>
</div>