export enum GroupIds {
    Ap = '602b06ffeda25399fde229ee',
    Atg = '65ba9f3816122715b9ee199f',
    Bocgas = '60afa9bb55f4ee369d1df40c',
    Burgos = '652089ad94a59a35e91437da',
    BurgosExpansion = '65c2849672bf4238ffccbe4c',
    Candelaria = '65947261374f88321dc39ff8',
    Cargo = '657336284652b86841f23cb6',
    Combuexpress = '63fd4c8f1a5bfd390727f392',
    Combuservicio = '649216f6edc3bad5497001f9',
    Corpoconta = '65d50dac037a5e0495e10c17',
    Dimeco = '646e7ea1e53664f7222fabd6',
    Enerser = '643f507358715227618de808',
    Eucomb = '651f57183f34ec2b2406465c',
    Esmexico = '63879c838f5a664f01681c4c',
    Estrella = '657ddf623065a34392b7c1f4',
    ElFuerte = '65f9fc4b9316c297a1fd931a',
    Exelgas = '65fb2b0f912c7730c8634aa3',
    FidelMora = '65f1d276b530248bf1f9cb3c',
    Flecha = '665a17634b4797a1fa1559b1',
    Garbri = '657c92f13065a34392a3d0da',
    Gasosur = '648ba1093447f170a68ef4f1',
    Gasonline = '60afa9aa55f4ee369d1df40b',
    GasMaster = '6627ecc258b79506a2350f6f',
    Gaspit = '669ede5f3a3c2f84da98f9cc',
    Gaspasa = '649f17348a5aa86209c2b3a9',
    Gilga = '5fd3ca02b9f729a66a9e20dd',
    Girasoles = '65287d5b86776b69f80a628f',
    Gracoil = '66847b620832c01ee81d8f0d',
    Guerra = '6539b9d1b1f13bc951a1466d',
    Gomez = '668e85976102602768f02b7b',
    Gocalvi = '648b72aa3447f170a68c82a6',
    Hormadi = '62d59700bdacaa85fcbdd6a1',
    Indian = '66ba9ae374475107bcbb4b20',
    Imperial = '648a5456cdc49c6a6d0ca302',
    Ibiza = '6579de2fb3d6140692a06488',
    LaPopular = '649b96f435d5a93616ae4547',
    Lubrigas = '6552da47be20575ea73226c3',
    Megagas = '666cb6528c251f2c5d6204b5',
    Mobico = '6697df2bfb7789085d7d82b4',
    Omega = '638a3fd8c56cf86ffa6449cf',
    Origas = '65cd5252bfa564c796e6a9d4',
    Pa = '60afa99655f4ee369d1df409',
    Playas = '648b68543447f170a68b6d9f',
    Petrum = '638e79fbc56cf86ffaa13e3f',
    PetroFigues = '62c4e01d22a6635208509fd9',
    PetroG = '66b296a38dff6c93012ce8fe',
    Petroll = '648b51163447f170a689b8dd',
    Prado = '657b6ebdffa4c0244ef556a0',
    Prodesa = '656e61c439f75ca94d47745c',
    Pizano = '662bcc3e87a85628c5743a7b',
    Rendichicas = '615efe30b318ce63b8971986',
    Rebeil = '66885aee96f87f6a4b07ea23',
    Rodriguez = '645a98af1dd1ab1b4b5c39ae',
    Roky = '651aef9fb13c717f2fc79deb',
    Ruta = '668eaeceec578636ce2a789a',
    Saru = '63aa1f4642d78337d94add1d',
    Salpat = '667c80087c07543656dafa30',
    SanRob = '667dcd172b94f9b1d025f3c1',
    Vicam = '660ef472668219a4cef9ac3a',
    Vigia = '669ec1ed41647880515c479b'
}