import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-dif-max-price-competitors',
  templateUrl: './table-dif-max-price-competitors.component.html',
  styleUrls: ['./table-dif-max-price-competitors.component.scss']
})
export class TableDifMaxPriceCompetitorsComponent implements OnInit, OnDestroy {

  competitors;
  daysAgo = 180
  daysAgo$!: Subscription;
  productType = 'regular';
  productType$!: Subscription;
  rowsCompetitors: any;
  reqst = 0;
  variableSelected = 'sales';
  variableSelected$!: Subscription;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getDiffPxMax();
        }
      }
    });

    this.variableSelected$ = this.sharedService.sharedVariablePxOpt.subscribe(variableSelected => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
        this.getDiffPxMax();
      }
    });

    this.daysAgo$ = this.sharedService.sharedDaysOptimumPx.subscribe(daysAgo => {
      if (this.daysAgo !== daysAgo) {
        this.daysAgo = daysAgo;
        this.getDiffPxMax();
      }
    });
  }

  ngOnInit(): void {
    this.getDiffPxMax();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.variableSelected$.unsubscribe();
    this.daysAgo$.unsubscribe();
  }

  getDiffPxMax() {
    this.reqst = 0;
    this.gasprecioService.getDiffPxMax(this.productType, this.variableSelected, this.daysAgo).subscribe((data: any) => {
      if (data['competitors']) {
        this.competitors = data['competitors'].length;
        data['competitors'].forEach(element => {
          element['name'] = this.gasprecioService.removeSADECV(element['name']);
        });

        this.rowsCompetitors = data['competitors'];
      }
      this.reqst = 1;
    });
  }

}
