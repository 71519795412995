import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-level-discount',
  templateUrl: './select-level-discount.component.html',
  styleUrls: ['./select-level-discount.component.scss']
})
export class SelectLevelDiscountComponent implements OnDestroy {

  @Input() section: any = false;

  public items: any[] = [
    { name: 'Nivel 1', value: '1' },
    { name: 'Nivel 2', value: '2' },
    { name: 'Nivel 3', value: '3' },
    { name: 'Nivel 4', value: '4' },
    { name: 'Nivel 5', value: '5' },
    { name: 'Nivel 6', value: '6' },
    { name: 'Nivel 7', value: '7' },
    { name: 'Nivel 8', value: '8' },
    { name: 'Nivel 9', value: '9' },
    { name: 'Conco 1', value: '10' },
    { name: 'Conco 2', value: '11' }
  ];

  public levelDiscount: any = [];
  public ngxDisabled = false;
  levelDiscount$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.levelDiscount$ = this.sharedService.sharedLevelDiscount.subscribe(levelDiscount => {
      this.levelDiscount = levelDiscount;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.levelDiscount$.unsubscribe();
  }

  changeLevelDiscount(event: any) {
    this.levelDiscount = event;
    this.sharedService.nextLevelDiscount = this.levelDiscount;
  }
}
