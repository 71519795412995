import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-top',
  templateUrl: './select-top.component.html',
  styleUrls: ['./select-top.component.scss']
})
export class SelectTopComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Top Real', value: true},
    { name: 'Top Competidores', value: false }
  ];

  public top: any = [];
  top$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.top$ = this.sharedService.sharedTop.subscribe(top => {
      this.top = top;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.top$.unsubscribe();
  }

  changeTop(event: any) {
    this.top = event;
    this.sharedService.nextTop = this.top;
  }
}
