import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-period-comparative',
  templateUrl: './select-period-comparative.component.html',
  styleUrls: ['./select-period-comparative.component.scss']
})
export class SelectPeriodComparativeComponent implements  OnDestroy {

  public items: any[] = [
    { value: 1, name: 'Prom. día' },
    { value: 2, name: 'Actual' },
    { value: 3, name: 'Tendencia' }
  ];

  public periodComparative: any = [];
  public ngxDisabled = false;
  periodComparative$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.periodComparative$ = this.sharedService.sharedPeriodComparative.subscribe(periodComparative => {
      this.periodComparative = periodComparative;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.periodComparative$.unsubscribe();
  }

  changeVariable(event: any) {
    this.periodComparative = event;
    this.sharedService.nextPeriodComparative = this.periodComparative;
  }
}
