import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import * as moment from 'moment-timezone';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-month-budget',
  templateUrl: './select-month-budget.component.html',
  styleUrls: ['./select-month-budget.component.scss']
})
export class SelectMonthBudgetComponent implements OnDestroy {

  @Input() format: any = false;
  public items: any[] = [];
  public month: any = [];
  month$: Subscription;
  public ngxDisabled = true;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    setTimeout(() => {
      const startDate = moment('2023-12-01');
      const endDate = moment().add(1, 'year');
      if (startDate < endDate) {
        const date = startDate.startOf('month');
        while (date <= endDate.endOf('month')) {
          if (this.format) {
            this.items.push({
              name: this.gasprecioService.capitalizeFirstLetter(date.format('MMM YY')),
              value: date.startOf('month').format('YYYY-MM-DD')
            });
          } else {
            this.items.push({
              name: this.gasprecioService.capitalizeFirstLetter(date.format('MMMM YYYY')),
              value: date.startOf('month').format('YYYY-MM-DD')
            });
          }

          date.add(1, 'month');
        }
      }

      this.month$ = sharedService.sharedMonthBudgetSelected.subscribe((month: string) => {
        this.month = month;
      });
    }, 100);

    setTimeout(() => {
      this.ngxDisabled = false;
    }, 500);
  }

  ngOnDestroy() {
    this.month$.unsubscribe();
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  changeMonthsAgo(event: any) {
    this.month = event;
    this.sharedService.nextMonthBudgetSelected = this.month;
    this.sharedService.nextPeriodBudgetSelected = 'month';
  }
}
