import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreciosService } from 'src/app/modules/gasprecio/services/precios.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as EXIF from 'exif-js';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-prices',
  templateUrl: './upload-prices.component.html',
  styleUrls: ['./upload-prices.component.scss']
})
export class UploadPricesComponent implements OnInit {

  @ViewChild('photoModal') photoModal: TemplateRef<any>;
  competitors = [];
  creId;
  currentStation;
  dateTime: any = moment().tz('America/Mexico_City').format('YYYY/MM/DD HH:mm:ss');
  imageHeight: any = null;
  imageSrc: string;
  imageWidth: any = null;
  imgDateSelected: any;
  imgNameSelected: any;
  imgSelected: any;
  infoImg: any;
  idStation: any;
  listCompetitors: any;
  pixelXDimension: any = null;
  pixelYDimension: any = null;
  rol;
  selectedImage;
  station = '';
  showList = false;
  size: any = null;
  type: any = null;
  reqst: any = 0;
  columns = [
    { name: 'photo', label: 'Foto', width: '5%', styleClass: 'text-center' },
    { name: 'name', label: 'Estación', width: '70%', styleClass: 'text-center' },
    { name: 'distance', label: 'Distancia', width: '7.5%', styleClass: 'text-center' },
    { name: 'brand', label: 'Marca', width: '7.5%', styleClass: 'text-center' },
  ];

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    file: new UntypedFormControl('', [Validators.required]),
    fileSource: new UntypedFormControl('', [Validators.required]),
    fileData: new UntypedFormControl('', [Validators.required]),
    date: new UntypedFormControl('')
  });

  constructor(
    private preciosService: PreciosService,
    private authenticationService: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { 
    this.activatedRoute.paramMap.subscribe(params => {
      this.idStation = params['params']['idStation'] || null;
      this.getCompetitors(this.idStation);
    });
  }

  ngOnInit() {}

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;
  }

  getCompetitors(idStation) {
    this.preciosService.getMonitor(idStation)
      .subscribe((data: any) => {
        this.currentStation = data['client'];
        this.competitors = data['competitors'];
        this.competitors.forEach((element: any, index) => {
          element['name'] = element['name'] ? this.preciosService.removeSADECV(element['name']) : '';
          element['brand'] = element['brand'] ? this.preciosService.removeSADECV(element['brand']) : '';
          element['brand'] = element['brand'] === 'Bp' ? 'BP' : element['brand'];
          element['brand'] = element['brand'] === 'Permer Gas' ? 'Permer' : element['brand'];
          element['brand'] = element['brand'] === 'Ferche Gas' ? 'Ferche' : element['brand'];
        });

        this.reqst = 1;
      });
  }

  get f() {
    return this.myForm.controls;
  }

  async onFileChange(event, station, cre) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.myForm.get('fileData')?.setValue(event.target.files[0]);
      this.infoImg = await this.getExif(file);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.myForm.patchValue({
          fileSource: reader.result
        });
      };
      this.selectedImage = event.target.files?.[0];
      this.creId = cre;
      this.station = station;
      this.openModalChart();
    }
  }

  getExif(img) {
    EXIF.getData(img, function () {
      const properties = ['lastModified', 'DateTime', 'ImageHeight', 'ImageWidth', 'PixelXDimension', 'PixelYDimension'];
      for (const prop of properties) {
        const value = EXIF.getTag(this, prop);
        this[prop.charAt(0).toLowerCase() + prop.slice(1)] = value || 0;
      }
      this.dateTime = this.dateTime ? this.dateTime.replace(/:/g, '/') : moment().tz('America/Mexico_City').format('YYYY/MM/DD HH:mm:ss');
    });

    return img;
  }

  submit() {
    this.closeModalChart();
    this.preciosService.showLoader('Subiendo Foto', 'Aguarda un momento...');
    this.type = this.infoImg['type'] ? this.infoImg['type'] : '';
    this.size = this.infoImg['size'] ? this.infoImg['size'] : 0;
    this.dateTime = this.infoImg['dateTime'] ? this.infoImg['dateTime'] : moment().tz('America/Mexico_City').format('YYYY/MM/DD HH:mm:ss');
    this.imageHeight = this.infoImg['imageHeight'] ? this.infoImg['imageHeight'] : 0;
    this.imageWidth = this.infoImg['imageWidth'] ? this.infoImg['imageWidth'] : 0;
    this.pixelXDimension = this.infoImg['pixelXDimension'] ? this.infoImg['pixelXDimension'] : 0;
    this.pixelYDimension = this.infoImg['pixelYDimension'] ? this.infoImg['pixelYDimension'] : 0;
    this.preciosService.uploadImage(this.selectedImage, this.station, this.type, this.size, this.dateTime, this.imageHeight, this.imageWidth, this.pixelXDimension, this.pixelYDimension, this.creId)
      .subscribe({
        next: (data) => {
          this.preciosService.displayMessage('success', '¡Foto registrada!', 'La foto ha sido enviada con éxito.', {
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        },
        error: () => {
          this.preciosService.displayMessage('error', '¡Error!', 'La foto no ha sido enviada con éxito.');
        }
      });
  }

  formatPrice(station, product, mode = 'price', diff = false) {
    if (!station) {
      return false;
    }

    const priceProperty = product + '_selling_price';


    if (diff && this.currentStation) {
      const prevPrice = this.currentStation;
      if (!prevPrice[priceProperty] || !station[priceProperty]) {
        return 'N/A';
      }
      const difference = (station[priceProperty] - prevPrice[priceProperty]).toFixed(2);
      return mode === 'priceC' ? +difference : difference;
    }

    if (typeof station[priceProperty] === 'number' && !isNaN(station[priceProperty])) {
      return station[priceProperty] ? '$ ' + station[priceProperty].toFixed(2) : 'N/A';
    } else {
      return 'N/A';
    }
  }

  formatDistance(distance) {
    return this.preciosService.formatDistance(distance);
  }

  setImg(img, name, date) {
    this.imgSelected = img;
    this.imgNameSelected = name;
    this.imgDateSelected = moment.tz(date, 'America/Mexico_City').format('DD/MMMM/YY HH:mm');
  }

  openModalChart() {
    this.modalService.open(this.photoModal, { windowClass: 'photoModal', centered: true });
  }

  closeModalChart() {
    this.modalService.dismissAll(this.photoModal);
  }

  async alertLogout() {
    Swal.fire({
      title: '¿Estás seguro que deseas Cerrar Sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00e07b',
      cancelButtonColor: '#fa1975',
      confirmButtonText: 'Si, Cerrar Sesión',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.logoutPrices();
      }
    });
  }

  logoutPrices() {
    this.authenticationService.logoutPrices();
  }
}
