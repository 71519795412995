<table class="min">
    <thead>
        <tr>
            <th style="width: 100%;padding: 0 1px !important;">
                <span style="font-size: 15px;text-decoration: underline;margin-left: 5px;">
                    # Competidores con Mínimo Hoy
                </span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="left-text">
                <span class="left-text">
                    {{competitors}}
                </span>
            </td>
        </tr>
    </tbody>
</table>

<br>

<table class="min">
    <thead>
        <tr>
            <th style="width: 70%;padding: 0 1px !important;">
                <span style="font-size: 15px;text-decoration: underline;margin-left: 5px;">
                    Estaciones
                </span>
            </th>
            <th style="width: 30%;padding: 0 1px !important;">
                <span style="font-size: 15px;text-decoration: underline;margin-left: 5px;">
                    Precio
                </span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of rowsCompetitors; let index = index">
            <td class="left-text">
                <span class="left-text">
                    {{row.name}}
                </span>
            </td>
            <td class="left-text">
                <span *ngIf="row.selling_price !== '-'">
                    $ {{row.selling_price| number : '1.2-2'}}
                </span>
            </td>
        </tr>
    </tbody>
</table>
