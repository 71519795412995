import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-campaign-type',
  templateUrl: './select-campaign-type.component.html',
  styleUrls: ['./select-campaign-type.component.scss']
})
export class SelectCampaignTypeComponent implements OnDestroy {

  public items: any[] = [
    { value: 'days', name: 'Promedio Día' },
    { value: 'past_weeks', name: 'Mismo día' },
    { value: 'diff_days', name: 'Toda la semana' }
  ];

  public campaignType: any = [];
  campaignType$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.campaignType$ = this.sharedService.sharedCampaignType.subscribe(campaignType => {
      this.campaignType = campaignType;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.campaignType$.unsubscribe();
  }

  changeCampaignType(event: any) {
    this.campaignType = event;
    this.sharedService.nextCampaignType = this.campaignType;
  }
}
