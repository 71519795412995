import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-period-budget',
  templateUrl: './select-period-budget.component.html',
  styleUrls: ['./select-period-budget.component.scss']
})
export class SelectPeriodBudgetComponent implements OnDestroy {

  public items: any[] = [
    { name: 'Semana', value: 'week' },
    { name: 'Mes', value: 'month' },
    { name: 'Año', value: 'year' }
  ];

  public period: any = [];
  public ngxDisabled = false;
  period$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.period$ = this.sharedService.sharedPeriodBudget.subscribe(period => {
      this.period = period;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.period$.unsubscribe();
  }

  changePeriod(event: any) {
    this.period = event;
    this.sharedService.nextPeriodBudget = this.period;
  }
}
