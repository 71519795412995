import * as moment from 'moment-timezone';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import { ClusterType } from 'src/app/modules/gasprecio/enums/cluster-type.enum';

@Component({
  selector: 'app-table-comparative-dashboard',
  templateUrl: './table-comparative-dashboard.component.html',
  styleUrls: ['./table-comparative-dashboard.component.scss']
})
export class TableComparativeDashboardComponent implements OnInit, OnDestroy {

  days = 30;
  columns = [];
  headerTable = [];
  id;
  idGroup: GroupIds | string = GroupIds.Pa;
  idStation;
  importants;
  months = 12;
  months$!: Subscription;
  private destroy$ = new Subject<void>();
  period = 'day';
  productType = 'regular';
  rol;
  rows: any;
  reqst = 0;
  SelectionType = SelectionType;
  selected: any[] = [];
  selectedCluster;
  selectedCluster2;
  selectedCluster3;

  temp: any = [];
  variableValue: any = 'selling_price';
  variableValue$: Subscription;
  val: any;
  heightTable = 0;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');

    combineLatest([
      this.sharedService.sharedProductTypeTotal,
      this.sharedService.sharedVariableDashboard,
      this.sharedService.sharedGroup,
      this.sharedService.sharedZoneDashboard,
      this.sharedService.sharedPeriodDashboard,
      this.sharedService.sharedDaysDashboard,
      this.sharedService.sharedWeeksAgo,
      this.sharedService.sharedMonthsAgo,
      this.sharedService.sharedImportants,
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster1),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster2),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster3)
    ]).pipe(takeUntil(this.destroy$)).subscribe(([
      productType,
      variableValue,
      idGroup,
      zone,
      period,
      days,
      weeks,
      months,
      importants,
      selectedCluster,
      selectedCluster2,
      selectedCluster3
    ]) => {
      if (this.productType !== productType) {
        this.productType = productType;
      }

      if (this.variableValue !== variableValue) {
        this.variableValue = variableValue;
      }

      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
      }

      this.zoneSelected = zone === 'Total' ? null : zone;

      if (this.period !== period) {
        this.period = period;
      }

      if (this.days !== days) {
        this.days = days;
      }

      if (this.weeks !== weeks) {
        this.weeks = weeks;
      }

      if (this.months !== months) {
        this.months = months;
      }

      if (this.importants !== importants) {
        this.importants = importants;
      }

      if (this.selectedCluster !== selectedCluster) {
        this.selectedCluster = selectedCluster;
      }

      if (this.selectedCluster2 !== selectedCluster2) {
        this.selectedCluster2 = selectedCluster2;
      }

      if (this.selectedCluster3 !== selectedCluster3) {
        this.selectedCluster3 = selectedCluster3;
      }

      if (this.reqst > 0) {
        this.getDashboardComparative();
      }
    });
  }

  ngOnInit(): void {
    this.getDashboardComparative();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRowHeight(row: any) {
    return row.height;
  }

  filterTable($event?: any) {
    if ($event) {
      this.val = $event.target.value;
    }
    this.temp = this.rows.filter((d: any) => {
      for (const key in d) {
        if (d[key] != null) {
          if (key === 'nameStation' || key === 'product' || key === 'station' || key === 'municipio' || key === 'entidad') {
            if (d[key].toLowerCase().indexOf(this.val.toLowerCase()) !== -1) {
              return true;
            }
          }
        }
      }
      return false;
    });
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getDashboardComparative() {
    this.reqst = 0;
    this.heightTable = 60;
    this.headerTable = [];
    this.columns = [];
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    setTimeout(() => {
      this.gasprecioService.getDashboardComparative(this.idGroup, this.variableValue, this.productType, this.period, this.zoneSelected, this.importants, typeValue, n, this.selectedCluster, this.selectedCluster2, this.selectedCluster3).subscribe((data: any) => {
        const inf = [];
        const excel = [];
        const headerExcel = [''];
        data.forEach((element: any, index: number) => {
          const months = Object.keys(element['values']).length - 1;
          this.heightTable = this.heightTable + 24;
          const r = {};
          const rowExcel = [];
          element['values'].map((row: any, i) => {
            if (i === 0) {
              let value;
              if (this.variableValue === 'volume') {
                value = row['value'] != null ? this.numberWithCommas(row['value']) : '-';
              } else if (this.variableValue === 'quotient') {
                value = row['value'] != null ? row['value'].toFixed(3) : '-';
              } else {
                value = row['value'] != null ? row['value'].toFixed(2) : '-';
              }
              rowExcel.push(row.zone_name, value);
            } else {
              let value;
              if (this.variableValue === 'volume') {
                value = row['value'] != null ? this.numberWithCommas(row['value']) : '-';
              } else if (this.variableValue === 'quotient') {
                value = row['value'] != null ? row['value'].toFixed(3) : '-';
              } else {
                value = row['value'] != null ? row['value'].toFixed(2) : '-';
              }
              rowExcel.push(value);
            }

            if (index === 0) {
              if (this.period === 'day') {
                headerExcel.push(moment(row['date']).tz('America/Mexico_City').format('DD MMM'));
              } else if (this.period === 'week') {
                headerExcel.push(row['date_alias']);
              } else if (this.period === 'month') {
                headerExcel.push(moment(row['date']).tz('America/Mexico_City').format('MMM YY'));
              }
            }
            if (this.variableValue === 'volume') {
              r['day_' + i] = row['value'] != null ? this.numberWithCommas(row['value']) + ' lts' : '-';
            } else if (this.variableValue === 'quotient') {
              r['day_' + i] = row['value'] != null ? row['value'].toFixed(3) : '-';
            } else {
              r['day_' + i] = row['value'] != null ? '$ ' + row['value'].toFixed(2) : '-';
            }
            r['height'] = 24;
            r['date'] = moment(row['date']).tz('America/Mexico_City').format('DD/MM/YY');
            r['zone'] = row['zone_name'];
            if (this.period === 'day') {
              this.headerTable.push(moment(row['date']).tz('America/Mexico_City').format('DD MMM'));
              if (index === 0) {
                this.columns.push({
                  name: moment(row['date']).tz('America/Mexico_City').format('DD MMM'),
                  prop: i
                });
              }
            } else if (this.period === 'week') {
              this.headerTable.push(row['date_alias']);
              if (index === 0) {
                this.columns.push({
                  name: row['date_alias'],
                  prop: i
                });
              }
            } else if (this.period === 'month') {
              this.headerTable.push(moment(row['date']).tz('America/Mexico_City').format('MMM YY'));
              if (index === 0) {
                this.columns.push({
                  name: moment(element['date']).tz('America/Mexico_City').subtract(months - i, 'month').format('MMM YY'),
                  prop: i
                });
              }
            }
          });
          inf.push(r);
          excel.push(rowExcel);
        });
        this.sharedService.nextHeaderTableComparative = headerExcel;
        this.sharedService.nextTableComparative = excel;
        this.rows = this.period === 'month' && this.idGroup === GroupIds.Rendichicas ? inf.slice(0, 8) : inf;
        this.temp = this.rows.map((prop: any, key: any) => {
          return {
            ...prop,
            id: key
          };
        });
        this.reqst = 1;
      });
    }, 300);
  }

}
