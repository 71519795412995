<ng-container class="reason">
    <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
        [disabled]="ngxDisabled" [(ngModel)]="markeTypeSelected" placeholder="Cluster"
        (select)="changeMarketType($event)">

        <ng-template ngx-select-option-not-found let-input>
            "{{input}}" no encontrado
        </ng-template>

    </ngx-select>
</ng-container>