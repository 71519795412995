import * as moment from 'moment-timezone';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';

@Component({
  selector: 'app-last-update',
  templateUrl: './last-update.component.html',
  styleUrls: ['./last-update.component.scss']
})
export class LastUpdateComponent implements OnInit, OnDestroy {

  @Input() icon: boolean;
  @Input() short: boolean;
  date: string;
  dateSubs$!: Subscription;
  event = false;
  scrHeight: any;
  scrWidth: any;
  text = 'Últ. actualización:'

  constructor(
    private gasprecioService: GasprecioService
  ) {
    this.dateSubs$ = gasprecioService.getLastUpdate().subscribe((resp: any) => { this.date = moment(resp).tz('America/Mexico_City').format('DD MMM HH:mm'); });
  }

  ngOnInit(): void {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || this.short) {
      this.text = 'Últ. act:';
    } else {
      this.text = 'Últ. actualización:';
    }
  }
  
  ngOnDestroy() {
    this.dateSubs$.unsubscribe();
  }

}
