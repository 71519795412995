import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-week-budget',
  templateUrl: './select-week-budget.component.html',
  styleUrls: ['./select-week-budget.component.scss']
})
export class SelectWeekBudgetComponent implements OnDestroy {

  @Input() format: any = false;
  public items: any[] = [];
  public week: any = [];
  week$: Subscription;
  public ngxDisabled = true;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    setTimeout(() => {
      const startDate = moment('2023-12-01');
      const endDate = moment().add(1, 'year');

      if (startDate.isBefore(endDate)) {
        const date = startDate.clone().startOf('isoWeek');
        const weeksOfYear = [];
        let prevYear;

        while (date.isSameOrBefore(endDate.endOf('year'))) {
          const currentYear = date.year();
          const weekNumber = date.isoWeek(); // Obtener el número de la semana

          if(this.format) {
            weeksOfYear.push({
              name: `Sem. ${weekNumber}`,
              value: date.clone().isoWeekday(1).format('YYYY-MM-DD'),
            });
          } else {
            weeksOfYear.push({
              name: `Sem. ${weekNumber} - ${date.format('YYYY')}`,
              value: date.clone().isoWeekday(1).format('YYYY-MM-DD'),
            });
          }
          
          prevYear = currentYear;
          date.add(1, 'week');
        }
        weeksOfYear.shift();
        weeksOfYear.pop();
        this.items = weeksOfYear;
      }

      this.week$ = sharedService.sharedWeekBudgetSelected.subscribe((week: string) => {
        this.week = week;
      });
    }, 100);

    setTimeout(() => {
      this.ngxDisabled = false;
    }, 500);   
  }

  ngOnDestroy() {
    this.week$.unsubscribe();
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  changeWeek(event: any) {
    this.week = event;
    this.sharedService.nextWeekBudgetSelected = this.week;
    this.sharedService.nextPeriodBudgetSelected = 'week';

  }
}