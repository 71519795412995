import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-multiselect-product',
  templateUrl: './multiselect-product.component.html',
  styleUrls: ['./multiselect-product.component.scss']
})
export class MultiselectProductComponent implements OnInit {

  @Input() isDropdownDisabled = false;
  @Input() showChangeFx: boolean;
  @Input() showTotal: boolean;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas',
    unSelectAllText: 'Todas',
    itemsShowLimit: 1,
    allowSearchFilter: false,
    maxHeight: 140,
    noDataAvailablePlaceholderText: 'No hay datos disponibles'
  };
  url: string;
  constructor(
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (this.showChangeFx) {
      this.dropdownList = [
        { item_id: 'regular', item_text: 'Regular' },
        { item_id: 'premium', item_text: 'Premium' },
        { item_id: 'diesel', item_text: 'Diesel' },
        { item_id: 'cambio_fx', item_text: 'Tipo de Cambio' }
      ];
      this.selectedItems = [
        { item_id: 'regular', item_text: 'Regular' },
        { item_id: 'premium', item_text: 'Premium' },
        { item_id: 'diesel', item_text: 'Diesel' },
        { item_id: 'cambio_fx', item_text: 'Tipo de Cambio' }
      ];
    } else if (this.showTotal) {
      this.dropdownList = [
        { item_id: 'regular', item_text: 'Regular' },
        { item_id: 'premium', item_text: 'Premium' },
        { item_id: 'diesel', item_text: 'Diesel' },
        { item_id: 'total', item_text: 'Total' }
      ];
      this.selectedItems = [
        { item_id: 'regular', item_text: 'Regular' },
        { item_id: 'premium', item_text: 'Premium' },
        { item_id: 'diesel', item_text: 'Diesel' },
        { item_id: 'total', item_text: 'Total' }
      ];
    } else {
      this.dropdownList = [
        { item_id: 'regular', item_text: 'Regular' },
        { item_id: 'premium', item_text: 'Premium' },
        { item_id: 'diesel', item_text: 'Diesel' }
      ];
      this.selectedItems = [
        { item_id: 'regular', item_text: 'Regular' },
        { item_id: 'premium', item_text: 'Premium' },
        { item_id: 'diesel', item_text: 'Diesel' }
      ];
    }
  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'products[]=' + item.item_id + '&';
    });

    if (this.selectedItems.length === 0 && this.showChangeFx) {
      this.url = 'products[]=regular&products[]=premium&products[]=diesel&products[]=change_fx&';
      this.selectedItems = [];
    } else if (this.selectedItems.length === 0 && this.showTotal) {
      this.url = 'products[]=regular&products[]=premium&products[]=diesel&products[]=total&';
      this.selectedItems = [];
    } else if (this.selectedItems.length === 0 && !this.showChangeFx && !this.showTotal) {
      this.url = 'products[]=regular&products[]=premium&products[]=diesel&';
      this.selectedItems = [];
    }

    this.sharedService.nextComplementProducts = this.url;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      this.url = this.url + 'products[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementProducts = this.url;
  }

  anyItems() {
    this.selectedItems = [];
    if (this.showChangeFx) {
      this.url = 'products[]=regular&products[]=premium&products[]=diesel&products[]=change_fx&';
    } else if (this.showChangeFx) {
      this.url = 'products[]=regular&products[]=premium&products[]=diesel&products[]=total&';
    } else {
      this.url = 'products[]=regular&products[]=premium&products[]=diesel&';
    }
    this.sharedService.nextComplementProducts = this.url;
  }

}
