import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-select-brands',
  templateUrl: './select-brands.component.html',
  styleUrls: ['./select-brands.component.scss']
})
export class SelectBrandsComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean;
  @Input() competitor: number;
  idGroup;
  rol;
  public leftover = true;
  public items: any[] = [];
  public brandSelected: any = [];
  public ngxDisabled = true;
  public placeholder = 'Tus Estaciones';
  public markeTypeSelected: any = '';
  brandSelected$: Subscription;
  markeTypeSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.markeTypeSelected$ = sharedService.sharedMarkeType.subscribe((markeType: string) => {
      if (this.markeTypeSelected !== markeType) {
        this.markeTypeSelected = markeType;
        this.items = [];
        switch (this.competitor) {
          case 1:
            this.placeholder = 'Marca Comp 1';
            this.items = [
              { name: 'Marca Comp 1', value: null },
              { name: 'Resto', value: -1 }
            ];
            break;
          case 2:
            this.placeholder = 'Marca Comp 2';
            this.items = [
              { name: 'Marca Comp 2', value: null },
              { name: 'Resto', value: -1 }
            ];
            break;
          case 3:
            this.placeholder = 'Marca Comp 3';
            this.items = [
              { name: 'Marca Comp 3', value: null },
              { name: 'Resto', value: -1 }
            ];
            break;
          case 4:
            this.placeholder = 'Marca Comp 4';
            this.items = [
              { name: 'Marca Comp 4', value: null },
              { name: 'Resto', value: -1 }
            ];
            break;
          case 5:
            this.placeholder = 'Marca Comp 5';
            this.items = [
              { name: 'Marca Comp 5', value: null },
              { name: 'Resto', value: -1 }
            ];
            break;
          default:
            this.items = [
              { name: 'Tus Estaciones', value: null },
              { name: 'Resto', value: -1 }
            ];
            break;
        }
        this.getBrands();
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    switch (this.competitor) {
      case 1:
        this.placeholder = 'Marca Comp 1';
        this.items = [
          { name: 'Marca Comp 1', value: null },
          { name: 'Resto', value: -1 }
        ];
        break;
      case 2:
        this.placeholder = 'Marca Comp 2';
        this.items = [
          { name: 'Marca Comp 2', value: null },
          { name: 'Resto', value: -1 }
        ];
        break;
      case 3:
        this.placeholder = 'Marca Comp 3';
        this.items = [
          { name: 'Marca Comp 3', value: null },
          { name: 'Resto', value: -1 }
        ];
        break;
      case 4:
        this.placeholder = 'Marca Comp 4';
        this.items = [
          { name: 'Marca Comp 4', value: null },
          { name: 'Resto', value: -1 }
        ];
        break;
      case 5:
        this.placeholder = 'Marca Comp 5';
        this.items = [
          { name: 'Marca Comp 5', value: null },
          { name: 'Resto', value: -1 }
        ];
        break;
      default:
        this.items = [
          { name: 'Tus Estaciones', value: null },
          { name: 'Resto', value: -1 }
        ];
        break;
    }
    this.getBrands();
  }

  ngOnDestroy() {
    this.markeTypeSelected$.unsubscribe();
  }

  getBrands() {
    this.gasprecioService.getBrands(this.idGroup).subscribe((data: any) => {
      const brands: any = data;
      brands.forEach((element: any) => {
        element.name = this.gasprecioService.capitalizeFirstLetter(element.name);
        this.items.push({ name: element.name, value: element.id });
      });

      if (!this.disabled) {
        this.ngxDisabled = false;
      }
    });
  }

  changeBrand(event: any) {
    this.brandSelected = event;
    if (this.brandSelected === null) {
      this.brandSelected = '';
    }
    switch (this.competitor) {
      case 1:
        this.sharedService.nextBrandC1 = this.brandSelected;
        break;
      case 2:
        this.sharedService.nextBrandC2 = this.brandSelected;
        break;
      case 3:
        this.sharedService.nextBrandC3 = this.brandSelected;
        break;
      case 4:
        this.sharedService.nextBrandC4 = this.brandSelected;
        break;
      case 5:
        this.sharedService.nextBrandC5 = this.brandSelected;
        break;
      default:
        this.sharedService.nextBrand = this.brandSelected;
        break;
    }
  }
}
