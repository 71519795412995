<aside class="left-sidebar" [ngClass]="{'expand-sidebar': showText === true}" (mouseover)="openSidebar()"
    (mouseleave)="closeSidebar()">
    <div class="scroll-sidebar">
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/metricas'" (click)="clickPage('Metricas')"
                        aria-expanded="false" title="Métricas">
                        <i class="fa-regular fa-line-chart"></i>
                        <span *ngIf="showText">
                            Métricas
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/clientes'" (click)="clickPage('Clientes')"
                        aria-expanded="false">
                        <i class="fa-regular fa-users"></i>
                        <span *ngIf="showText">
                            Clientes
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/solicitudes'" (click)="clickPage('Solicitudes')"
                        aria-expanded="false">
                        <i class="fa-regular fa-rectangle-list"></i>
                        <span *ngIf="showText">
                            Solicitudes
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/premios'" (click)="clickPage('Inventario')"
                        aria-expanded="false">
                        <i class="fa-regular fa-cubes"></i>
                        <span *ngIf="showText">
                            Inventario
                        </span>
                    </a>
                </li>
                <li>
                    <a class="has-arrow waves-effect waves-dark" [routerLink]="'/gaspremia/Órdenes'" (click)="clickPage('Órdenes')"
                        aria-expanded="false">
                        <i class="fa-regular fa-truck-droplet"></i>
                        <span *ngIf="showText">
                            Órdenes
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</aside>