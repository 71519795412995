import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

interface Group {
  name: string;
  _id: string;
  default_zone: string;
}

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss']
})
export class SelectGroupComponent implements OnInit, OnDestroy {

  public items: { name: string, value: string, zone: string }[] = [];
  public idGroup: string;
  public ngxDisabled = true;
  private subscription: Subscription = new Subscription();

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getGroups();
    this.subscription.add(
      this.sharedService.sharedGroup.subscribe((idGroup: string) => {
        if (idGroup !== this.idGroup) {
          this.idGroup = idGroup;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getGroups() {
    this.gasprecioService.getGroups().subscribe((data: Group[]) => {
      this.items = data.map(group => ({
        name: group.name,
        value: group._id,
        zone: group.default_zone
      }));
      this.ngxDisabled = false;
    });
  }

  changeGroup(event: any) {
    const selectedGroup = this.items.find(item => item.value === event);
    if (selectedGroup) {
      this.idGroup = selectedGroup.value;
      this.sharedService.nextGroup = this.idGroup;
      this.sharedService.nextZone = selectedGroup.zone;
    }
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

}
