import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import * as moment from 'moment-timezone';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-year-budget',
  templateUrl: './select-year-budget.component.html',
  styleUrls: ['./select-year-budget.component.scss']
})
export class SelectYearBudgetComponent implements OnDestroy {

  @Input() format: any = false;
  public items: any[] = [];
  public year: any = [];
  year$: Subscription;
  public ngxDisabled = true;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    setTimeout(() => {
      const startDate = moment('2023-12-01');
      const endDate = moment().add(1, 'year');
      if (startDate < endDate) {
        const date = startDate.startOf('year');
        while (date <= endDate.endOf('year')) {
          if (this.format) {
            this.items.push({
              name: this.gasprecioService.capitalizeFirstLetter( 'Año ' + date.format('YYYY')),
              value: date.startOf('year').format('YYYY-MM-DD')
            });
          } else {
            this.items.push({
              name: this.gasprecioService.capitalizeFirstLetter(date.format('YYYY')),
              value: date.startOf('year').format('YYYY-MM-DD')
            });
          }

          date.add(1, 'year');
        }
      }

      this.year$ = sharedService.sharedYearBudgetSelected.subscribe((year: string) => {
        this.year = year;
      });
    }, 100);

    setTimeout(() => {
      this.ngxDisabled = false;
    }, 500);
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.year$.unsubscribe();
  }

  changeYear(event: any) {
    this.year = event;
    this.sharedService.nextYearBudgetSelected = this.year;
    this.sharedService.nextPeriodBudgetSelected = 'year';
  }
}
