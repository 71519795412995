import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-chart-sales-seasonally',
  templateUrl: './chart-sales-seasonally.component.html',
  styleUrls: ['./chart-sales-seasonally.component.scss']
})
export class ChartSalesSeasonallyComponent implements OnInit, OnDestroy {

  adjusted;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  sales: any;
  stationId$!: Subscription;
  stationId: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: 'Ventas - Venta Desestacionalizadas',
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        format: '{value:,.0f} lts.'
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: 'Ventas - Venta Desestacionalizadas',
      sourceWidth: 1200,
      sourceHeight: 800
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -16
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });
  }

  async ngOnInit() {
    this.getChartSalesSeasonally();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
  }

  getChartSalesSeasonally() {
    this.reqst = 1;
    this.stationId = localStorage.getItem('idStation');
    this.gasprecioService.getChartSalesSeasonally(this.stationId).subscribe({
      next: (data: any) => {
        this.sales = data['record'];
        this.adjusted = data['adjusted'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorLight = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);

    const lineChartData: any = [
      {
        name: 'Ventas',
        data: this.sales.map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        lineWidth: 2,
        color: color,
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b>';
          }
        }
      },
      {
        name: 'Venta Desestacionalizadas',
        data: this.adjusted.map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        lineWidth: 2,
        color: colorLight,
        dashStyle: 'ShortDot',
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b>';
          }
        }
      }
    ];

    const lineXaxisLegend = [];
    this.sales.forEach((v: any) =>
      lineXaxisLegend.push(this.gasprecioService.parseDateDM(v['date']))
    );

    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions['series'] = lineChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }


}
