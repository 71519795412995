import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import * as moment from 'moment-timezone';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';

@Component({
  selector: 'app-select-month',
  templateUrl: './select-month.component.html',
  styleUrls: ['./select-month.component.scss']
})
export class SelectMonthComponent implements OnInit {

  public items: any[] = [];
  public months: any = ['Ranking ' + this.gasprecioService.capitalizeFirstLetter(moment().format('MMM YY'))];

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {}

  ngOnInit(): void {
    const startDate = moment('2024-01-01');
    const endDate = moment();
    if (startDate < endDate) {
      const date = startDate.startOf('month');
      while (date <= endDate.endOf('month')) {
        this.items.push({
          name: 'Ranking ' + this.gasprecioService.capitalizeFirstLetter(date.format('MMM YY')),
          startDate: date.startOf('month').format('YYYY-MM-DD'),
          endDate: date.endOf('month').format('YYYY-MM-DD')
        });
        date.add(1, 'month');
      }
      this.items.reverse();
      this.months = ['Ranking ' + this.gasprecioService.capitalizeFirstLetter(moment().format('MMM YY'))];
    }
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  changeMonthsAgo(event: any) {
    this.months = event;
    this.items.map((e) => {
      if (e.name === event) {
        this.sharedService.nextDateStart = e.startDate;
        this.sharedService.nextDateEnd = e.endDate;
      }
    });
  }
}