import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-chart-sales-period',
  templateUrl: './chart-sales-period.component.html',
  styleUrls: ['./chart-sales-period.component.scss']
})
export class ChartSalesPeriodComponent implements OnInit, OnDestroy {

  @Input() period: string = '';
  interval = {
    regular: 0,
    premium: 0,
    diesel: 0
  };
  date: any;
  event$!: Subscription;
  idGroup: GroupIds | string = GroupIds.Pa;
  idGroup$!: Subscription;
  importants;
  importants$: Subscription;
  isMobile = false;
  max;
  min;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  rol;
  route;
  title;
  volumen;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Volumen de Venta`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Volumen de Venta',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:,.0f} lts.',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      column: {
        colorByPoint: true,
        animation: {
          duration: 1000
        },
        stacking: 'normal',
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          y: -20,
          verticalAlign: 'top',
          enabled: false,
          style: {
            color: 'black'
          },
          format: '{point.y:,.0f}'
        }
      }
    },
    exporting: {
      filename: `Volumen de Venta`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    const isMobileDevice = regexp.test(details);
    this.isMobile = isMobileDevice ;
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        if (this.reqst > 1) {
          this.getMaxSales();
        }
      }
    });

    this.importants$ = sharedService.sharedImportants.subscribe((importants: string) => {
      if (this.importants !== importants) {
        this.importants = importants;
        if (this.reqst > 1) {
          this.getMaxSales();
        }
      }
    });
  }

  ngOnInit() {
    this.getMaxSales();
    switch (this.period) {
      case 'day':
        this.title = 'Ult. 30 días';
        break;
      case 'week':
        this.title = 'Día Semana';
        break;
      case 'bi-week':
        this.title = 'Quincena';
        break;
      case 'month':
        this.title = 'Mes';
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.importants$.unsubscribe();
    this.event$.unsubscribe();
  }

  roundValues(value, round, type) {
    if (type === 'min') {
      const result = Math.round(value / round) * round;
      return result;
    } else {
      const result = Math.ceil(value / round) * round;
      return result;
    }
  }

  calculateInterval(product, round?) {
    if (round) {
      let range = this.max[product] - this.min[product];
      let interval = range / 8;
      this.interval[product] = this.roundValues(interval, round, 'max');
    } else {
      let range = this.max[product];
      let interval = range / 8;
      this.interval[product] = interval;
    }
  }

  getMaxSales() {
    this.reqst = 1;
    this.gasprecioService.getMaxSales().subscribe({
      next: (data: any) => {
        const info = data;
        if (this.period === 'week') {
          this.volumen = info['day_data'];
          this.max = info['max_day_fn'];
          this.min = info['min_day_fn'];
        } else if (this.period === 'bi-week') {
          this.volumen = info['fn_data'];
          this.max = info['max_day_fn'];
          this.min = info['min_day_fn'];
        } else if (this.period === 'month') {
          this.volumen = info['month_data'];
          this.max = info['max_daily_month'];
          this.min = info['min_daily_month'];
        } else if (this.period === 'day') {
          this.volumen = info['daily_data'];
          this.max = info['max_daily_month'];
          this.min = info['min_daily_month'];
        }

        if (this.period === 'week' || this.period === 'bi-week') {
          this.volumen.map((v: any) => {
            v['regular'] = v['regular'] && v['regular'] !== null ? v['regular'] : '0';
            v['premium'] = v['premium'] && v['premium'] !== null ? v['premium'] : '0';
            v['diesel'] = v['diesel'] && v['diesel'] !== null ? v['diesel'] : '0';
            switch (v['subset']) {
              case 'mon':
                v['date'] = 'Lunes';
                break;
              case 'tue':
                v['date'] = 'Martes';
                break;
              case 'wed':
                v['date'] = 'Miércoles';
                break;
              case 'thu':
                v['date'] = 'Jueves';
                break;
              case 'fri':
                v['date'] = 'Viernes';
                break;
              case 'sat':
                v['date'] = 'Sábado';
                break;
              case 'sun':
                v['date'] = 'Domingo';
                break;
              case 'fn':
                v['date'] = 'Quincena';
                break;
              case 'no_fn':
                v['date'] = 'Cotidiano';
                break;
              default:
                break;
            }
          })
        }
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorLight = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex(this.productType)]);
    this.max[this.productType] = this.roundValues(this.max[this.productType], 1000, 'max');
    this.min[this.productType] = this.roundValues(this.min[this.productType], 1000, 'min');
    this.calculateInterval(this.productType, 1000);
    let interval, maxRange, minRange;
    interval = this.interval[this.productType];
    maxRange = this.max[this.productType] + (this.interval[this.productType]);
    minRange = this.min[this.productType] - (this.interval[this.productType]);
    if(minRange < 0) {
      minRange = 0;
    }

    let volumenChartData: any;
    const lineXaxisLegendGenerator = {
      'day': (v: any) => this.gasprecioService.parseDateDM(v.date),
      'week': (v: any) => v.date,
      'bi-week': (v: any) => v.date,
      'month': (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    let colorsChart = [];
    if(this.period === 'month') {
      for (let i = 0; i < this.volumen.length; i++) {
        if (i < this.volumen.length - 6) {
          colorsChart.push(colorLight);
        } else {
          colorsChart.push(color);
        }
      }
    } else {
      colorsChart.push(color);
    }

    const colors = colorsChart;
    const lineXaxisLegend = this.volumen.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    let numberLabels = this.chartOptions['xAxis']['categories'].length;
    const borderW = numberLabels >= 90 ? 0 : 2;
    volumenChartData = [{
      name: 'Volumen de Venta',
      type: 'column',
      data: this.volumen.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      colors: colors,
      borderColor: colors,
      borderWidth: borderW,
      yAxis: 0,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b>';
        }
      }
    }];

    if (this.period !== 'month' && this.period !== 'day') {
      this.chartOptions['yAxis'][0]['max'] = maxRange;
      this.chartOptions['plotOptions']['series']['dataLabels']['enabled'] = true; 
      this.chartOptions['plotOptions']['column']['pointWidth'] = 40;
    }
    this.chartOptions['yAxis'][0]['min'] = this.period === 'month' || this.period === 'day' ? 0 : minRange;
    this.chartOptions.series = [...volumenChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }
}
