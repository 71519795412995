import { OnInit, OnDestroy, Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
HighchartsMore(Highcharts);

@Component({
  selector: 'app-chart-sales-quartil-comparative',
  templateUrl: './chart-sales-quartil-comparative.component.html',
  styleUrls: ['./chart-sales-quartil-comparative.component.scss']
})
export class ChartSalesQuartilComparativeComponent implements OnInit, OnDestroy {

  chartOptions;
  Highcharts = Highcharts;
  isRendering = false;
  max;
  median;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  showTendence = true;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType && !this.isRendering) {
        this.productType = productType;
        this.getChartSalesQuartilComparative();
      }
    });
  }

  ngOnInit(): void {
    if (!this.isRendering) {
      this.getChartSalesQuartilComparative();
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
  }

  getChartSalesQuartilComparative() {
    this.isRendering = true;
    this.reqst = 0;
    this.max = 0;
    this.gasprecioService.getChartSalesQuartilComparative(this.productType).subscribe({
      next: (data: any) => {
        let info_30 = data['graph_data_30'];
        let info_180 = data['graph_data_180'];
        let slope_30 = data['slope_30'];
        let slope_180 = data['slope_180'];
        if(this.productType === 'regular') {
          this.max = this.roundValues(data['max_sale'], 1000);
        } else {
          this.max = this.roundValues(data['max_sale'], 500);
        }
       
        const step = +((this.max - 0) / 10).toFixed(0);
        let serie_slope_30 = {
          name: 'Tendencia lineal de ventas (Últimos 30 días)',
          type: 'line',
          data: [],
          lineWidth: 2.5,
          color: 'red',
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              return ''
            }
          },
          showInLegend: true
        };

        let serie_slope_180 = {
          name: 'Tendencia lineal de ventas (Últimos 180 días)',
          type: 'line',
          data: [],
          lineWidth: 2.5,
          color: 'rgba(3, 155, 200, 1)',
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              return ''
            }
          },
          showInLegend: true
        };

        let box_30 = {
          name: 'Últimos 30 días',
          type: 'boxplot',
          data: [],
          tooltip: {
            headerFormat: '<em>Zona de mercado: {point.key}</em><br/><br/>',
            pointFormatter: function () {
              return '<span style="color:' +
                this.series.color + '">\u25CF</span> <b> ' +
                this.series.name + '</b><br/>' +
                'Mínimo: ' + (this.low.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                '25% Cuartil: ' + (this.q1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                'Mediana: ' + (this.median.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                '75% Cuartil: ' + (this.q3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                'Máximo: ' + (this.high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' 
            }
          },
          showInLegend: false
        };

        let box_180 = {
          name: 'Últimos 180 días',
          type: 'boxplot',
          data: [],
          fillColor: 'rgba(7, 36, 70, 1)',
          lineWidth: 1,
          color: 'black',
          medianWidth: 2,
          medianColor: 'white',
          tooltip: {
            headerFormat: '<em>Zona de mercado: {point.key}</em><br/><br/>',
            pointFormatter: function () {
              return '<span style="color:' +
                this.series.color + '">\u25CF</span> <b> ' +
                this.series.name + '</b><br/>' +
                'Mínimo: ' + (this.low.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                '25% Cuartil: ' + (this.q1.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                'Mediana: ' + (this.median.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                '75% Cuartil: ' + (this.q3.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>' +
                'Máximo: ' + (this.high.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' lts. <br/>'
            }
          },
          showInLegend: false
        };

        info_180['data'].map((element: any, index: number) => {
          switch (index) {
            case 0:
              box_180['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(229, 229, 229, 1)',
                medianColor: 'black'
              })
              break;
            case 1:
              box_180['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(114, 114, 114, .8)',
                medianColor: 'black'
              })

              break;
            case 2:
              box_180['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(0, 155, 211, .8)',
                medianColor: 'black'
              })
              break;
            case 3:
              box_180['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(130, 207, 255, .8)',
                medianColor: 'black'
              })
              break;
            default:
              break;
          }
        });
        info_30['data'].map((element: any, index: number) => {
          switch (index) {
            case 0:
              box_30['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(7, 36, 70, 1)',
                medianColor: 'white'
              })
              break;
            case 1:
              box_30['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(7, 36, 70, 1)',
                medianColor: 'white'
              })

              break;
            case 2:
              box_30['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(7, 36, 70, 1)',
                medianColor: 'white'
              })
              break;
            case 3:
              box_30['data'].push({
                low: element[0],
                q1: element[1],
                median: element[2],
                q3: element[3],
                high: element[4],
                fillColor: 'rgba(7, 36, 70, 1)',
                medianColor: 'white'
              })
              break;
            default:
              break;
          }
        });

        slope_30.map((element: any) => {
          serie_slope_30['data'].push(...element['values'])
        });

        slope_180.map((element: any) => {
          serie_slope_180['data'].push(...element['values'])
        });

        let dataSeries; 

        if(this.showTendence) {
          dataSeries = [
            box_30,
            box_180,
            serie_slope_30,
            serie_slope_180
          ]
        } else {
          dataSeries = [
            box_30,
            box_180
          ]
        }

        this.chartOptions = {
          credits: {
            enabled: false
          },
          chart: {
            type: 'boxplot',
            events: {
              load: function () {
                var chart = this;
                var points_180 = chart.series[0].points; 
                points_180.forEach(function (point_180, index) {
                  const median = point_180.median ? point_180.median.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
                  chart.renderer.text(median + ' lts', point_180.plotX + 108, point_180.medianPlot + 20)
                    .css({
                      color: 'white', // Establece el color del texto según la condición del índice
                      fontSize: '10px',
                      fontWeight: 'bold'
                    })
                    .attr({
                      zIndex: 5 // Ajusta el zIndex para asegurarte de que el texto esté encima de otros elementos
                    })
                    .add();
                });
                var points_30 = chart.series[1].points;
                points_30.forEach(function (point_30, index) {
                  const median = point_30.median ? point_30.median.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
                  let textColor = index === 0 || index === 3 ? 'black' : 'white'; // Color del texto dependiendo del índice
                  chart.renderer.text(median + ' lts', point_30.plotX + 16, point_30.medianPlot + 20) 
                    .css({
                      color: textColor,
                      fontSize: '10px',
                      fontWeight: 'bold'
                    })
                    .attr({
                      zIndex: 5 // Ajusta el zIndex para asegurarte de que el texto esté encima de otros elementos
                    })
                    .add();
                });
              }
            }
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Baja', 'Media Baja', 'Media Alta', 'Alta'],
            title: {
              text: ''
            }
          },
          yAxis: {
            title: {
              text: ''
            },
            labels: {
              formatter: function () {
                let value = this.value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return value + ' lts.'; // Agrega "litros" a los valores del eje Y
              }
            }
          },
          plotOptions: {
            boxplot: {
              lineWidth: 1,
              color: 'black',
              medianWidth: 1.5,
              pointWidth: 70,
              groupPadding: 1,
              whiskerLength: 50,
            },
            line: {
              marker: {
                enabled: false
              },
              dashStyle: '2 2',
              stickyTracking: false
            }
          },
          tooltip: {
            boxplot: {
              enabled: true
            },
            line: {
              enabled: false
            }
          },
          series: dataSeries
        };
      },
      complete: () => {
        this.isRendering = false;
        this.reqst = 1;
        setTimeout(() => {
          const chart = Highcharts.chart('container-highcharts', this.chartOptions);
        }, 10);
      }
    });
  }

  roundValues(value, round) {
    const result = Math.round(value / round) * round;
    return result;
  }
}