import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.css']
})
export class TableLoaderComponent {
  @Input() columns = 6;
  @Input() rows = 6;
  constructor() { }
}
