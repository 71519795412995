<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/images/login/img_login.svg)">
    <div class="login-box card" style="border-radius: 0 !important;">
        <div class="password-header">
            <h2>Restablece tu Contraseña</h2>
        </div>

        <div class="card-body center-card" *ngIf="success">
            <form class="form-horizontal form-material full" [formGroup]="recoverForm"  (ngSubmit)="onSubmit()">
                <a href="javascript:void(0)" class="text-center db">
                    <img style="margin-bottom: 10px"
                        src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/logo-v.png"
                        width="40%" alt="Home" />
                </a>

                <h2 class="welcome">Ingresa una Nueva Contraseña</h2>
                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <input class="form-control" [type]="showPassword ? 'text' : 'password'" name="password"
                            placeholder="Nueva Contraseña" formControlName="password">
                        <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" (click)="togglePasswordVisibility()"
                                style="position: absolute; right: 10px; top: 10px; cursor: pointer;"></i>
                        <span class="text-danger" *ngIf="submitted && f.password.errors?.required">La contraseña es
                            requerida.</span>
                        <span class="text-danger" *ngIf="submitted && f.password.errors?.minlength">La contraseña debe
                            tener al menos 8 caracteres.</span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <input class="form-control" id="pwd" [type]="showPassword2 ? 'text' : 'password'"
                            name="confirm_password" placeholder="Confirmar Nueva Contraseña"
                            formControlName="confirm_password">
                            <i class="fa" [ngClass]="showPassword2 ? 'fa-eye-slash' : 'fa-eye'" (click)="togglePassword2Visibility()"
                                style="position: absolute; right: 10px; top: 10px; cursor: pointer;"></i>
                        <span class="text-danger" *ngIf="submitted && f.confirm_password.errors?.required">La
                            confirmación de contraseña es requerida.</span>
                        <span class="text-danger" *ngIf="submitted && f.confirm_password.errors?.mustMatch">Las
                            contraseñas no coinciden.</span>

                            <div style="font-size: 12px;color: gray;margin-top: 10px;">
                                Nota: La contraseña debe tener al menos 8 caracteres.
                            </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4 offset-md-4">
                        <button class="btn btn-info btn-red btn-lg btn-block btn-rounded" color="primary" type="submit"
                            value="submit" [disabled]="!recoverForm.valid">
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="password-footer">
            <h2>hola@gaspremia.com</h2>
        </div>
    </div>
</section>