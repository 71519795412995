import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-pillar',
  templateUrl: './select-pillar.component.html',
  styleUrls: ['./select-pillar.component.scss']
})
export class SelectPillarComponent implements OnInit, OnDestroy {

  @Input() holy: any = false;
  public items: any[] = [
    { name: 'Total', value: null },
    { name: 'Pilares', value: 1 },
    { name: 'Resto', value: 0 }
  ];

  public importants: any = [];
  importants$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.importants$ = this.sharedService.sharedImportants.subscribe(importants => {
      this.importants = importants;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit() {
    if (this.holy) {
      this.items = [
        { name: 'Total', value: null },
        { name: 'Pilares', value: 1 },
        { name: 'Resto', value: 0 },
        { name: 'Vacas Sagradas', value: 2 },
        { name: 'Resto Vacas Sag.', value: 3 }
      ];
    } else {
      this.items = [
        { name: 'Total', value: null },
        { name: 'Pilares', value: 1 },
        { name: 'Resto', value: 0 }
      ];
    }
  }

  ngOnDestroy() {
    this.importants$.unsubscribe();
  }

  changeImportants(event: any) {
    this.importants = event;
    this.sharedService.nextImportants = this.importants;
  }
}
