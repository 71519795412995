import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-statistics-trade-tracking',
  templateUrl: './select-statistics-trade-tracking.component.html',
  styleUrls: ['./select-statistics-trade-tracking.component.scss']
})
export class SelectStatisticsTradeTrackingComponent implements  OnDestroy {

  public items: any[] = [
    { name: 'Prom Diario', value: 'avg' },
    { name: 'Suma Total', value: 'accum' }
  ];

  public statisticTradeTrackingSelected: any = [];
  public ngxDisabled = true;
  statisticTradeTrackingSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.statisticTradeTrackingSelected$ = sharedService.sharedStatisticTradeTracking.subscribe((statisticTradeTrackingSelected: string) => {
      if (this.statisticTradeTrackingSelected !== statisticTradeTrackingSelected) {
        this.statisticTradeTrackingSelected = statisticTradeTrackingSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.statisticTradeTrackingSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.statisticTradeTrackingSelected = event;
    this.sharedService.nextStatisticTradeTracking = this.statisticTradeTrackingSelected;
  }

}
