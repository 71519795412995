import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    public token = '';
    public tokenPrices = '';

    constructor(
        private authenticationService: AuthService,
        private router: Router
    ) {
        this.token = localStorage.getItem('token') ? localStorage.getItem('token') : this.authenticationService.token;
        if (!this.router.url.includes('precios')) {
            if (this.token === null || this.token === undefined) {
                localStorage.clear();
                this.router.navigateByUrl('/login');
            }
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        if (request.headers.get('skip')) {
            request = request.clone({
                headers: request.headers.delete('skip', 'skip')
            });
            return next.handle(request).pipe(
                catchError(this.showError)
            );
        }

        if (request.url.includes('/login')) {
            return next.handle(request);
        }

        const currentUser: any = this.authenticationService.currentUserValue;

        if (currentUser) {
            const token = currentUser['user'] ? currentUser['user'].token : currentUser['token'];
            if (this.token === null) {
                this.token = token;
            }
        }

        if (currentUser && this.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.token}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError(this.showError)
        );
    }

    showError() {
        return throwError('Error en la petición');
    }

}
