import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-margin-type',
  templateUrl: './select-margin-type.component.html',
  styleUrls: ['./select-margin-type.component.scss']
})
export class SelectMarginTypeComponent implements OnInit, OnDestroy {

  @Input() burgos: any = false;
  @Input() section: any = false;

  public items: any[] = [
    { name: 'Margen TAR', value: '0' },
    { name: 'Margen Desc.', value: '1' }
  ];

  public marginType: any = [];
  public ngxDisabled = false;
  marginType$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.marginType$ = this.sharedService.sharedMarginType.subscribe(marginType => {
      this.marginType = marginType;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    if (this.burgos) {
      this.items = [
        { name: 'Margen TAR', value: '0' },
        { name: 'Margen Desc.', value: '1' },
        { name: 'Margen Exp.', value: '2' }
      ];
    } else {
      this.items = [
        { name: 'Margen TAR', value: '0' },
        { name: 'Margen Desc.', value: '1' }
      ];
    }
  }


  ngOnDestroy() {
    this.marginType$.unsubscribe();
  }

  changeMarginType(event: any) {
    this.marginType = event;
    this.sharedService.nextMarginType = this.marginType;
  }
}
