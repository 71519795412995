import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-campaign',
  templateUrl: './select-campaign.component.html',
  styleUrls: ['./select-campaign.component.scss']
})
export class SelectCampaignComponent implements OnDestroy {

  @Input() page;
  public items: any[] = [];
  public campaign: any = [];
  campaign$: Subscription;
  idStation;
  ngxDisabled = true;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.idStation = localStorage.getItem('idStation');
    this.campaign$ = this.sharedService.sharedCampaign.subscribe(campaign => {
      this.campaign = campaign;
    });

    this.loadClusters();
  }

  ngOnInit() {
    if (this.page === 'last') {
      this.items = [{ name: 'Últ. Promoción', value: null }];
    }
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  loadClusters() {
    this.items = [{ name: 'Todas', value: null }];
    this.gasprecioService.getCampaigns(this.idStation)
      .subscribe({
        next: (data: any) => {
          this.items.push(...data.map((campaign) => ({
            name: campaign['identifier'] + '.- ' + campaign['name'],
            value: campaign['_id']
          })));
        },
        complete: () => {
          this.ngxDisabled = false;
        }
      });
  }

  ngOnDestroy() {
    this.campaign$.unsubscribe();
  }

  changeCampaign(event: any) {
    this.campaign = event;
    this.sharedService.nextCampaign = this.campaign;
  }
}
