import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import { ClusterType } from 'src/app/modules/gasprecio/enums/cluster-type.enum';

@Component({
  selector: 'app-chart-sale-ppc',
  templateUrl: './chart-sale-ppc.component.html',
  styleUrls: ['./chart-sale-ppc.component.scss']
})
export class ChartSalePpcComponent implements OnInit, OnDestroy {

  @ViewChild('chartModalEvol') chartModalEvol: TemplateRef<any>;
  @Input() section: any = false;
  @Input() station: any = false;
  changeModal;
  changeModal$!: Subscription;
  dataEvol;
  date: any;
  days = 30;
  event$!: Subscription;
  idGroup: GroupIds | string = GroupIds.Pa;
  importants;
  maxSellingPrice: any;
  maxSellingPrice3: any;
  minSellingPrice: any;
  minSellingPrice3: any;
  loading = false;
  months = 12;
  nameStation;
  period = 'day';
  ppc: any;
  private destroy$ = new Subject<void>();
  productType = 'regular';
  reqst = 0;
  reqstEvol = 0;
  rol;
  route;
  selectedCluster;
  selectedCluster2;
  selectedCluster3;
  sellingPrice;
  showPriceComp = false;
  stationId: any;
  weeks = 12;
  zoneSelected = null;
  chartEvolOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Histórico - Evolución de Precios`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: {
      title: {
        text: 'Precio de Venta',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        format: '$ {value:.2f}',
        style: {
          fontSize: '12px'
        }
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      formatter: function () {
        var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
        var currentPoint = this;
        var addedStations = [];
        this.series.chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
              tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>$ ' + point.y.toFixed(2) + '</b><br/>';
            }
          });
        });
        return tooltip;
      }
      // pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 2
      }
    },
    exporting: {
      filename: `Histórico - Evolución de Precios`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -6
      }
    },
    series: []
  };

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Precio de Venta - Precio de Competencia`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Precio de Venta',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Precio de Competencia',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '12px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
      pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 2
      }
    },
    exporting: {
      filename: `Precio de Venta - Precio de Competencia`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.stationId = localStorage.getItem('idStation');
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedProductTypeTotal,
      this.sharedService.sharedStationId,
      this.sharedService.sharedChangeModal,
      this.sharedService.sharedGroup,
      this.sharedService.sharedZoneDashboard,
      this.sharedService.sharedPeriodDashboard,
      this.sharedService.sharedDaysDashboard,
      this.sharedService.sharedWeeksAgo,
      this.sharedService.sharedMonthsAgo,
      this.sharedService.sharedImportants,
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster1),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster2),
      this.sharedService.getSharedSelectedClusters(ClusterType.Cluster3)
    ]).pipe(takeUntil(this.destroy$)).subscribe(([productType, stationId, changeModal, idGroup, zone, period, days, weeks, months, importants, selectedCluster, selectedCluster2, selectedCluster3]) => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (!this.loading) {
          this.setData();
        }
      }

      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }

      this.changeModal = changeModal;

      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.zoneSelected = '';
      }

      if (zone !== this.zoneSelected) {
        this.zoneSelected = zone === 'Total' ? null : zone;
      }

      if (period !== this.period) {
        this.period = period;
      }

      if (days !== this.days) {
        this.days = days;
      }

      if (weeks !== this.weeks) {
        this.weeks = weeks;
      }

      if (months !== this.months) {
        this.months = months;
      }

      if (this.importants !== importants) {
        this.importants = importants;
      }

      if (selectedCluster !== this.selectedCluster) {
        this.selectedCluster = selectedCluster;
      }

      if (selectedCluster2 !== this.selectedCluster2) {
        this.selectedCluster2 = selectedCluster2;
      }

      if (selectedCluster3 !== this.selectedCluster3) {
        this.selectedCluster3 = selectedCluster3;
      }

      if (!this.loading) {
        this.getDashboardSellingPricePpc();
      }
    });
  }

  ngOnInit() {
    // this.getDashboardSellingPricePpc();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDashboardSellingPricePpc() {
    this.loading = true;
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    setTimeout(() => {
      const url = this.station ?
        this.gasprecioService.getDashboardSellingPricePpcStation(this.period, typeValue, n) :
        this.gasprecioService.getDashboardSellingPricePpc(this.idGroup, this.zoneSelected, this.period, this.importants, typeValue, n, this.selectedCluster, this.selectedCluster2, this.selectedCluster3);
      url.subscribe({
        next: (data: any) => {
          const info = data;
          this.ppc = info['ppc'];
          this.sellingPrice = info['selling_price'];
          this.minSellingPrice = data['min_selling_price'];
          this.maxSellingPrice = data['max_selling_price'];
          this.minSellingPrice3 = data['min3_selling_price'];
          this.maxSellingPrice3 = data['max3_selling_price'];
          this.setData();
        }, complete: () => {
          this.reqst = 2;
        }, error: () => {
          this.loading = false;
          this.reqst = 3;
        }
      });
    }, 400);
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorOrange = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[4]);
    const colorMin = this.gasprecioService.getColorString(this.gasprecioService.colorsMinMax[0]);
    const colorMax = this.gasprecioService.getColorString(this.gasprecioService.colorsMinMax[1]);

    const sellingPriceChartData: any = {
      name: 'Precio de Venta',
      data: this.sellingPrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: color,
      lineWidth: 2,
      marker: { radius: 2 }
    };

    const competitorsPriceChartData: any = {
      name: 'Precio de Competencia',
      data: this.ppc.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorOrange,
      lineWidth: 2
    };

    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const minPriceChartData: any = {
      name: 'Precio Mín Comp',
      data: this.minSellingPrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      dashStyle: 'ShortDot',
      color: colorMin,
      lineWidth: 2
    };

    const maxPriceChartData: any = {
      name: 'Precio Máx Comp',
      data: this.maxSellingPrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      dashStyle: 'ShortDot',
      color: colorMax,
      lineWidth: 2
    };

    const lineXaxisLegend = this.ppc.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions.series = this.showPriceComp ? [sellingPriceChartData, competitorsPriceChartData, minPriceChartData, maxPriceChartData] : [sellingPriceChartData, competitorsPriceChartData];
    setTimeout(() => {
      this.loading = false;
      this.reqst = 2;
    }, 100);
  }

  openModalChartEvol() {
    this.modalService.open(this.chartModalEvol, { windowClass: 'chartModal', centered: true });
  }

  closeModalChartEvol() {
    this.modalService.dismissAll(this.chartModalEvol);
  }

  getEvolution() {
    this.reqstEvol = 1;
    this.stationId = this.section ? localStorage.getItem('idStation') : this.stationId;
    if (!this.stationId) return;
    this.gasprecioService.getEvolPriceHistory(this.stationId).subscribe({
      next: (res) => {
        this.dataEvol = res;
        this.nameStation = this.dataEvol['client']['name'];
        this.setDataEvol();
      }, complete: () => {
        this.reqstEvol = 2;
      }, error: () => {
        this.reqstEvol = 3;
      }
    });
  }

  setDataEvol() {
    this.reqst = 1;
    const lineChartData: any = [
      {
        name: this.rol !== 'Gaspre' ? this.dataEvol['client']['name'] + ' (' + this.dataEvol['client']['brand'] + ')' : 'Tu Estación ' + ' (' + this.dataEvol['client']['brand'] + ')',
        data: this.dataEvol['client']['values'].map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        color: 'rgb(212, 175, 55)',
        lineWidth: 1.5,
        marker: {
          radius: 4
        }
      }
    ]

    const lineXaxisLegend: any = [];
    this.dataEvol['client']['values'].map((v: any) =>
      lineXaxisLegend.push(this.gasprecioService.parseDateDM(v['date']))
    );

    this.dataEvol['competitors'].forEach((c, index) => {
      let color = `rgb(${this.gasprecioService.colors[index + 1].r}, ${this.gasprecioService.colors[index + 1].g}, ${this.gasprecioService.colors[index + 1].b})`;
      lineChartData.push({
        name: this.rol !== 'Gaspre' ? c['name'] + ' (' + c['brand'] + ')' : 'Competitor ' + (index + 1) + ' (' + c['brand'] + ')',
        data: c['values'].filter((type: any) => type !== null).map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        color: color
      });
    })

    this.chartEvolOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartEvolOptions['series'] = lineChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas4: any = document.getElementById('mychart4');
    const imgData4 = canvas4.toDataURL('image/png');
    this.sharedService.nextImgData4 = imgData4;
  }
}
