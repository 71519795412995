import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-select-market-zone',
  templateUrl: './select-market-zone.component.html',
  styleUrls: ['./select-market-zone.component.scss']
})
export class SelectMarketZoneComponent implements OnInit, OnDestroy {

  public items: any[] = [{ value: '', name: 'Zona' }];
  public idGroup: any;
  public zoneMarket: any = [];
  public ngxDisabled = true;
  idGroup$: Subscription;
  sharedZone$: Subscription;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getZones();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
  }

  getZones() {
    this.gasprecioService.getZonesIM(this.idGroup).subscribe((data: any) => {
      const zones: any = data;
      zones.forEach((element: any) => {
        if (element._id !== '6178671b46818a57510e921b' && element._id !== '6178671b46818a57510e921a') {
          this.items.push({ name: this.gasprecioService.capitalizeFirstLetter(element['name']), value: element['_id'], entity: element['entidad_id'] });
        }
      });
      this.ngxDisabled = false;
    });
  }

  changeMarket(event: any) {
    this.zoneMarket = event;
    this.items.map((element) => {
      if (element['value'] === event) {
        this.sharedService.nextEntity = element['entity'];
      }
    });
    this.sharedService.nextTown = 'Municipio';
    this.sharedService.nextZoneMarket = this.zoneMarket;
    this.items.map((zone: any) => {
      if (zone.value === this.zoneMarket) {
        this.sharedService.nextZoneMarketText = zone.name;
      }
    });
  }
}
