<div class="header-c">
    Dif Precio vs Competencia
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Promedio diario de la diferencia de precio de venta por estación vs su competencia.
    </epy-tooltip>
    - Indice de Precios
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es < 1.0 significa que tu
            precio es menor, mientras que si es > 1.0 tu precio es más alto que el de tus competidores.
    </epy-tooltip> 
    <!-- <input type="checkbox" id="checkbox-limit" [(ngModel)]="showDiff" (ngModelChange)="setData()" *ngIf="showRendichicas"> <label
        style="font-size: 13px;margin-bottom: 0;margin-left: .2%;" for="checkbox-limit" *ngIf="showRendichicas">Limites Indice</label> -->
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="reqst===2">
    <div>
        <highcharts-chart id="mychart5" [Highcharts]="Highcharts" [options]="chartOptions" [ngStyle]="{ 'height': station || period !== 'month' && !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) ? 'auto': '310px'}" style="width: 100%;display: block;"></highcharts-chart>
    </div>
</div>
