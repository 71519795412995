<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/images/login/img_login.svg)">
    <div class="login-box card" style="border-radius: 0 !important;">
        <div class="card-body center-card">
            <form class="form-horizontal form-material full" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <a href="javascript:void(0)" class="text-center db">
                    <img style="margin-bottom: 10px"
                        src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/logo-v.png"
                        width="40%" alt="Home" />
                </a>

                <h2 class="welcome">Bienvenido</h2>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <label for="email" style="display: none;"></label>
                        <input class="form-control" id="email" type="text" name="email" placeholder="Correo Electrónico"
                            formControlName="email" #email>
                        <span class="text-danger" *ngIf="submitted && f.email.errors">
                            El correo electrónico que ingresaste no coincide con ninguna cuenta.
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3 position-relative">
                        <label for="pwd" style="display: none;"></label>
                        <input class="form-control" id="pwd" [type]="showPassword ? 'text' : 'password'" name="pwd"
                            placeholder="Contraseña" formControlName="password" #password>
                        <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                            (click)="togglePasswordVisibility()" style="position: absolute; right: 10px; top: 10px; cursor: pointer;"></i>
                        <span class="text-danger" *ngIf="submitted && f.password.errors">
                            La contraseña que ingresaste es incorrecta.
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 offset-md-3">
                        <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right font-italic"
                            (click)="recoverPassword()">
                            <i class="fa-regular fa-lock m-r-5"></i>¿Olvidaste tu contraseña?
                        </a>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4 offset-md-4">
                        <button class="btn btn-info btn-red btn-lg btn-block btn-rounded" color="primary" type="submit"
                            value="submit" [disabled]="!loginForm.valid">
                            Iniciar Sesión
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</section>