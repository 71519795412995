import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { CookieService } from "ngx-cookie-service";
import { environment } from '../../enviroments/enviroments';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    // private endpoint1 = environment.apiUrl + '/v1';
    private endpoint1 = environment.apiUrl + '/v1';
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public token: any;
    public idStation: any;
    public creId: any;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();
        this.idStation = localStorage.getItem('idStation');
        this.token = localStorage.getItem('token');
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {

        const data = { 'email': email, 'password': password };

        return this.http.post<any>(`${this.endpoint1}/crm/users/login`, data);
    }

    loginPrices(user: string, password: string) {

        const data = { 'username': user, 'password': password.toLowerCase() };

        return this.http.post(`${this.endpoint1}/prices/users/login`, data)
            .pipe(
                map((x: any) => { return x['username'] }),
                shareReplay(1),
                catchError(this.processError)
            );
    }

    setToken(token) {
        this.cookieService.set("tokenPrices", token);
    }

    getToken() {
        return this.cookieService.get("tokenPrices");
    }

    setStation(station) {
        this.cookieService.set("idStationPrices", station);
    }

    getStation() {
        return this.cookieService.get("idStationPrices");
    }

    signup(name: string, email: string, cre: string) {

        const data = { 'name': name, 'email': email, 'cre': cre };

        return this.http.post<any>(`${this.endpoint1}/crm/users/signup`, data)
            .pipe(map(user => {
                // console.log('entro ', user);
            })
            );
    }

    logout() {
        localStorage.clear();
        this.currentUserSubject.next(null);
    }

    logoutPrices() {
        localStorage.removeItem('idStationPrices');
        localStorage.removeItem('tokenPrices');
        this.cookieService.delete("tokenPrices");
        this.cookieService.delete("idStationPrices");
        this.router.navigateByUrl('precios/login');
    }

    sendRecoverPassword(email: any) {

        const data = { 'email': email };

        return new Promise(resolve => {
            this.http.post(`${this.endpoint1}/crm/users/auth/forgot_password`, data)
                .subscribe(async resp => {
                    resolve(true);
                });
        });
    }

    setPassword(password: any, token: any) {

        password.token = token;

        return new Promise(resolve => {
            this.http.put<any>(`${this.endpoint1}/crm/users/password`, password)
                .subscribe(user => {
                    resolve(true);
                });
        });
    }

    verifyAccount(token: any) {

        const data = '';

        return new Promise(() => {
            this.http.put<any>(`${this.endpoint1}/app/users/verify_account`, data, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).subscribe(async resp => {
                // console.log(resp);
                return resp;
            });
        });
    }

    processError(err: any) {
        let message = '';
        if (err.error instanceof ErrorEvent) {
            message = err.error.message;
        } else {
            message = `Error Code: ${err.status}\nMessage: ${err.message}`;
        }
        return throwError(() => {
            message;
        });
    }

    showLoader(title: string, text: string) {
        Swal.fire({
            title: title,
            text: text,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
    }

}
