import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-select-region-evolution',
  templateUrl: './select-region-evolution.component.html',
  styleUrls: ['./select-region-evolution.component.scss']
})
export class SelectRegionEvolutionComponent implements OnInit, OnDestroy {

  public items: any[] = [
    { name: 'Región', value: null }
  ];

  public regionSelected: any = [];
  public ngxDisabled = true;
  regionSelected$: Subscription;
  public idGroup: any;
  idGroup$: Subscription;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }
    });

    this.regionSelected$ = sharedService.sharedRegion.subscribe((regionSelected: string) => {
      if (this.regionSelected !== regionSelected) {
        this.regionSelected = regionSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getRegions();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
    this.regionSelected$.unsubscribe();
  }

  getRegions() {
    this.gasprecioService.getRegions(this.idGroup).subscribe((data: any) => {
      const towns: any = data;
      towns.forEach((element: any) => {
        element.name = this.gasprecioService.capitalizeFirstLetter(element.name);
        this.items.push({ name: element.name, value: element._id });
      });
    });
  }

  changeVariable(event: any) {
    this.regionSelected = event;
    this.sharedService.nextRegion = this.regionSelected;
  }

}
