import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-dashboard-period',
  templateUrl: './select-dashboard-period.component.html',
  styleUrls: ['./select-dashboard-period.component.scss']
})
export class SelectDashboardPeriodComponent implements OnInit, OnDestroy {

  @Input() rch: boolean;
  public items: any[] = [
    { name: 'Diario', value: 'day' },
    { name: 'Semanal', value: 'week' },
    { name: 'Mensual', value: 'month' }
  ];
  public period: any = [];
  public ngxDisabled = false;
  period$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      this.period = period;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    if (this.rch) {
      this.items = [
        { name: 'Diario', value: 'day' },
        { name: 'Mensual', value: 'month' }
      ];
    } else {
      this.items = [
        { name: 'Diario', value: 'day' },
        { name: 'Semanal', value: 'week' },
        { name: 'Mensual', value: 'month' }
      ];
    }
  }

  ngOnDestroy() {
    this.period$.unsubscribe();
  }

  changePeriod(event: any) {
    this.period = event;
    this.sharedService.nextPeriodDashboard = this.period;
  }
}
