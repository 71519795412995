import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-days-px-optimum',
  templateUrl: './select-days-px-optimum.component.html',
  styleUrls: ['./select-days-px-optimum.component.scss']
})
export class SelectDaysPxOptimumComponent implements  OnInit, OnDestroy {

  @Input() editable: any = false;
  public items: any[] = [];
  public daysAgo: any = [];
  public ngxDisabled = true;
  daysAgo$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.daysAgo$ = this.sharedService.sharedDaysOptimumPx.subscribe(daysAgo => {
      this.daysAgo = daysAgo;
    });
  }

  ngOnInit(): void {
    if (this.editable) {
      this.items = [
        { value: 90, name: 'Últ. 90 días' },
        { value: 180, name: 'Últ. 180 días' },
        { value: 360, name: 'Últ. 360 días' }
      ];
      this.ngxDisabled = false;
    } else {
      this.items = [
        { value: 90, name: 'Últ. 180 días' },
        { value: 180, name: 'Últ. 180 días' },
        { value: 360, name: 'Últ. 360 días' }
      ];
    }
   }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.daysAgo$.unsubscribe();
  }

  changeDaysAgo(event: any) {
    this.daysAgo = event;
    this.sharedService.nextDaysOptimumPx = this.daysAgo;
    this.sharedService.nextDaysOptimumPrice = this.daysAgo;
  }
}
