<ng-container *ngIf="!ngxDisabled">
    <div class="budget" *ngIf="format">
        <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name" [(ngModel)]="month"
            appendTo="body" placeholder="Seleccionar Mes" (select)="changeMonthsAgo($event)" [disabled]="ngxDisabled" dropDownMenuOtherClasses="monitor">
            <ng-template ngx-select-option-not-found let-input>
                "{{input}}" no encontrado
            </ng-template>
        </ngx-select>
    </div>

    <div *ngIf="!format">
        <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name" [(ngModel)]="month"
            appendTo="body" placeholder="Seleccionar Mes" (select)="changeMonthsAgo($event)" [disabled]="ngxDisabled"
            dropDownMenuOtherClasses="monitor">
            <ng-template ngx-select-option-not-found let-input>
                "{{input}}" no encontrado
            </ng-template>
        </ngx-select>
    </div>
</ng-container>
