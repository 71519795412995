import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import * as moment from 'moment-timezone';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';

@Component({
  selector: 'app-select-month-heat-map',
  templateUrl: './select-month-heat-map.component.html',
  styleUrls: ['./select-month-heat-map.component.scss']
})
export class SelectMonthHeatMapComponent implements OnInit {

  public items: any[] = [];
  public months: any = [this.gasprecioService.capitalizeFirstLetter(moment('2024-09-01').format('MMM YY'))];

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) { }

  ngOnInit(): void {
    const startDate = moment('2024-01-01');
    const endDate = moment('2024-09-01');
    if(startDate <endDate) {
      const date = startDate.startOf('month');
      while (date <= endDate.endOf('month')) {
        this.items.push({
          name: this.gasprecioService.capitalizeFirstLetter(date.format('MMMM YY')),
          startDate: date.startOf('month').format('YYYY-MM-DD'),
          endDate: date.endOf('month').format('YYYY-MM-DD')
        });
        date.add(1, 'month');
      }
      this.items.reverse();
      this.months = [this.gasprecioService.capitalizeFirstLetter(endDate.format('MMMM YY'))];
    }
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  changeMonthsAgo(event: any) {
    this.months = event;
    this.items.map((e) => {
      if (e.name === event) {
        this.sharedService.nextSelectedMonth = e.startDate;
      }
    });
  }
}
