<ngx-datatable *ngIf="reqst === 1" class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50"
    [rowHeight]="getRowHeight" [rows]="temp" [scrollbarV]="true" [scrollbarH]="true"
    [selected]="selected" [selectionType]="SelectionType.multiClick" style="height: auto;margin-top: 16px;" [style.min-height] = "heightTable + 'px'"
    [messages]="{emptyMessage: 'No se ha encontrado ninguna estación con tu búsqueda, por favor intenta con otro parámetro.'}">

    <ngx-datatable-column prop="zone" [width]="110" [frozenLeft]="true" [sortable]="false"
        [cellClass]="'left-side green-blue'" [headerClass]="'left-side top-side green-blue'">
        <ng-template ngx-datatable-header-template>
            <span>  </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="title-l">
                <span style="margin-left: 8px;">
                    {{row.zone}}
                </span>
            </div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="70" *ngFor="let column of columns; let i = index;" name="{{column.name}}" [sortable]="false"
        prop="{{'day_' + column.index}}" [headerClass]="'top-side'">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row['day_' + i]}}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<div class="row" style="margin:10px;" *ngIf="reqst === 0">
    <app-table-loader style="width: 100%;"></app-table-loader>
</div>