<div>
    <ngx-select [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
        [disabled]="ngxDisabled" [autoClearSearch]="false" [(ngModel)]="concoDiscount" placeholder="Nivel Conco"
        (select)="changeConcoDiscount($event)">

        <ng-template ngx-select-option-not-found let-input>
            "{{input}}" no encontrado
        </ng-template>

    </ngx-select>
</div>