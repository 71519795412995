import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import { SelectionType } from '@swimlane/ngx-datatable';
import moment from 'moment';

@Component({
  selector: 'app-profitability-comparison',
  templateUrl: './profitability-comparison.component.html',
  styleUrls: ['./profitability-comparison.component.scss']
})
export class ProfitabilityComparisonComponent implements OnInit, OnDestroy {

  SelectionType = SelectionType;
  currentStation: any;
  selected: any[] = [];
  data: any;
  days = 30;
  columns = [];
  headerTable = [];
  id;
  idGroup: GroupIds | string = GroupIds.Pa;
  idStation;
  importants;
  months = 12;
  period = 'day';
  profitabilityPercent = 15;
  private destroy$ = new Subject<void>();
  productType = 'regular';
  productType$!: Subscription;
  temp: any = [];
  reqst = 0;
  rows: any;
  rol;
  loading = false;
  variableValue: any = 'selling_price';
  weeks = 12;
  zoneSelected = null;
  val: any;
  heightTable = 0;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Comparativo Zona`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
    },
    yAxis: {
      title: {
        text: 'Precio de Venta',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        format: '$ {value:.2f}'
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      formatter: function () {
        var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
        var currentPoint = this;
        var addedStations = [];
        this.series.chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
              tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>$ ' + point.y.toFixed(2) + '</b><br/>';
            }
          });
        });
        return tooltip;
      }
      // pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: `Comparativo Zona`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -6
      }
    },
    series: []
  };

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedProductTypeTotal,
      this.sharedService.sharedVariableProfitability,
      this.sharedService.sharedProfitabilityPercent
    ]).pipe(takeUntil(this.destroy$)).subscribe(([
      idGroup, productType, variableValue, profitabilityPercent
    ]) => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
      }

      if (productType !== this.productType) {
        this.productType = productType;
      }

      if (variableValue !== this.variableValue) {
        this.variableValue = variableValue;
      }

      if (profitabilityPercent !== this.profitabilityPercent) {
        this.profitabilityPercent = profitabilityPercent;
      }

      if (!this.loading) {
        this.getProfitabilityComparison();
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCellClass({ row, column }): any {
    return {
      'bg-ligthb': row['flag'] === 'top' || row['cre_id'] === 'Top 5' || row['cre_id'] === 'Top 10' || row['cre_id'] === 'Top 15' || row['cre_id'] === 'Top 20' || row['cre_id'] === 'Top 25',
      'bg-ligthred': row['flag'] === 'bottom' || row['cre_id'] === 'Bottom 5' || row['cre_id'] === 'Bottom 10' || row['cre_id'] === 'Bottom 15' || row['cre_id'] === 'Bottom 20' || row['cre_id'] === 'Bottom 25',
      'bottom-side': row['last'],
      'left-side bottom-side': row['last'] && column['prop'] === 'name' || row['last'] && column['prop'] === 'sale' || row['last'] && column['prop'] === 'diff_selling_price',
      'right-side bottom-side': row['last'] && column['prop'] === 'product' || row['last'] && column['prop'] === 'zone_name' || row['last'] && column['prop'] === 'ratio' || row['last'] && column['prop'] === 'table',
      'left-side': !row['last'] && column['prop'] === 'name' || !row['last'] && column['prop'] === 'sale' || !row['last'] && column['prop'] === 'diff_selling_price',
      'right-side': !row['last'] && column['prop'] === 'product' || !row['last'] && column['prop'] === 'zone_name' || !row['last'] && column['prop'] === 'ratio' || !row['last'] && column['prop'] === 'table',
      'fsct': row['name'] !== row['zone'] && row['name'] !== 'TOTAL' && column['prop'] === 'liters_forecast',
    };
  }

  getRowHeight(row: any) {
    return row.height;
  }

  filterTable($event?: any) {
    if ($event) {
      this.val = $event.target.value;
    }
    this.temp = this.rows.filter((d: any) => {
      for (const key in d) {
        if (d[key] != null) {
          if (key === 'nameStation' || key === 'product' || key === 'station' || key === 'municipio' || key === 'entidad') {
            if (d[key].toLowerCase().indexOf(this.val.toLowerCase()) !== -1) {
              return true;
            }
          }
        }
      }
      return false;
    });
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getProfitabilityComparison() {
    this.reqst = 1;
    this.loading = true;
    let typeValue = 'value';
    this.rows = [];
    this.temp = [];
    this.data = [];
    this.columns = [];
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    setTimeout(() => {
      this.gasprecioService.getProfitabilityComparison(this.idGroup, this.productType, this.variableValue, this.profitabilityPercent).subscribe({
        next: (data: any) => {
          this.data = data;
          const inf = [];
          data.forEach((element: any, index: number) => {
            const months = Object.keys(element['values']).length - 1;
            this.heightTable = 127;
            const r = {};
            const rowExcel = [];
            element['values'].map((row: any, i) => {
              console.log(row)
              if (i === 0) {
                let value;
                if (this.variableValue === 'sales' || this.variableValue === 'avg_sales') {
                  value = row['value'] != null ? this.numberWithCommas(row['value']) : '-';
                } else if (this.variableValue === 'utility_pct') {
                  value = row['value'] != null ? row['value'].toFixed(0) + ' %' : '-';
                } else if (this.variableValue === 'utility') {
                  value = row['value'] != null ? '$ ' + row['value'].toFixed(0): '-';
                } else {
                  value = row['value'] != null ? row['value'].toFixed(2) : '-';
                }
                rowExcel.push(element['type']['name'] === 'top' ? 'Top ' + this.profitabilityPercent : element['type']['name'] === 'bottom' ? 'Bottom ' + this.profitabilityPercent : 'Resto', value);
              } else {
                let value;
                if (this.variableValue === 'sales' || this.variableValue === 'avg_sales') {
                  value = row['value'] != null ? this.numberWithCommas(row['value']) : '-';
                } else if (this.variableValue === 'utility_pct') {
                  value = row['value'] != null ? row['value'].toFixed(0) + ' %' : '-';
                } else if (this.variableValue === 'utility') {
                  value = row['value'] != null ? '$ ' + this.numberWithCommas(row['value']) : '-';
                } else {
                  value = row['value'] != null ? row['value'].toFixed(2) : '-';
                }
                rowExcel.push(value);
              }

              if (this.variableValue === 'sales' || this.variableValue === 'avg_sales') {
                r['day_' + i] = row['value'] != null ? this.numberWithCommas(row['value']) + ' lts' : '-';
              } else if (this.variableValue === 'utility_pct') {
                r['day_' + i] = row['value'] != null ? row['value'].toFixed(0) + ' %' : '-';
              } else if (this.variableValue === 'utility') {
                r['day_' + i] = row['value'] != null ? '$ ' + this.numberWithCommas(row['value']) : '-';
              } else {
                r['day_' + i] = row['value'] != null ? '$ ' + row['value'].toFixed(2) : '-';
              }
              r['height'] = 24;
              r['date'] = moment(row['date']).tz('America/Mexico_City').format('DD/MM/YY');
              r['name'] = element['type']['name'] === 'top' ? 'Top ' + this.profitabilityPercent : element['type']['name'] === 'bottom' ? 'Bottom ' + this.profitabilityPercent : 'Resto';
              r['flag'] = element['type']['name'];
              r['last'] = index === (element['values'].length - 1);
              if (this.period === 'day') {
                this.headerTable.push(moment(row['date']).tz('America/Mexico_City').format('MMM YY'));
                if (index === 0) {
                  this.columns.push({
                    name: moment(row['date']).tz('America/Mexico_City').format('MMM YY'),
                    prop: i
                  });
                }
              } else if (this.period === 'week') {
                this.headerTable.push(row['date_alias']);
                if (index === 0) {
                  this.columns.push({
                    name: row['date_alias'],
                    prop: i
                  });
                }
              } else if (this.period === 'month') {
                this.headerTable.push(moment(row['date']).tz('America/Mexico_City').format('MMM YY'));
                if (index === 0) {
                  this.columns.push({
                    name: moment(element['date']).tz('America/Mexico_City').subtract(months - i, 'month').format('MMM YY'),
                    prop: i
                  });
                }
              }
            });
            inf.push(r);
          });
          this.rows = inf;
          this.temp = this.rows.map((prop: any, key: any) => {
            return {
              ...prop,
              id: key
            };
          });
          this.reqst = 1;
          this.setData();
        }, complete: () => {
          this.loading = false;
          this.reqst = 2;
        }, error: () => {
          this.reqst = 3;
        }
      });
    }, 300);
  }

  setData() {
    this.reqst = 1;
    const seriesData = [];
    this.data.forEach((element, index) => {
      let color = `rgb(${this.gasprecioService.colorsTop[index].r}, ${this.gasprecioService.colorsTop[index].g}, ${this.gasprecioService.colorsTop[index].b})`;
      let pointFormat = {};
      if (this.variableValue === 'sales' || this.variableValue === 'avg_sales') {
        this.chartOptions['yAxis']['labels']['format'] = '{value:,.0f} lts.'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts. </b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      } else if (this.variableValue === 'utility_pct') {
        this.chartOptions['yAxis']['labels']['format'] = '{value:.0f} %'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>' + point.y.toFixed(0) + ' %</b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      } else if (this.variableValue === 'utility') {
        this.chartOptions['yAxis']['labels']['format'] = '$ {value:.0f}'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': $ <b>' + point.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      } else {
        this.chartOptions['yAxis']['labels']['format'] = '$ {value:.2f}'
        pointFormat = {
          formatter: function () {
            var tooltip = '<span style="font-size: 10px">' + this.x + '</span><br/>'; // Agrega la categoría en el tooltip
            var currentPoint = this;
            var addedStations = [];
            this.series.chart.series.forEach(function (series) {
              series.points.forEach(function (point) {
                if (point.y === currentPoint.y && point.x === currentPoint['point'].index && !addedStations.includes(series.name)) {
                  tooltip += '<span style="color:' + series.color + '">\u25CF</span> ' + series.name + ': <b>$ ' + point.y.toFixed(2) + '</b><br/>';
                }
              });
            });
            return tooltip;
          }
        }
        this.chartOptions['tooltip'] = pointFormat
      }
      const lineChartData = {
        name: element['type']['name'] === 'top' ? 'Top ' + this.profitabilityPercent : element['type']['name'] === 'bottom' ? 'Bottom ' + this.profitabilityPercent : 'Resto',
        data: element['values'].map((v: any) => v['value']),
        color: color,
        lineWidth: 1.5,
        marker: {
          radius: 4
        }
      };

      const lineXaxisLegendGenerator = {
        day: (v: any) => this.gasprecioService.parseDateM(v.date),
        week: (v: any) => v.date_alias,
        month: (v: any) => this.gasprecioService.parseDateM(v.date)
      };

      const lineXaxisLegend = element['values'].map(lineXaxisLegendGenerator[this.period]);
      this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
      seriesData.push(lineChartData);
    });
    this.chartOptions.series = seriesData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas: any = document.getElementById('mychart');
    const imgData = canvas.toDataURL('image/png');
    this.sharedService.nextImgData = imgData;
  }
}
