import * as moment from 'moment';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts from 'highcharts';

Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
  return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};

@Component({
  selector: 'app-traffic-chart',
  templateUrl: './traffic-chart.component.html',
  styleUrls: ['./traffic-chart.component.scss']
})
export class TrafficChartComponent implements OnInit, OnDestroy {

  @ViewChild('editModal') editModal: TemplateRef<any>;
  @Input() section: any = false;
  changeModal$!: Subscription;
  changeModal;
  construction;
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  stationId$!: Subscription;
  stationId: any;
  traffic: any;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: 'Tráfico - Obra',
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        format: '{value:,.2f}'
      },
      tickAmount: 10,
      gridLineDashStyle: 'LongDash'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 3
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: 'Tráfico - Obra',
      sourceWidth: 1200,
      sourceHeight: 800
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -16
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.stationId = localStorage.getItem('idStation');
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.stationId$ = this.sharedService.sharedStationId.subscribe(stationId => {
      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }
    });

    this.changeModal$ = this.sharedService.sharedChangeModal.subscribe(changeModal => {
      this.changeModal = changeModal;
      if (this.changeModal === 'charts') {
        this.getChartTraffic();
      }
    });
  }

  async ngOnInit() {
    if (this.section) {
      this.getChartTraffic();
    }
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.stationId$.unsubscribe();
    this.changeModal$.unsubscribe();
  }

  getChartTraffic() {
    this.reqst = 1;
    if (this.section) {
      this.stationId = localStorage.getItem('idStation');
    }
    if (this.stationId === '' || this.stationId === null) {
      return;
    }
    this.gasprecioService.getChartTraffic(this.stationId).subscribe({
      next: (data: any) => {
      this.traffic = data['record'];
      this.construction = data['construction'];
      this.setData();
    }, complete: () => {
      this.reqst = 2;
    }, error: () => {
      this.reqst = 3;
    }
    });
  }

  setData() {
    this.reqst = 1;
    const color = 'rgb(169, 173, 178)';
    const colorLight = 'rgb(245, 125, 8)';
    const lineChartData: any = [
      {
        name: 'Tráfico',
        data: this.traffic.map((v: any) => v['value']),
        lineWidth: 2,
        color: color,
        marker: {
          radius: 3
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(2) + '</b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartNClicked(event['point']['index'])
          }
        }
      },
      {
        name: 'Obra',
        data: this.construction.map((v: any) => v['value']),
        lineWidth: 2,
        color: colorLight,
        marker: {
          radius: 3,
          symbol: 'url(https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/road-barrier.svg)'
        },
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0) + '</b><br>Click aquí para ver el detalle del día';
          }
        },
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.chartNClicked(event['point']['index'])
          }
        }
      }
    ];

    const lineXaxisLegend = [];
    this.traffic.forEach((v: any) =>
      lineXaxisLegend.push(this.gasprecioService.parseDateDM(v['date']))
    );

    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions['series'] = lineChartData;
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  chartNClicked(index: any) {
    const date = this.traffic[index].date;
    const name = this.traffic[index].name;
    this.changeDataTable(date, name);
    this.openModal();
  }

  changeDataTable(date, name) {
    this.sharedService.nextDate = moment.tz(date, 'America/Mexico_City').format('YYYY-MM-DD');
    this.sharedService.nextProductType = this.productType;
    this.sharedService.nextStationId = this.stationId;
    this.sharedService.nextNameStation = name;
    setTimeout(() => {
      this.sharedService.nextChangeModal = 'table';
    }, 100);
  }

  openModal() {
    this.modalService.open(this.editModal, { windowClass: 'tableModal' });
  }

  closeModal() {
    this.modalService.dismissAll(this.editModal);
  }
}
