<div class="row center-align">
    <br>
    <div class="col s12 m12">
        <a href="javascript:void(0)" routerLink="/login" class="text-center db">
            <img src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/logo.svg" width="20%" alt="Home" />
        </a>
        <div class="centro">
            <div class="col s12 m12">
                <h3 i18n> 404 Página no encontrada </h3>
                <h3>:(</h3>
            </div>
            <a routerLink="/login" class="uline-uno" i18n>Inicio</a>
        </div>
    </div>
</div>