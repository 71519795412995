<div class="header-c"> 
    Utilidad Bruta
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Volumen de venta en litros multiplicado por su margen bruto.
    </epy-tooltip>
    &nbsp;
    <input type="checkbox" id="checkbox-tooltip-earning" [(ngModel)]="showTooltip" (ngModelChange)="setData()" *ngIf="period !== 'day'">
    <label style="font-size: 13px;margin-bottom: 0;margin-left: .2%;" for="checkbox-tooltip-earning" *ngIf="period !== 'day'">Ver Dif $ y Dif %</label>
    &nbsp;
    <input type="checkbox" id="checkbox-scale-earning" [(ngModel)]="showScale" (ngModelChange)="setData()" *ngIf="productType !== 'total'"> <label
        style="font-size: 13px;margin-bottom: 0;margin-left: .2%;" for="checkbox-scale-earning" *ngIf="productType !== 'total'">Escala desde 0</label>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="reqst===2">
    <div style="margin-right: 1%;">
        <highcharts-chart id="mychart6" [Highcharts]="Highcharts" [options]="chartOptions"
            [ngStyle]="{ 'height': station || period !== 'month' && !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) ? 'auto': '310px'}" style="width: 100%;display: block;"></highcharts-chart>
    </div>
</div>
