import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-multiselect-reason',
  templateUrl: './multiselect-reason.component.html',
  styleUrls: ['./multiselect-reason.component.scss']
})
export class MultiselectReasonComponent implements OnInit, OnDestroy {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas',
    unSelectAllText: 'Todas',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    searchPlaceholderText: 'Buscar...',
    maxHeight: 140,
    noDataAvailablePlaceholderText: 'No hay datos disponibles'
  };
  url: string;
  public idGroup: any;
  rol;
  idGroup$: Subscription;

  constructor(
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');

    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.getReasons();
      }
    });
  }

  ngOnInit(): void {
    this.getReasons();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
  }

  getReasons() {
    const items = [];
    this.gasprecioService.getReasons().subscribe((data: any) => {
      const reasons: any = data;
      reasons.forEach((element: any, index) => {
        const pos = index + 1;
        items.push({ item_id: element._id, item_text: pos + '.- ' + element.heading });
      });

      this.dropdownList = items;
    });
  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'causes[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementReasons = this.url;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      this.url = this.url + 'causes[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementReasons = this.url;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = '';
    this.sharedService.nextComplementReasons = this.url;
  }

}
