import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.scss']
})
export class SelectProductComponent implements  OnDestroy {

  public items: any[] = [
    { name: 'Regular', value: 'regular' },
    { name: 'Premium', value: 'premium' },
    { name: 'Diesel', value: 'diesel' }
  ];

  public productType: any = [];
  productType$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productType = localStorage.getItem('product');
    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      this.productType = productType;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.productType$.unsubscribe();
  }

  changeProductType(event: any) {
    this.productType = event;
    this.sharedService.nextProductType = this.productType;
    localStorage.setItem('product', this.productType);
  }
}
