<div class="header-c" style="margin-bottom: 10px;margin-top: 10px;">
    <div class="select-type">
        <app-select-variable-px-opt></app-select-variable-px-opt>
    </div> Promedio Diario
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Promedio de litros diarios vendidos por estación.
    </epy-tooltip>
    - Zona de Mercado
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
        <span class="difference" slot="trigger">
            <i class="fa-regular fa-circle-info info" aria-hidden="true"></i>
        </span>
        Según el Indice de Precios, en que Zona o parte del Mercado te ubicas.<br>
        Baja: Indice <= 0.98 <br>
        Media Baja: 0.98 < Indice < 1.00 <br>
        Media Alta: 1.00 <= Indice < 1.02<br>
        Alta: Indice => 1.02 <br>
    </epy-tooltip>
</div>

<div id="container-highcharts-180" style="width: 100%;" *ngIf="reqst === 1">
    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
</div>

<div *ngIf="reqst === 0" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>