import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-statistics-evolution',
  templateUrl: './select-statistics-evolution.component.html',
  styleUrls: ['./select-statistics-evolution.component.scss']
})
export class SelectStatisticsEvolutionComponent implements  OnDestroy {

  public items: any[] = [
    { name: 'Promedio', value: 'avg' },
    { name: 'Cierre de Mes', value: 'end' }
  ];

  public statisticEvolutionSelected: any = [];
  public ngxDisabled = true;
  statisticEvolutionSelected$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.statisticEvolutionSelected$ = sharedService.sharedStatisticEvolution.subscribe((statisticEvolutionSelected: string) => {
      if (this.statisticEvolutionSelected !== statisticEvolutionSelected) {
        this.statisticEvolutionSelected = statisticEvolutionSelected;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.statisticEvolutionSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.statisticEvolutionSelected = event;
    this.sharedService.nextStatisticEvolution = this.statisticEvolutionSelected;
  }

}
